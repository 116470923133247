module.exports = {
  en: {
    aboutToChangeStrategiestate:
      "<p>You are about to change the state of the strategy to <strong>{state}</strong>.</p><p>Users will be notified of this action. Make sure this is the action you want to perform before proceeding.</p>",
    accessRestriction: "Access Restriction",
    accessRestrictionTooltip: "Restrict access to specific members and groups.",
    actionInProgress: "Action in progress...",
    actions: "Actions",
    actionSuccessful: "Success!",
    activateDistributedMode: "Activate distributed mode",
    activeMembers: "Team members",
    activeMembersDescription: "Manage and invite new members to your team.",
    activities: "Activities",
    activitiesPageDescription:
      "Track the platform REST API activities and traffic",
    activitiesPageTitle: "API Activities",
    add3DScatterPlot: "Add 3D scatter plot",
    addChemicalFilter: "Add chemical filter",
    addChemicalFilterPageDescription: "Add a new chemical filter.",
    addChemicalFilterPageTitle: "Add chemical filter",
    addChemicalFilterStatement: "Add new statement",
    addChemicalSearch: "Chemical Search",
    addChemicalSearch2: "Add chemical Search",
    addComment: "Add comment",
    addCompoundLibrary: "Add compound library",
    addCompoundLibraryPageDescription: "Add a new compound library.",
    addCompoundLibraryPageTitle: "Add compound library",
    addDescriptorsTask: "Add descriptors task",
    addDescriptorsTaskDescription: "Launch a new descriptors task",
    addDistributedScoringTask: "Add distributed scoring task",
    addDistributedScoringTaskDescription:
      "Launch a new distributed scoring task",
    addFeatureFlag: "Add feature flag",
    addFeatureFlagOrg: "Enter a name of an existing organization to add it",
    addFeatureFlagPageDescription: "Create a new feature flag.",
    addFeatureFlagPageTitle: "Add feature flag",
    addFeatureFlagUser: "Enter an email address of an existing user to add it",
    addFeaturizeTask: "Add featurization task",
    addFeaturizeTaskDescription: "Launch a new featurization task",
    addFilter: "Add filter",
    addFlag: "Add flag",
    addForSubmission: "Add for submission",
    addGenericTemplateDescription:
      "Create reusable pieces of information to rapidly configure and launch machine learning tasks",
    addGenericTemplateTitle: "Add template",
    addGroup: "Add group",
    addGroupPageDescription: "Add a new group to your team.",
    addGroupPageTitle: "Add group",
    addHistogram: "Add histogram",
    additionalCatalogs: "Additional catalogs",
    additionalDetails: "Additional details",
    additionalExamplesProperties:
      "<strong>Additional examples:</strong> LogP, LogD, Kinetic Solubility, Microsomal Stability, PAMPA Permeability.",
    additionalInfo: "Additional information",
    additionalInformation: "Additional information",
    addMember: "Add member",
    addMemberPageDescription: "Invite new members to your team.",
    addMemberPageTitle: "Add member",
    addModelSelectionTask: "Add model selection task",
    addModelSelectionTaskDescription: "Launch a new model selection task",
    addMoleculeGrid: "Add molecule grid",
    addNewChemicalSearch: "Add new chemical search",
    addNewMember: "Add new member",
    addNewProject: "Add new project",
    addNewStrategy: "Add new strategy",
    addOrganization: "Add a team",
    addOrganizationPageDescription: "Create a new team.",
    addOrganizationPageTitle: "Add team",
    addProject: "Add project",
    addProjectOnly: "Add project only",
    addProjectPageDescription: "Add a new project to collaborate on.",
    addProjectPageTitle: "Add project",
    addProperty: "Add property",
    addRadarPlot: "Add radar plot",
    addScatterPlot: "Add scatter plot",
    addStrategiestep1Title: "Step 1: Strategy Details",
    addStrategiestep2Title: "Step 2: Assay Readouts",
    addStrategiestep3Title: "Final Step: Assay Results",
    addStrategy: "Add strategy",
    addStrategyCompoundPropertiesIntro:
      "Specify which assay readouts you are interested in for this strategy.",
    addStrategyDatasetInfo:
      'To learn why we encourage dataset submissions based on a template, <a href="https://documentation.valencediscovery.com/docs/strategies-data-format" target="_blank">click here</a>.',
    addStrategyDatasetIntro:
      "Provide your assay results based on the dataset template below.",
    addStrategyDetailsIntro:
      "Specify the general information of your strategy.",
    addStrategyPageDescription: "Add a new strategy to an existing project.",
    addStrategyPageTitle: "Add strategy",
    addStrategyToProject: "Add strategy to this project",
    addSuperUser: "Add special user",
    addSuperUserDescription:
      "Add a user from our organization (Valence Labs) who will have special privileges.",
    addSuperUserTitle: "Add special user",
    addTemplate: "Add template",
    addToSearch: "Add to search",
    adminDescription: "Admin can view, edit, and delete the resource.",
    administration: "Administration",
    adminLabel: "Admin",
    alerts: "Alerts",
    alignFromApply: "Align from (apply)",
    alignFromEditor: "Align from (editor)",
    all: "All",
    allCatalogs: "All catalogs",
    allCatalogsScenarioDescription:
      "Search the most relevant molecules across all Circus catalogs, commercial or not.",
    allCompoundsLoaded: "All compounds were loaded.",
    allMolecules: "All molecules",
    allPrices:
      "(available from {count, plural, =1 {1 provider} other {# providers}})",
    allStructuresExist: "All structures already added.",
    allTasks: "All tasks",
    allTasksPageDescription:
      "Oversee all existing computational tasks of your team.",
    allTasksPageTitle: "All tasks",
    alreadyHaveCode: "I just need to confirm my account",
    alreadySignedUp: "Already signed up?",
    any: "Any",
    applicationLoadFailed:
      "Something went wrong while loading the application.",
    appliedChemicalSearch: "Chemical search filter",
    appliedFilters: "More filters...",
    apply: "Apply",
    applyFilters: "Apply filters",
    areYouSure: "Are you sure?",
    areYouSureToDeleteForever:
      "<p>{count, plural, =1 {1 item} other {# items}} will be deleted forever and you won't be able to restore them.</p><p>This action is not recoverable.</p>",
    areYouSureToEmptyTrash:
      "<p>All items in the trash will be deleted forever and you won't be able to restore them.</p><p>This action is not recoverable.</p>",
    areYouSureToRemoveChemicalFilter:
      "<p>Are you sure to remove the chemical filter <strong>{name}</strong>?</p><p>This will also remove all statements belonging to this chemical filter.</p>",
    areYouSureToRemoveCompoundLibraries:
      "<p>Are you sure to remove the <strong>{title}</strong> compound library?</p><p>This action is not recoverable.</p>",
    areYouSureToRemoveFeatureFlag:
      "<p>Are you sure to remove <strong>{name}</strong>?</p><p>If you still have references to this flag in the codebase, you will have to remove them to unlock the full feature for all users.</p>",
    areYouSureToRemoveGroup:
      "<p>Are you sure to remove <strong>{name}</strong>?</p><p>This may change access restrictions settings if this group was used to restrict access to specific resources.</p>",
    areYouSureToRemoveMember:
      "Are you sure to remove <strong>{email}</strong> from your team?",
    areYouSureToRemoveOrganization:
      "<p>Are you sure to remove <strong>{name}</strong>?</p><p>This will also remove all strategies and other resources belonging to this team.</p>",
    areYouSureToRemoveProjects:
      "<p>Are you sure to remove the project <strong>{title}</strong>?</p><p>This will also remove all strategies and other resources belonging to this project.</p>",
    areYouSureToRemoveStrategies:
      "<p>Are you sure to remove the <strong>{title}</strong> strategy?</p><p>This action is not recoverable.</p>",
    areYouSureToReset: "Are you sure you want to reset this review?",
    areYouSureUpdateReport:
      "<p>You are about to update the report sent to partners.</p>Make sure this is the final version of the report you would like to send before proceeding.</p><p><strong>Note:</strong> You can always preview the report to make sure everything is ok.</p>",
    areYouSureUploadReport:
      "<p>You are about to send a report to partners.</p>Make sure this is the final version of the report you would like to send before proceeding.</p><p><strong>Note:</strong> You can always preview the report to make sure everything is ok.</p>",
    assayReadouts: "Assay readouts",
    assayResults: "Assay results",
    assays: "Assays",
    assignChemicalFilters: "Assign chemical filters",
    assignedChemicalFilters: "Assigned Chemical Filters",
    assignedModels: "Models available for scoring",
    assignedModelVersions: "Assigned Model Versions",
    assignModels: "Assign models",
    assignModelVersions: "Assign model versions",
    autoSavingChanges: "Autosaving changes...",
    availability: "Availability",
    back: "Back",
    backToChemicalFilters: "Back to chemical filters",
    backToGroups: "Back to groups",
    backToProjects: "Back to projects",
    backToSignIn: "Back to sign in",
    backToStrategies: "Back to strategies",
    backToTop: "Back to top",
    batchSize: "Batch size",
    betaNotice:
      "<p>This software is a beta version of the {appName} which is undergoing continuous improvements before its official release.</p><p>We strongly encourage bug reports, feature requests, and additional feedback from users.</p>",
    betaNoticeShort: "Beta Notice",
    blindModeActivated: "blind mode activated",
    blindModeDeactivated: "blind mode deactivated",
    blindReview: "Blind mode",
    blindReviewDescription:
      "If checked, a reviewer will not be able to see reviews from other reviewers until he completes his own.",
    buildingBlocks: "Building blocks",
    cancel: "Cancel",
    cannotAccessThisPage: "You cannot access this page at the moment.",
    cannotAccessThisResource: "You cannot access this resource.",
    cannotAddTwoPropertyWithSameName:
      "You cannot add two assays with the same name.",
    cannotEditRestrictions: "You do not have the rights to edit restrictions.",
    cardsView: "Grid view",
    catalogLabel_chebi: "ChEBI",
    catalogLabel_chembl: "ChEMBL",
    catalogLabel_chemspace: "ChemSpace",
    catalogLabel_drugbank: "DrugBank",
    catalogLabel_emolecules: "eMolecules",
    catalogLabel_labnetwork: "LabNetwork",
    catalogLabel_mcule: "Mcule",
    catalogLabel_molport: "MolPort",
    catalogLabel_pubchem: "PubChem",
    catalogLabel_real: "Enamine",
    "catalogLabel_real-bb": "REAL Building Blocks",
    "catalogLabel_real-diverse-druglike": "REAL Diverse drug-like",
    catalogLabel_surechembl: "SureChEMBL",
    catalogLabel_zinc: "ZINC",
    catalogs: "Catalogs",
    catalogsDescription: "Select specific chemical compounds catalogs",
    "cddConfigurationForm.vaultID": "Vault ID",
    "cddConfigurationForm.apiKey": "API Key",
    "cddConfigurationForm.cddVaultIntegration": "CDD Vault Integration",
    "cddConfigurationForm.vaultIDPlaceholder": "Enter a Vault ID",
    "cddConfigurationForm.vaultIDTooltip":
      "The ID of the CDD Vault to synchronise",
    "cddConfigurationForm.apiKeyTooltip":
      "The credentials information necessary to synchronise your CDD Vault with Valence Labs platform.",
    "cddConfigurationForm.apiKeyPlaceholder": "Enter an API Key",
    "cddConfigurationForm.valueLikelyInvalid":
      "The value specified is likely invalid.",
    "cddConfigurationForm.lastConfigured": "Last configured on {configured_at}",
    "cddConfigurationForm.configuredBy": "configured by {userLabel}",
    cddFilterInVaultWithData: "In vault (with data)",
    cddFilterInVaultWithoutData: "In vault (without data)",
    cddFilterNotInVault: "Not in vault",
    cddID: "CDD ID",
    cddVault: "CDD Vault",
    cddVaultAvailableData:
      "Data is available in your CDD Vault for this molecule.",
    cddVaultInformation: "CDD Vault Information",
    cddvaultSyncState_ER: "Synchronization error",
    cddvaultSyncState_NS: "Not synchronised",
    cddvaultSyncState_QE: "Synchronisation requested...",
    cddvaultSyncState_SC: "Last synchronised {syncedAt}",
    cddvaultSyncState_SP: "Synchronizing...",
    cddvaultSyncState_undefined: " ",
    changeChemicalSearchParameters: "Chemical search parameters",
    changeStrategiestate: "Change strategy state",
    changesWereSavedSuccessfully: "Changes saved successfully.",
    changeYourProfileImage: "Change your profile image",
    checkInboxForCode: "Check your email inbox for your code.",
    chemicalFilter: "Chemical Filter",
    chemicalFilterAlreadyExists:
      "A chemical filter with this name already exists.",
    chemicalFilterDeletedSuccessfully: "Chemical filter deleted successfully",
    chemicalFilterOperator: "Operator",
    chemicalFilters: "Chemical filters",
    chemicalFiltersAssignmentTitle:
      "Chemical filters assignment for project <i>{project}</i>",
    chemicalFiltersPageDescription:
      "Overview and manage the chemical filters of your team.",
    chemicalFiltersPageTitle: "Chemical Filters",
    chemicalFilterStatements: "Statements",
    chemicalFilterStatementsLogic: "Statements logic",
    chemicalFilterStructure: "Structure",
    chemicalFiltersUpdatedSuccessfully:
      "Chemical filters updated successfully.",
    chemicalSearch: "Chemical search",
    chemicalSearchAromatizeNotice:
      "Try adjusting the aromatize/dearomatize editor options if your search returns unexpected results.",
    chemicalSearches: "Chemical Searches",
    chemicalSearchesPageTitle: "Chemical Search - History",
    chemicalSearchHistoryDescription:
      "Track and reuse previous search queries of your team.",
    chemicalSearchHistoryTitle: "Search History",
    chemicalSearchOperator: "Operator",
    chemicalSearchOperatorContext_AND:
      "The search finds compounds containing all the specified structures.",
    chemicalSearchOperatorContext_OR:
      "The search finds compounds identical to one of the specified structures.",
    chemicalSearchRoutes: "Chemical search routes",
    chemicalSearchStateCO: "Complete",
    chemicalSearchStateER: "Error",
    chemicalSearchStateIN: "Running",
    chemicalSearchThresholdUsed:
      "A threshold of {threshold} will be used for similarity search.",
    circusLabel: "Circus Search Engine",
    clear: "Clear",
    click: "click",
    clickHere: "click here",
    clickOrDragDatasetToSubmit:
      "Click or drag your assay results to this area.",
    clickOrDragFileToEditCompoundLibrary:
      "Click or drag a new file to change the content of your compound library.",
    clickOrDragFileToUpload: "Click or drag file to this area to upload.",
    clickOrDragMoleculesFile:
      "Click or drag your list of generated compounds to this area.",
    clickOrDragSuggestedMoleculeFile:
      "Click or drag a list of compounds to submit in a .csv, .xlsx, .sdf format.",
    clickOrDragToUpload: "Click or drag file(s) to this area to upload.",
    clickOrDragTrainingMoleculesFile:
      "Click or drag your list of training compounds to this area.",
    clickOrLeftArrow: "Click or use left keyboard arrow",
    clickOrRightArrow: "Click or use right keyboard arrow",
    clickToCopy: "Click to copy",
    clickToEnlarge: "Click to expand",
    clickToOpenDrive: "Click to open drive",
    clickToSelect: "Click to select",
    clickToSwitchOrg: "Click to select another team.",
    clickToUpload: "Click to upload",
    close: "Close",
    closePreview: "Close preview",
    clustersPlural: "{count, plural, =1 {1 group} other {# groups}}",
    code: "Code",
    codeSentSuccessfully: "Code sent successfully",
    collaborators: "Collaborators",
    collapse: "Collapse",
    comeBackLater: "Come back later",
    comment: "Comment",
    commentAndSubmit: "Comment and submit",
    commentPotentialPlural: "comment(s)",
    commerciallyAvailableOnly: "Commercially available only",
    commerciallyAvailableOnlyCheckedDescription:
      "Only molecules that are available for purchase will be included.",
    commerciallyAvailableOnlyDescription:
      "Include only molecules that are available for purchase.",
    commerciallyAvailableOnlyNotCheckedDescription:
      "All molecules matching the search inputs will be returned.",
    completeProfileBadge: "Complete your profile information",
    completeProfileInformation: "Complete your profile...",
    completeSignHeader: "Complete the sign in process",
    completeSignInButton: "Complete sign in",
    completeSignInDescription: "Please enter a new password below.",
    compoundForSubmission: "For Submission",
    compoundId: "Compound ID",
    compoundIdCopied: "Copied!",
    compoundIdCopy: "Copy catalog ID",
    compoundLibraries: "Compound libraries",
    compoundLibrariesPageDescription:
      "Centralize and manage your compound libraries.",
    compoundLibrariesPageTitle: "Compound libraries",
    compoundLibrariesRemovedSuccessfully:
      "<strong>{title}</strong> was removed successfully.",
    compoundLibrary: "Compound Library",
    compoundLibraryAddedSuccessfully:
      "<strong>{name}</strong> compound library was added successfully.",
    compoundLibraryAlreadyExists:
      "A compound library with this name already exists.",
    compoundLibraryCurrentFile:
      "<span>The current file associated with this compound library is <b>{fileName}</b></span>",
    compoundLibraryEditedSuccessfully:
      "<strong>{name}</strong> compound library was edited successfully.",
    compoundLibraryFileWillBeReplaced:
      '<span>The file associated with this compound library will be replaced by the new file <b>{fileName}</b> once you click on the "Save" button.</span>',
    compoundModalTitle: "Compound ID: {id}",
    compoundProperties: "Compound properties",
    compoundsAlreadySubmittedForPrediction:
      "The compounds below were already submitted for prediction by a member of your team throughout the course of this project.",
    compoundsAlreadySubmittedForPredictionStrategy:
      "The compounds below will be presented to you with their predicted properties in the next strategy of this project.",
    compoundsCount: "Compounds",
    compoundsForSubmission: "For Submission",
    compoundsForSubmissionList: "Compounds for submission",
    compoundsFound: "Total compounds found",
    compoundsMaxDisplayed:
      "Only the first {maxDisplayed} compounds are shown for preview.",
    compoundsPreviouslySubmitted: "Project submissions",
    compoundsPreviouslySubmittedCurrent: "Next strategy submissions",
    compoundSuggestedSuccessfully: "Compound suggested successfully!",
    compoundsUpdated: "Compounds updated",
    computationalTasks: "Machine Learning",
    configuration: "Configuration",
    confirm: "Confirm",
    confirmationCode: "Confirmation code",
    confirmPassword: "Confirm password",
    confirmYourEmailAddress: "Confirm your email address",
    confirmYourEmailAddressDescription:
      "Retrieve the confirmation code in your inbox",
    connectingToPlatform: "Connecting to {appName}...",
    consultChemicalSearchResults:
      "Navigate through your chemical search results",
    consultReview: "Consult review",
    contactUs: "Contact us",
    containerImageName: "Container image name",
    containerImageTag: "Container image tag",
    continue: "Continue",
    continueReview: "Continue review",
    copied: "Copied!",
    copyCXSmiles: "Copy CXSMILES",
    copyImageToClipboard: "Copy image to clipboard",
    copyInchi: "Copy InchI",
    copyInchikey: "Copy InChiKey",
    copyListToClipboard: "Copy list to clipboard",
    copyMolBlock: "Copy MolBlock",
    copyright: "Valence Labs Inc., {year}",
    copySmarts: "Copy SMARTS",
    copySmiles: "Copy SMILES",
    copyToClipboard: "Copy to Clipboard",
    createChemicalFilter: "Create chemical filter",
    created: "Created",
    createdBy: "Created by",
    createDemoOrganization: "Create demo organization",
    createDemoOrganizationDescription:
      "Click the button below to create a demo organization.",
    createFolder: "Create Folder",
    createStrategy: "Create strategy",
    creationSuccessfulDemoOrganization:
      "Demo organization created successfully!",
    cron: "CRON",
    csvFileSupport: "Reminder: The file should be in a .csv format.",
    current: "Current",
    currentOrganization: "Current team",
    currentStructure: "Current structure",
    currentSubmission: "Current submission",
    currentView: "Current view",
    custom: "Custom",
    customRoute: "Custom route",
    dataFiles: "Data",
    dataProcessingTasksSideNavTitle: "Data processing",
    dataset: "Dataset",
    datasets: "Datasets",
    datasetsEmpty: "No datasets are available yet.",
    datasetType: "Type",
    datasetType_generated: "Compounds",
    datasetType_training: "Assay results",
    datasetTypeExplanation_generated:
      "The dataset of compounds generated from the current computational strategy.",
    datasetTypeExplanation_training:
      "The preprocessed dataset used to train a generative or predictive model. Typically an assay results dataset.",
    date: "Date",
    dateAdded: "Date added",
    days: "{count, plural, =1 {1 day} other {# days}}",
    delete: "Delete",
    deleteChemicalFilter: "Delete",
    deleteForever: "Delete Forever",
    deleteReview: "Delete review",
    deleteRouteFeedback: "Delete",
    deleteTreeNode: "Delete",
    deletionSuccessful: "Deletion successful",
    demoOrganizationNamePresentation:
      "The name of your demo organization name is:",
    description: "Description",
    descriptor_amw: "Average MolWeight",
    descriptor_clogp: "cLogP",
    descriptor_crippen_clog_p: "cLogP",
    descriptor_CrippenClogP: "cLogP",
    descriptor_CrippenMR: "Crippen-Wildman MR",
    descriptor_exactmw: "Exact MolWeight",
    descriptor_fraction_csp3: "Fsp3",
    descriptor_FractionCSP3: "Fsp3",
    descriptor_fsp_3: "Fsp3",
    descriptor_fsp3: "Fsp3",
    descriptor_labuteASA: "LabuteASA",
    descriptor_lipinskiHBA: "Lipinsky HBA",
    descriptor_lipinskiHBD: "Lipinsky HBD",
    descriptor_molecular_weight: "Molecular Weight",
    descriptor_molecularWeight: "Molecular Weight",
    descriptor_mw: "MW (Molecular Weight)",
    descriptor_n_lipinski_hba: "# Lipinski HBA",
    descriptor_n_lipinski_hbd: "# Lipinski HBD",
    descriptor_n_NHOH: "# Sodium Hydroxide (NHOH)",
    descriptor_n_NO: "# Nitric Oxide (NO)",
    descriptor_num_aromatic_rings: "# Aromatic Rings",
    descriptor_num_hba: "# HBA",
    descriptor_num_hbd: "# HBD",
    descriptor_num_heavy_atoms: "# Heavy Atoms",
    descriptor_num_heteroatoms: "# Hetero Atoms",
    descriptor_num_rings: "# Rings",
    descriptor_NumAliphaticHeterocycles: "# Aliphatic Heterocycles",
    descriptor_NumAliphaticRings: "# Aliphatic Rings",
    descriptor_NumAmideBonds: "# Amide Bonds",
    descriptor_NumAromaticHeterocycles: "# Aromatic Heterocycles",
    descriptor_NumAromaticRings: "# Aromatic Rings",
    descriptor_NumAtomStereoCenters: "# Atom Stereocenters",
    descriptor_NumBridgeheadAtoms: "# Bridgehead Atoms",
    descriptor_NumHBA: "# HBA",
    descriptor_NumHBD: "# HBD",
    descriptor_NumHeteroatoms: "# Heteroatoms",
    descriptor_NumHeterocycles: "# Heterocycles",
    descriptor_NumRings: "# Rings",
    descriptor_NumRotatableBonds: "# Rotatable Bonds",
    descriptor_NumSaturatedHeterocycles: "# Saturated Heterocycles",
    descriptor_NumSaturatedRings: "# Saturated Rings",
    descriptor_NumSpiroAtoms: "# Spiro Atoms",
    descriptor_NumUnspecifiedAtomStereoCenters:
      "# Unspecified Atom Stereocenters",
    descriptor_qed: "QED (Quantitative Estimate of Drug-likeness)",
    descriptor_refractivity: "Molar Refractivity",
    descriptor_sas: "SAscore",
    descriptor_tpsa: "TPSA (Topological Polar Surface Area)",
    descriptors: "Molecular Descriptors",
    descriptorsList: "Descriptors list",
    descriptorsPrefix: "Descriptors prefix",
    descriptorsSideNavTitle: "Descriptors",
    descriptorsTaskHistoryDescription:
      "Track and access results of molecular descriptors calculation jobs performed by your team.",
    descriptorsTaskHistoryTitle: "Descriptors Tasks",
    deselectAll: "Deselect all",
    dimensional: "Dimensional",
    displayName: "Display name",
    distributed: "Distributed",
    distributedScoringSideNavTitle: "Scoring (internal)",
    distributedScoringTasksPageDescription:
      "Track and access results of distributed scoring jobs performed by your team.",
    distributedScoringTasksPageTitle: "Distributed scoring tasks",
    dockerImageName: "Docker image name",
    dockerImageTag: "Docker image tag",
    docs: "Docs",
    documentation: "Documentation",
    doNotUseForScoring: "Do not use for scoring.",
    donwloadCurrentViewTooltip:
      "Only filtered compounds will be downloaded.\n\nCurrently {currentCount} out of {totalCount} compounds.",
    doubleClick: "double click",
    download: "Download",
    downloadAsPNG: "Download as PNG",
    downloadAsSVG: "Download as SVG",
    downloadCompounds: "Download compounds",
    downloadCurrentReview: "Download review",
    downloadFailed: "Download failed",
    downloadFailedDescription:
      "Something went wrong while downloading the files. Try again and contact us if the error persists.",
    downloadImage: "Download image",
    downloading: "Downloading...",
    downloadingFileContext:
      "Processing compounds. Download may take a few seconds...",
    downloadInputFile: "Download input file",
    downloadOriginalFile: "Download original file",
    downloadOriginalFileRaw: "Download original file (raw)",
    downloadResults: "Download results",
    downloadReview: "Download review",
    downloadScope: "Download scope",
    downloadStrategyConfig: "Download strategy configuration",
    downloadTemplate: "Download dataset template",
    downloadTransformerFile: "Download transformer file",
    dragReportHere: "Drag and drop results.",
    drawStructure: "Draw structure",
    drive: "Drive Space",
    driveDeletionInsufficientPermissions:
      "You cannot perform this action because you don't have the necessary rights on all items you attempted to delete.",
    driveFile: "Drive file",
    driveNotifications: "Drive Space",
    driveNotificationSettings: "Notification Settings",
    drivePageDescription: "A centralized place to manage files of your team.",
    drivePageTitle: "Drive Space",
    driveRestorationAncestorInTrash:
      "Restoration cannot be performed because the parent folders of some items are currently in the trash. Please restore the parent folders before restoring the current items.",
    driveTrash: "Trash",
    driveTrash30DaysNotice:
      "Files and folders are deleted forever after 30 consecutive days spent in the trash.",
    driveTrashPageDescription:
      "Manage files and folders that were moved to trash.",
    driveTrashPageTitle: "Drive Trash",
    driveWhatToTrash:
      "Move files and folders that you no longer need in the trash.",
    edit: "Edit",
    editChemicalFilter: "Edit chemical filter",
    editChemicalFilterPageDescription:
      "Manage the information and statements of your chemical filter.",
    editChemicalFilterPageTitle: "Edit chemical filter",
    editComment: "Edit comment",
    editCompoundLibraryPageDescription:
      "Manage the information and content of your compound library.",
    editCompoundLibraryPageTitle: "Edit compound library",
    editFilter: "Edit filter",
    editFlagPageDescription: "Manage users bound to a feature flag.",
    editFlagPageTitle: "Edit feature flag",
    editGenericTemplateTitle: "Edit template",
    editGroupPageDescription: "Manage an existing group of your team.",
    editGroupPageTitle: "Edit group",
    editMemberPageDescription: "Edit a team member.",
    editMemberPageTitle: "Edit member",
    editor: "Editor",
    editorDescription: "Editors can view and edit the resource.",
    editOrganization: "Edit team",
    editOrganizationPageDescription: "Edit a team.",
    editOrganizationPageTitle: "Edit team",
    editorLabel: "Editor",
    editorSubmission: "Editor submission",
    editParameters: "Edit parameters",
    editProject: "Edit project",
    editProjectDetails: "Edit project details",
    editProjectPageDescription: "Manage information of your project.",
    editProjectPageTitle: "Edit project",
    editStrategy: "Edit strategy",
    editStrategyDetails: "Edit strategy details",
    editSubmit: "Edit submission",
    editTemplate: "Edit template",
    elapsedTime:
      "Elapsed time: {time, plural, =0 {0 second} other {# seconds}}",
    emailAddress: "Email address",
    emptyTrash: "Empty Trash",
    enabled: "Enabled",
    enableRetrosynthesisSearch: "Retrosynthesis search",
    enableRetrosynthesisSearchDescription:
      "Enable or Disable retrosynthesis search",
    enterAFileName: "Enter a file name",
    enterChemicalFilterDescription:
      "Explain the common usage of this filter to make it reusable by other team members.",
    enterChemicalFilterName: "Enter a name for the chemical filter",
    enterComment: "Enter your comment here",
    enterCompoundLibraryDescription: "Enter compound library description",
    enterCompoundLibraryName: "Enter compound library name",
    enterDescription: "Enter a description",
    enterDisplayName: "Enter display name",
    enterDockerImageName: "Enter Docker image name",
    enterDockerImageTag: "Enter Docker image tag",
    enterEmailAddress: "Enter email address",
    enterEmailAndPassword: "Enter your email address and password below.",
    enterFeatureFlagName: "Enter feature flag name",
    enterFolderName: "Enter folder's name",
    enterGroupDescription: "Enter a description for the group",
    enterGroupName: "Enter a name for the group",
    enterMachineName: "Enter machine name",
    enterMembersForGroup: "Add members for this group",
    enterModelDescription: "Enter model description",
    enterModelVersion: "Enter model version",
    enterName: "Enter a name",
    enterOneSmartsOrSmiles: "Enter a single SMARTS or SMILES.",
    enterOrganizationName: "Enter team name",
    enterProjectDescription: "Enter a description for the project",
    enterProjectName: "Enter a title for the project",
    enterReviewComment: "Enter a review comment",
    enterReviewers: "Add reviewers for this strategy",
    enterSmarts: "Enter a single SMARTS.",
    enterSmartsOrSmiles: "Enter a single SMARTS or SMILES per line.",
    enterSmiles: "Enter a single SMILES per line.",
    enterStrategyCollaborators: "Add collaborators to this strategy",
    enterStrategyDescription: "Enter a description for the strategy.",
    enterStrategyName: "Enter a title for the strategy",
    enterUnits: "Enter units",
    enterValue: "Enter value",
    entityAddedSuccessfully: "<strong>{name}</strong> was added successfully.",
    error: "Error",
    errorCode_SUGGESTED_MOLECULES_ALREADY_EXIST_description:
      "You are trying to suggest compounds that are already part of this strategy. Make sure you only suggest novel compounds.",
    errorCode_SUGGESTED_MOLECULES_ALREADY_EXIST_title:
      "Some compounds already exist",
    errorCode_SUGGESTED_MOLECULES_MAX_EXCEEDED_description:
      "This submission will exceed the maximum number (100) of suggestions you can make per strategy. Try again with fewer suggestions.",
    errorCode_SUGGESTED_MOLECULES_MAX_EXCEEDED_title:
      "Maximum suggested compounds per strategy exceeded",
    everyone: "Everyone",
    exactSearch: "Exact search",
    exactSearchResults: "Exact search results",
    exampleBindingIC50Name: "Binding IC50",
    exampleBindingIC50Thresold: "Lowest possible",
    exampleBindingIC50Title: "Example 2: Binding IC50",
    exampleBindingIC50Units: "nM",
    exampleCellDeathName: "Cell death",
    exampleCellDeathThresold: "Lowest within range",
    exampleCellDeathTitle: "Example 1: Cell Death",
    exampleCellDeathUnits: "%",
    exampleScenarios: "Example scenarios",
    exclude: "Exclude",
    exitFullScreen: "Click to exit full screen (or press Escape)",
    exitFullScreenSimple: "Exit full screen",
    expand: "Expand",
    exploreChemicalSearchRoutes: "Explore your chemical search routes",
    exploreResults: "Explore results",
    exploreRoute: "Explore route",
    exploreScenarios: "Explore scenarios",
    export: "Export",
    exportCsv: "Export CSV",
    exportRoute: "Export route",
    familyName: "Family name",
    featureFlagAddedSuccessfully:
      "<strong>{name}</strong> was added successfully.",
    featureFlagAlreadyExists: "This feature flag name is already taken.",
    featureFlagEditedSuccessfully:
      "<strong>{name}</strong> was edited successfully.",
    featureFlagRemovedSuccessfully:
      "<strong>{name}</strong> was removed successfully.",
    featureFlags: "Feature flags",
    featurize: "Featurization",
    featurizePageTitle: "Featurization",
    featurizeTaskHistoryDescription:
      "Track and access results of featurization jobs performed by your team.",
    featurizeTaskHistoryTitle: "Featurization Tasks",
    feedbackReceivedWillBeConsidered:
      "Your feedback was received and will be carefully considered.",
    ffp2: "Morgan (type 2)",
    ffp2Help:
      "Morgan fingerprint using chemical-feature invariants. This is an FCFP-like fingerprint.",
    file: "File",
    fileFormat: "File format",
    fileName: "File name",
    fileNameContainsIllegalChars: "File name contains illegal characters",
    fileNotUploadedProperly:
      "This file may not have been uploaded correctly.\n\nIf this is the case, it will be deleted automatically after 24 hours. Otherwise, this warning sign will disappear once the file is verified.\n\nIf you are able to download this file, you can ignore this warning.",
    filePreview: "File Preview: {fileName}",
    fileSize: "File size",
    fileSubmission: "File submission",
    fileTooLargeContent: "The file size cannot exceed {maxSize}.",
    fileTooLargeTitle: "File is too large",
    filter: "Filter",
    filterBySpecificInfo: "Filter by specific information",
    filterFrom: "Filter from",
    filters: "Filters",
    filtersAndVisualizations: "Filters and visualizations",
    fingerprintType: "Fingerprint type",
    flagRoute: "Flag Route",
    flagsPageDescription: "Review and manage feature flags.",
    flagsPageTitle: "Feature flags",
    forgotPassword: "Forgot your password?",
    foundResults: "Found {count, plural, =1 {1 result} other {# results}}",
    fullName: "Full name",
    fullNameLabel: "Full name:",
    fullNamePlaceholder: "Enter your full name",
    fullNameTip:
      "Help people recognize you by using the name you are known by: either your full name, nickname, or business name.",
    general: "General",
    generalComment: "General comment",
    generalComments: "General comments",
    generatedMolecules: "Generated compounds",
    generatedMoleculesCollapse:
      "Generated compounds (mandatory on first submission)",
    genericTemplateLabel_json: "json",
    genericTemplateLabel_py: "python",
    genericTemplateLabel_txt: "text",
    genericTemplateLabel_yaml: "yaml",
    genericTemplatesPageDescription:
      "Manage reusable pieces of information to rapidly launch tasks and tasks.",
    genericTemplatesPageTitle: "Templates",
    giveNameToFirstTeam: "Give a name to your team...",
    givenName: "Given name",
    goBack: "Go back",
    goBackToDrive: "Go back to Drive",
    goBackToGroups: "Go back to groups",
    goBackToReview: "Go back to review",
    goFullScreen: "Go full screen (recommended)",
    goFullScreenSimple: "Go full screen",
    goTo: "Go to {entity}",
    goToDrive: "Go to drive",
    goToLocation: "Go to location",
    goToReviewSummary: "Go to review summary",
    goToValencePlatform: "Go to Valence Labs Platform",
    group: "Group",
    groupActions: "Group actions",
    groupAlreadyExists: "A group with this name already exists.",
    groupBy: "Group by",
    groups: "Groups",
    groupsPageDescription: "Review and manage the groups of your team.",
    groupsPageTitle: "Groups",
    groupType_main_fragment: "Single Fragment",
    groupType_scaffold: "Scaffold",
    help: "Help",
    helpWithShortcut: "Navigation Help ({keyboardShortcut})",
    hidden: "Hidden",
    hiddenColumns: "Hidden columns",
    hideSuggested: "Hide suggestions",
    hideThis: "Hide this",
    history: "History",
    home: "Home",
    homePageActiveProjects: "Active projects",
    homePageChemicalSearchesDescription:
      "Explore results of recent chemical searches.",
    homePageDescription: "Track the activities and resources of your team.",
    homePageNoChemicalSearchesWarningDescription:
      "No chemical searches were made yet.",
    homePageNoCompoundLibrariesWarningDescription:
      "No compound libraries were added yet.",
    homePageNoMembersWarningDescription: "Add members or delete this team.",
    homePageNoMembersWarningTitle: "No members in this team.",
    homePageNoProjectsWarningDescription: "No projects were added yet.",
    homePageNoStrategiesWarningDescription: "No strategies were added yet.",
    homePageOneMemberWarningDescription:
      "There is only one member in this team. Add additional members to collaborate with on the platform.",
    homePageStrategiesDescription:
      "Explore and review compounds generated from recent strategies.",
    hoverStructureWillAppear: "Highlighted structure will appear here.",
    howCanWeHelp: "How can we help?",
    howCanWeHelpDescription:
      "Let us know how we can help by filling the form below.",
    howCanWeHelpMessageFieldLabel: "Message",
    howCanWeHelpMessageFieldPlaceholder: "Enter your message here.",
    howCanWeHelpOptionMessageTopicField_FE:
      "I would like to suggest a feature.",
    howCanWeHelpOptionMessageTopicField_LI:
      "I would like to request a limit increase.",
    howCanWeHelpOptionMessageTopicField_PR:
      "I would like to report a problem/bug.",
    howCanWeHelpOptionMessageTopicField_QU:
      "I am confused by the platform and have questions.",
    howCanWeHelpOptionMessageTopicFieldLabel: "Topic",
    howCanWeHelpSend: "Send message",
    howCanWeHelpTitle: "How can we help?",
    id: "ID",
    identifiers: "Identifiers",
    idReservedColTooltip:
      "This column is used to know whether a given row should update a molecule entry or create a new one.\n\nFor an update to take place, the provided id should be identical to the compound id seen during a review or review summary.\n\nThe 'display_id' column takes precedence over the 'compound_id' column.",
    imageActions: "Image actions",
    imageCopyUnsupportedForThisNavigator:
      "Image copy not supported for this navigator",
    import: "Import",
    inchikey: "InChIKey",
    inchikeySearchPlaceholder: "Search by InChiKey",
    include: "Include",
    information: "Information",
    inputFile: "Input file",
    inputPath: "Input path",
    inputRemoteHelp: "Specify an S3 or Google Storage path",
    integrations: "Integrations",
    interval: "Interval",
    invalidAddProperty:
      "You cannot add an assay with invalid parameter combination.",
    invalidStatementsLogic: "Invalid statements logic",
    isItSolved: "Solved",
    isSmartsOrSmiles: "Is SMARTS or SMILES?",
    kernelLabel: "Valence Labs Platform",
    label: "Label",
    large: "Large",
    lastLibraryAdded: "Last library added",
    lastMemberInvited: "Last member invited",
    lastRefresh: "Last refresh",
    lastRunAt: "Last run",
    lastSaved: "Last saved",
    lastUpdate: "Last update",
    lastUpdateWithTime: "Last update {date}",
    launch: "Launch",
    launchNamedSearch: "Launch named search",
    launchQuickSearch: "Launch quick search",
    launchScoringJob: "Launch scoring job",
    launchSearch: "Launch search",
    launchSubmission: "Launch submission",
    leadTimeDescription: "Lead time in weekdays. Lead time is an estimate.",
    leadTimeInDays: "Lead Time",
    leadTimeMayVary: "Lead time may vary",
    leaveAReviewComment:
      "Would you like to leave a general comment with your review? (optional)",
    leaveARouteFlaggingComment:
      "Would you like to leave a general comment with your flagging? (optional)",
    leaveFeedbackAboutRoute: "Something wrong with this route?",
    legacyReview: "Legacy review ",
    legacyReviewContext:
      "A strategy that had no reviewers assigned to it. All members of the team shared the same review.",
    library: "Library",
    license: "License",
    licenseLimit_limit_chemfilters: "Chemical Filters",
    licenseLimit_limit_chemsearch_max_results: "Chemical Search Results",
    licenseLimit_limit_chemsearch_per_day: "Chemical Searches",
    licenseLimit_limit_drive_storage: "Storage Space",
    licenseLimit_limit_organization_members: "Team Members",
    licenseLimit_limit_projects: "Projects",
    licenseLimit_limit_scoring_per_day: "Scoring Jobs",
    licenseLimit_limit_strategies: "Strategies",
    licenseLimitSuffix_limit_chemfilters: "filters",
    licenseLimitSuffix_limit_chemsearch_max_results: "results per search",
    licenseLimitSuffix_limit_chemsearch_per_day: "searches per day",
    licenseLimitSuffix_limit_drive_storage: "GB",
    licenseLimitSuffix_limit_organization_members: "members",
    licenseLimitSuffix_limit_projects: "projects",
    licenseLimitSuffix_limit_scoring_per_day: "jobs per day",
    licenseLimitSuffix_limit_strategies: "strategies",
    limit_CHEMICAL_SEARCH_LIMIT_EXCEEDED:
      "Maximum number of chemical searches per day reached. Please contact us if you need to increase that limit.",
    limit_CHEMICAL_SEARCH_MAX_RESULTS_LIMIT_EXCEEDED:
      "Too many results requested. You are trying to request more results then your team license allows. Please contact us if you need to increase that limit.",
    limit_DRIVE_STORAGE_LIMIT_EXCEEDED:
      "Storage limit reached. Please contact us if you need to increase that limit.",
    limit_ORGANIZATION_MEMBER_LIMIT_EXCEEDED:
      "Team members limit reached. Please contact us if you need to increase that limit.",
    limit_SCORING_JOBS_LIMIT_EXCEEDED:
      "Maximum number of scoring jobs per day is reached. Please contact us if you need to increase that limit.",
    limits: "Limits",
    loading: "Loading",
    logicalOperator_AND: "AND",
    logicalOperator_OR: "OR",
    logicMissing: "Logic is missing",
    logicType_EX: "Exclude",
    logicType_IN: "Include",
    lowerLimit: "Lower limit",
    machineLearning: "Machine learning",
    machineName: "Machine name",
    machineType: "Machine type",
    mainDescriptors: "Main descriptors",
    mainFragmentOnly: "Single fragment",
    mainFragmentOnlyCheckedDescription:
      "Only molecules with a single fragment will be included.",
    mainFragmentOnlyDescription:
      "Exclude molecules with more than a single fragment.",
    mainFragmentOnlyNotCheckedDescription:
      "Molecules with one or more fragments will be included.",
    mainFragmentOnlyScenarioDescription:
      "Perform a search where molecules with more than one fragment will be excluded.",
    mainName: "Main name",
    manage: "Manage",
    manageColumns: "Manage columns",
    manageUsers: "Manage users",
    markAsComplete: "Mark as completed",
    markAsOpen: "Reopen for review",
    matchMaxValue: "Match Max Value",
    matchMinValue: "Match Min Value",
    matchType: "Match Type",
    matchType_EQ: "Equal",
    matchType_GT: "Greater than",
    matchType_GTE: "Greater or Equal to",
    matchType_LT: "Less than",
    matchType_LTE: "Less or Equal to",
    matchType_WR: "Within range",
    matchTypeMissing: "Match type is missing",
    matchValue: "Match Value",
    matchValueMissing: "Match value is missing",
    maxFilesUploadExceeded: "Maximum files per upload exceeded",
    maxFilesUploadExceededDescription:
      "Please upload between one and ten files at a time.",
    maxSearchCompoundsExceededDescription:
      "The number of structures to search with must be between 1 and 100.",
    maxSearchCompoundsExceededTitle: "Maximum structures exceeded",
    meaningfulNameSearch: "Type a meaningful name",
    medchemAlert_alarm_nmr: "Alarm-NMR",
    medchemAlert_alphascreen: "Alphascreen Frequent Hitters",
    medchemAlert_bms: "BMS",
    medchemAlert_brenk: "Brenk Filters",
    medchemAlert_dnabinder: "DNA Binders",
    medchemAlert_dundee: "Dundee",
    medchemAlert_electrophilic: "Electrophilic",
    medchemAlert_glaxo: "GSK",
    medchemAlert_hitters: "Frequent Hitters",
    medchemAlert_inpharmatica: "Inpharmatica",
    medchemAlert_ld50_oral: "Oral LD50",
    medchemAlert_lint: "Pfizer Lint",
    medchemAlert_luciferase: "Luciferase Inhibitor",
    medchemAlert_mlsmr: "NCATS SMR Alerts",
    medchemAlert_nibr: "Novartis Screening Filters",
    medchemAlert_nih: "NIH Filters",
    medchemAlert_pains: "PAINS",
    medchemAlert_pains_a: "PAINS_A",
    medchemAlert_pains_b: "PAINS_B",
    medchemAlert_pains_c: "PAINS_C",
    medchemAlert_schembl: "SureChEMBL",
    medchemAlert_toxicophore: "Common toxicophores",
    medchemAlert_zinc: "ZINC Database Filters",
    medchemAlertDescription_: "Pan Assay Interference Compounds (family A)",
    medchemAlertDescription_alarm_nmr:
      "Alerts for aggregators/promiscuous compounds.",
    medchemAlertDescription_alphascreen:
      "Compounds that may be alphascreen frequent hitters.",
    medchemAlertDescription_bms: "Bristol-Myers Squibb HTS Deck Filters",
    medchemAlertDescription_brenk:
      "Unwanted functional groups for screening libraries (University of Dundee)",
    medchemAlertDescription_dnabinder:
      "Compounds that may be alphascreen frequent hitters",
    medchemAlertDescription_dundee:
      "Filters for the NTD Screening Library (University of Dundee)",
    medchemAlertDescription_electrophilic:
      "Compounds that are likely to take part in electrophilic reactions",
    medchemAlertDescription_glaxo: "Glaxo Wellcome Research HTS Filters",
    medchemAlertDescription_hitters: "Known promiscuous compounds",
    medchemAlertDescription_inpharmatica:
      "Inpharmatica unwanted fragments filters",
    medchemAlertDescription_ld50_oral:
      "Orally administrated compounds that are linked to acute toxicity",
    medchemAlertDescription_lint:
      "Pfizer 'Lint' procedure for removing problematic functionality",
    medchemAlertDescription_luciferase:
      "Compounds that interfers with luciferase-based assays",
    medchemAlertDescription_mlsmr:
      "Chemically reactive functional groups that would interfere with HTS, as well as compounds likely to be promiscuous aggregators.",
    medchemAlertDescription_nibr: "Novartis filters for screening deck",
    medchemAlertDescription_nih: "Compounds with problematic functional groups",
    medchemAlertDescription_pains: "Pan Assay Interference Compounds (full)",
    medchemAlertDescription_pains_b:
      "Pan Assay Interference Compounds (family B)",
    medchemAlertDescription_pains_c:
      "Pan Assay Interference Compounds (family C)",
    medchemAlertDescription_schembl: "SureChEMBL chemical alerts",
    medchemAlertDescription_toxicophore:
      "Common substructure linked to toxicity",
    medchemAlertDescription_zinc:
      "ZINC chemical alerts and unwanted functional groups",
    medchemAlertRef_alarm_nmr: "https://pubs.acs.org/doi/10.1021/ja0455547",
    medchemAlertRef_bms: "https://pubs.acs.org/doi/10.1021/ci050504m",
    medchemAlertRef_brenk:
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2628535/",
    medchemAlertRef_dundee:
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2628535/",
    medchemAlertRef_glaxo: "https://pubs.acs.org/doi/10.1021/ci990423o",
    medchemAlertRef_inpharmatica:
      "https://www.soci.org/~/media/Files/Conference%20Downloads/2011/Designing%20Safer%20Medicines%20in%20Discvery%20Mar%202011/Francis_Atkinson_Presentation.ashx",
    medchemAlertRef_lint:
      "https://www.soci.org/~/media/Files/Conference%20Downloads/2011/Designing%20Safer%20Medicines%20in%20Discvery%20Mar%202011/Francis_Atkinson_Presentation.ashx",
    medchemAlertRef_mlsmr:
      "https://mlsmr.evotec.com/MLSMR_HomePage/pdf/MLSMR_Excluded_Functionality_Filters_200605121510.pdf",
    medchemAlertRef_nibr:
      "https://pubs.acs.org/doi/10.1021/acs.jmedchem.0c01332",
    medchemAlertRef_nih: "https://pubmed.ncbi.nlm.nih.gov/25408068/",
    medchemAlertRef_pains: "https://pubmed.ncbi.nlm.nih.gov/20131845/",
    medchemAlertRef_pains_a: "https://pubmed.ncbi.nlm.nih.gov/20131845/",
    medchemAlertRef_pains_b: "https://pubmed.ncbi.nlm.nih.gov/20131845/",
    medchemAlertRef_pains_c: "https://pubmed.ncbi.nlm.nih.gov/20131845/",
    medchemAlertRef_schembl: "https://www.surechembl.org/knowledgebase/169485",
    medchemAlertRef_zinc: "https://blaster.docking.org/filtering/",
    medchemRule_rule_of_cns: "Rule of CNS",
    medchemRule_rule_of_egan: "Rule of Egan",
    medchemRule_rule_of_five: "Rule-of-5",
    medchemRule_rule_of_five_beyond: "Beyond rule-of-5 ",
    medchemRule_rule_of_four: "Rule-of-4",
    medchemRule_rule_of_ghose: "Ghose filter",
    medchemRule_rule_of_gsk_4_400: "Rule of GSK (4/400)",
    medchemRule_rule_of_leadlike_soft: "Lead-Like Soft rule",
    medchemRule_rule_of_oprea: "Oprea's rule",
    medchemRule_rule_of_pfizer_3_75: "Pfizer Rule (3/75 Rule)",
    medchemRule_rule_of_reos: "REOS filter",
    medchemRule_rule_of_three: "Rule-of-3",
    medchemRule_rule_of_three_extended: "Extended rule-of-3",
    medchemRule_rule_of_veber: "Veber filter",
    medchemRule_rule_of_zinc: "Zinc rule",
    medchemRuleDescription_rule_of_cns:
      "Computes drug likeness rule for CNS penetrant molecules",
    medchemRuleDescription_rule_of_egan:
      "Compute passive intestinal absorption according to Egan Rules",
    medchemRuleDescription_rule_of_five:
      "Compute the Lipinski's rule-of-5 for a molecule. Also known as Pfizer's rule of five or RO5, this rule is a rule of thumb to evaluate the druglikeness of a chemical compounds",
    medchemRuleDescription_rule_of_five_beyond:
      "Compute the Beyond rule-of-5 rule for a molecule. This rule illustrates the potential of compounds far beyond rule of 5 space to modulate novel and difficult target classes that have large, flat, and groove-shaped binding sites",
    medchemRuleDescription_rule_of_four:
      "The rule-of-4 define a rule of thumb for PPI inhibitors, which are typically larger and more lipophilic than inhibitors of more standard binding sites.",
    medchemRuleDescription_rule_of_ghose:
      "Compute the Ghose filter. The Ghose filter is a drug-like filter.",
    medchemRuleDescription_rule_of_gsk_4_400:
      "Compute GSK Rule (4/400) for druglikeness using interpretable ADMET rule of thumb",
    medchemRuleDescription_rule_of_leadlike_soft:
      "Compute the Lead-Like Soft rule available in FAF-Drugs4.",
    medchemRuleDescription_rule_of_oprea:
      "Computes Oprea's rule of drug likeness obtained by comparing drug vs non drug compounds across multiple datasets.",
    medchemRuleDescription_rule_of_pfizer_3_75:
      "Compute Pfizer Rule(3/75 Rule) for invivo toxicity.",
    medchemRuleDescription_rule_of_reos:
      "The REOS filter is a filter designed to filter out unuseful compounds from HTS screening results. ",
    medchemRuleDescription_rule_of_three:
      "The rule-of-three is a rule of thumb for molecular fragments (and not small molecules)",
    medchemRuleDescription_rule_of_three_extended:
      "This is an extension of the rule-of-3. It computes: `MW <= 300 & logP in [-3, 3]  & HBA <= 6 & HBD <= 3 & ROTBONDS <= 3 & TPSA <= 60`.",
    medchemRuleDescription_rule_of_veber:
      "The Veber filter is a druglike filter for orally active drugs.",
    medchemRuleDescription_rule_of_zinc:
      "The Zinc rule is a rule of thumb to evaluate the druglikeness of a chemical compounds.",
    medchemRuleRef_rule_of_cns:
      "https://www.sciencedirect.com/science/article/abs/pii/S0969996109002113",
    medchemRuleRef_rule_of_egan:
      "https://pubs.acs.org/doi/abs/10.1021/jm000292e",
    medchemRuleRef_rule_of_five:
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4910824/",
    medchemRuleRef_rule_of_five_beyond:
      "https://pubmed.ncbi.nlm.nih.gov/26457449/",
    medchemRuleRef_rule_of_four: "https://pubmed.ncbi.nlm.nih.gov/33209039/",
    medchemRuleRef_rule_of_ghose: "https://pubs.acs.org/doi/10.1021/cc9800071",
    medchemRuleRef_rule_of_gsk_4_400:
      "https://pubs.acs.org/doi/10.1021/jm701122q",
    medchemRuleRef_rule_of_leadlike_soft:
      "https://fafdrugs4.rpbs.univ-paris-diderot.fr/filters.html",
    medchemRuleRef_rule_of_oprea: "https://pubmed.ncbi.nlm.nih.gov/10756480/",
    medchemRuleRef_rule_of_pfizer_3_75:
      "https://www.sciencedirect.com/science/article/abs/pii/S0960894X08008500",
    medchemRuleRef_rule_of_reos: "https://pubmed.ncbi.nlm.nih.gov/12669025/",
    medchemRuleRef_rule_of_three: "https://pubmed.ncbi.nlm.nih.gov/14554012/",
    medchemRuleRef_rule_of_veber: "https://pubs.acs.org/doi/10.1021/jm020017n",
    medchemRuleRef_rule_of_zinc: "https://pubs.acs.org/doi/10.1021/ci049714%2B",
    medicinalChemistryAlerts: "Medicinal chemistry alerts",
    medium: "Medium",
    member: "Member",
    members: "Members",
    memberSince: "Member since",
    memberSinceDate: "Member since {date}",
    membersPageDescription: "Review and manage the members of your team.",
    membersPageTitle: "Members",
    mfp2: "Morgan (type 1)",
    mfp2Help:
      "Morgan fingerprint using connectivity invariants. This is an ECFP-like fingerprint.",
    mode: "Mode",
    modelAnalysisTasksSideNavTitle: "Model analysis",
    modelDescription: "Model description",
    modelInformation: "Model information (ada info)",
    modelName: "Model name",
    models: "Models",
    modelsAssignmentTitle: "Models assignment for project <i>{project}</i>",
    modelsConfiguration: "Models configuration",
    modelSelection: "Model selection",
    modelSelectionTasksPageDescription:
      "Track and access results of model selection jobs performed by your team.",
    modelSelectionTasksPageTitle: "Model selection tasks",
    modelSelectionTasksSideNavTitle: "Model selection",
    modelsInformation: "Model information (ada infos)",
    modelsList: "Models list",
    modelVersion: "Model version",
    modelversionsAssignmentTitle:
      "Model versions assignment for strategy <i>{strategy}</i>",
    modify: "Modify",
    molecularEditor: "Molecular editor",
    molecularSketching: "Molecular Sketching",
    moleculeAlerts: "Alerts",
    moleculeEditor: "Editor",
    moleculeResultModalTitle: "SMILES: {smiles}",
    moleculeReview: "Compound reviews",
    molecules: "Molecules",
    moleculesDeletedSuccessfully: "Compounds deleted successfully.",
    moleculesPlural: "{count, plural, =1 {1 molecule} other {# molecules}}",
    moleculesSubmissionTitle:
      "Compounds submission for project <i>{project}</i>, and strategy <i>{strategy}</i>",
    moleculesSubmittedSuccessfully: "Compounds submitted successfully.",
    moleculeSubmissionConfirmation:
      "<p>You are about to submit <strong>{n_molecules} new compound(s) for prediction</strong>.</p><p>Compounds that were already submitted in this project will be ignored.</p><p><strong>Note:</strong> Predictions for these compounds will appear in the next strategy of this project.</p>",
    moleculeSubmissionError_EMBEDDING_COL_MISSING:
      "The file provided does not contain valid embedding columns for latent space visualization: {embeddingColumns}. To make the latent space visualization available, provide two-dimensional or three-dimensional embeddings with column headers in lowercase.",
    moleculeSubmissionError_FILE_EMPTY: "The file provided contains no data.",
    moleculeSubmissionError_MANDATORY_COL_MISSING:
      "The file provided does not contain all mandatory columns: {mandatoryColumns}. Make sure all these columns are present and column headers are in lowercase.",
    moreActions: "More actions",
    moreDetails: "More details:",
    moreInfo: "More info",
    moveTo: "Move to",
    moveToTrash: "Remove",
    multiFileSupport:
      "The file should be in a .xlsx, .xls, .sdf, or .csv format.",
    multipleStructures: "Multiple structures",
    multipleStructuresScenarioDescription:
      "Perform searches using up to ten SMILES or SMARTS.",
    mustBePositive: "Must be positive",
    na: "N/A",
    name: "Name",
    nameForSubmission: "Name for submission",
    nameMissing: "Name is missing",
    nameYourSearch: "Name your search (optional)",
    navigation: "Navigation",
    navigationHelp: "Navigation Help",
    nBlocks: "{count} blocks",
    nCompoundsWillBeScored:
      "The models from this project are about to predict properties for <b>{nCompounds, plural, =1 {1 new compound} other {# new compounds}}</b>.",
    nCompoundsWillBeScoredSub:
      "Give a meaningful name to the job scoring your compounds for later reference.",
    negative: "Negative",
    newFolder: "New Folder",
    newPassword: "New password",
    newSearch: "New search",
    next: "Next",
    nextMolecule: "Next compound",
    nextStep: "Next step",
    no: "No",
    noActivities: "No activities",
    noAdditionalCatalogsFound: "No additional catalogs found.",
    noAlerts: "This molecule is free of medicinal chemistry alerts.",
    noAlertsSimple: "No alerts.",
    noAssignedReviewers: "No reviewers assigned",
    noAvailabilitiesFromProvider: "No availabilities from providers.",
    noChemicalFilters: "No chemical filters have been assigned yet.",
    noChemicalSearches: "No searches have been performed yet.",
    noComment: "No comment.",
    noCommentLeft: "No comment left.",
    noComments: "No comments.",
    noCommentsLeft: "No comments were left for this compound.",
    noCompoundLibraries: "No compound libraries have been added yet.",
    noCompoundsSubmittedYet:
      "No compounds were submitted for this project yet.",
    noCompoundsToSubmit: "No compounds to submit",
    noCompoundsToSubmitContent:
      "Make sure to submit a file with at least one compound.",
    noDisplayedColumns: "No displayed columns",
    noDriveFiles: "This folder is empty.",
    noExtraInfoAvailable: "No additional information available",
    noFeatureFlags: "No feature flags.",
    noFeaturizeTasks: "No featurize tasks have been launched yet.",
    noFilesAdded: "No files were added yet.",
    noFilesOrFolders:
      'No files or folders found for the query "<span>{query}</span>".',
    noFiltersCreated: "No filters created",
    noFiltersSelected: "No filters selected",
    noGenericTemplates: "No templates were added yet",
    noGroups: "No groups have been added yet.",
    noHiddenColumns: "No hidden columns",
    noInformationProvided: "No information provided",
    noIntegrationsDescription:
      "Your team has not yet subscribed to any integrations available.",
    noMembers: "No members have been added yet.",
    noMembersInGroup: "No members in group",
    noMembersInTeamForAddGroup:
      "There is no members in this team. Add members before creating a group.",
    noMembersInTeamForEditGroup:
      "There is no members in this team. Add members before editing this group.",
    noModels: "No models have been assigned yet.",
    noModelVersions: "No model versions have been assigned yet.",
    none: "None",
    noneApplied: "None applied.",
    noneProvided: "None provided.",
    noOrganizationMolecules: "Your team does not have molecules at the moment.",
    noOrganizations: "No teams have been added yet.",
    noPatents: "No patents have been uploaded yet.",
    noPeriodicTasks: "No periodic tasks registered yet",
    noProjects: "No projects have been added yet.",
    noProjectsInOrg: "No projects in this team.",
    noProjectsInOrgError:
      "You must have at least one project in your team before being able to add a strategy.",
    noPropertiesAddedYet: "No assay readouts added yet.",
    noResults: "No results.",
    noResultsSimilarity: "No results found for similarity search.",
    noResultsSubstruct: "No results found for substructure search.",
    noReviewerCandidates:
      "No members have the required privileges to review strategies.",
    noReviewersToSelect: "There is no reviewer candidates to select.",
    noRoutesAvailable: "No synthesis routes found for this structure.",
    noScoringTasks: "No scoring tasks have been launched yet.",
    noServices: "No services exist.",
    noStrategies: "No strategies have been added yet.",
    noStructureProvided: "No structure provided",
    noStructureToAdd: "No structures provided.",
    noTasks: "No tasks have been launched yet.",
    notAvailable: "Not available",
    noTeams: "No teams",
    notifications: "Notifications",
    notifyMeWhenTaskComplete: "Send me an email when results are ready.",
    noTrashedFiles: "Nothing in Trash.",
    noUsers: "No users",
    noVisibleColumns: "No visible columns",
    nSteps: "{count} steps",
    numberOfAlerts: "Number of alerts",
    numberOfAlertsDescription:
      "Number of medicinal chemistry alerts for a single compound",
    numberOfChemicalFiltersSelected:
      "{numberOfItems} chemical filters selected ({chemicalFilters})",
    numberOfCompoundsTooLargeContent:
      "The number of compounds per submission cannot exceed {maxSize}.",
    numberOfCompoundsTooLargeTitle: "Too many compounds.",
    numberOfFragments: "Number of fragments",
    numberOfFragmentsDescription: "Number of fragments for a single compound",
    numberOfItemsSelected: "Selected {numberOfItems} items",
    numberOfModelsSelected: "{numberOfItems} models selected",
    numberReviewComments: "Review comments",
    of: "of",
    ok: "Ok",
    oneLowerCaseLetterConstraint: "One lowercase letter",
    oneNumberConstraint: "One number",
    oneSpecialCharacterConstraint: "One special character",
    oneUpperCaseLetterConstraint: "One uppercase letter",
    onlyOneStructureAllowedDescription:
      "You need to provide only one structure.",
    onlyOneStructureAllowedTitle: "Only one structure allowed",
    openImageInNewTab: "Open image in new tab",
    openInWorkspace: "Open in workspace",
    operatorMissing: "Operator is missing",
    or: "or",
    organization: "Team",
    organizationAddedSuccessfully:
      "<strong>{name}</strong> was added successfully.",
    organizationAlreadyExists: "This team name is already taken.",
    organizationEditedSuccessfully:
      "<strong>{name}</strong> was edited successfully.",
    organizationMemberships: "Team memberships",
    "organizationMoleculesPage.pageTitle": "Molecules",
    "organizationMoleculesPage.pageDescription":
      "Explore and manage molecules of your team.",
    organizationRemovedSuccessfully:
      "<strong>{name}</strong> was removed successfully.",
    organizations: "Teams",
    organizationSettings: "Team Settings",
    organizationSettingsDescription: "Manage your team settings.",
    organizationSettingsTitle: "Team Settings",
    organizationsPageDescription: "Review and manage teams.",
    organizationsPageTitle: "Teams",
    originalCompound: "Original compound from Valence Labs",
    originalName: "Original name",
    otherActions: "Other Actions",
    outOf: "out of",
    outputPath: "Output path",
    outputPathFolder: "Output path (folder)",
    overview: "Overview",
    ownerDescription: "Owner can view, edit, and delete the resource.",
    ownerLabel: "Owner",
    page: "Page",
    panelN: "Panel {n}",
    "parseCDDForm.protocolConfiguration": "Protocol configuration",
    "parseCDDForm.targetColLabel": "Column for target name",
    "parseCDDForm.targetColTooltip":
      "The name of column in which to retrieve the name of the target protein.",
    "parseCDDForm.targetColPlaceholder": "Enter a column name",
    "parseCDDForm.targetNameLabel": "Target name",
    "parseCDDForm.targetNameTooltip":
      "The name of the target protein. If specified, this argument will override the target name in the target column.",
    "parseCDDForm.targetNamePlaceholder": "Enter a target name",
    "parseCDDForm.plateNameLabel": "Column for plate name",
    "parseCDDForm.plateNameTooltip":
      "The name of the column in which to retrieve the name of the plate.",
    "parseCDDForm.plateNamePlaceholder": "Enter a column name",
    "parseCDDForm.plateIDLabel": "Plate ID",
    "parseCDDForm.plateIDTooltip":
      "The assay plate ID. If the plate ID is not specified in the file name, it will fallback to the value specified in this input.",
    "parseCDDForm.plateIDPlaceholder": "Enter a plate ID",
    "parseCDDForm.dataSheetLabel": "Excel sheet name",
    "parseCDDForm.dataSheetTooltip":
      "The name of the Excel sheet containing the data.",
    "parseCDDForm.dataSheetPlaceholder": "Enter a sheet name",
    "parseCDDForm.dataColLabel": "Column for readout data",
    "parseCDDForm.dataColTooltip":
      "The name of the column in which to retrieve the key readout data.",
    "parseCDDForm.dataColPlaceholder": "Enter a column name",
    "parseCDDForm.atpConcentrationLabel": "ATP concentration",
    "parseCDDForm.atpConcentrationTooltip":
      "The ATP concentration of the protocol. If this value cannot be detected in the file name, it will fallback to the value specified in this input.",
    "parseCDDForm.atpConcentrationPlaceholder": "Enter an ATP concentration",
    "parseCDDForm.parsingConfiguration": "Parsing configuration",
    "parseCDDForm.cleanDataLabel": "Clean data",
    "parseCDDForm.cleanDataTooltip":
      "Remove the special characters from the readout column.",
    "parseCDDForm.mergeDataLabel": "Merge data",
    "parseCDDForm.mergeDataTooltip":
      "Merge the extracted data from files which share the same protocol.",
    parseFilesForCDDUpload: "Parse files for CDD Upload ({count} selected)",
    parseForCDD: "Parse for CDD",
    parsingFile: "Parsing file...",
    password: "Password",
    passwordRules:
      "<p>Your password must be between 8 and 25 characters.</p><p>It should also contain at least one uppercase and lowercase letter, one number digit, and one symbol.</p>",
    patentor: "Patent Extractor",
    patentorPageDescription:
      "Extract, retrieve, and manage information retrieved from patent documents.",
    patentorPageTitle: "Patent Extractor",
    patentorPatentListTitle: "Patents",
    pdf: "PDF",
    pdfReportSupport: "The results report should be in a .pdf format.",
    performingSearch: "Performing search...",
    periodicTask: "Periodic Task",
    periodicTasksDescription: "Track celery beat registered periodic tasks",
    periodicTasksTitle: "Periodic Tasks",
    permissionRestrictedPage:
      "You do not have the permissions to access this page.",
    permissionsMayChangeDescription:
      "If restrictions were set on some resources, these will be removed and inherit the permissions of the folder you selected.",
    permissionsMayChangeTitle: "Permissions may change",
    platformStatus: "Platform status",
    platformStatusFunctional: "Functional",
    pleaseAddMembers: "Please add members to this group.",
    pleaseAddReviewers: "Please add reviewers to this strategy.",
    pleaseAlert: "Alert is missing",
    pleaseAlphaNumericHyphen:
      "The name must contain letters, numbers and hyphens only.",
    pleaseAlphaNumericSpaceHyphen:
      "The name must contain letters, numbers, spaces and hyphens only.",
    pleaseAtLeastOneAssay: "Please specify at least one assay.",
    pleaseChemicalFilterDescription:
      "Please input a description for this chemical filter.",
    pleaseChemicalFilterName: "Please input a valid chemical filter name.",
    pleaseCompoundLibraryDescription:
      "Please input a description for this compound library.",
    pleaseConfirmPassword: "Please confirm your password.",
    pleaseDescription: "Please input a description",
    pleaseDescriptor: "Descriptor missing",
    pleaseDescriptors: "Please select descriptors",
    pleaseEnterAMessage: "Please provide a message.",
    pleaseEnterAValidEmail: "Please enter a valid email",
    pleaseEnterYourCommentHere: "Enter your comment here.",
    pleaseEnterYourConfirmationCode: "Please enter confirmation code.",
    pleaseFilter: "Filter name is missing",
    pleaseFunctionalGroup: "Functional group missing",
    pleaseGroupDescription: "Please input a description for this group.",
    pleaseInputValue: "Please input a value",
    pleaseLimitIncrease: "To request a limit increase",
    pleaseMax255: "Please input a value with 255 characters maximum.",
    pleaseOneReviewer: "Select at least one reviewer",
    pleasePasswordRules:
      "The password must be between 8 and 25 characters. It must also contain one uppercase and lowercase letter, one number digit, and one symbol.",
    pleaseProjectDescription: "Please input a description for this project.",
    pleaseProjectTitle: "Please input a valid project title.",
    pleaseProvideAFileWithCompoundLibrary:
      "Please input a file for this compound library.",
    pleaseProvideAFileWithDatasetTemplate:
      "Please input a file based on the dataset template.",
    pleaseProvideMessageReason: "Please select an option.",
    pleaseRule: "Rule is missing",
    pleaseSamePassword: "The two passwords you entered do not match.",
    pleaseSelect: "Please select",
    pleaseSelectALogic: "Please select a logic",
    pleaseSelectAnOperator: "and/or",
    pleaseSelectAnOption: "Please select an option",
    pleaseSelectAProject: "Please select a related project.",
    pleaseSelectAStructure: "Click to select a structure",
    pleaseSelectCatalogs: "Please select catalogs",
    pleaseSelectOneModel:
      "Please select at least one model in the Model selection panel.",
    pleaseSelectRole: "Please select a role.",
    pleaseSelectThreshold: "Please select a threshold.",
    pleaseSelectType: "Please select type",
    pleaseSomethingWrongApp:
      "Something is wrong. You may not be able to access the application at the moment.",
    pleaseSomethingWrongSignup:
      "Something is wrong. You may not be able to signup at the moment.",
    pleaseStrategyDescription: "Please input a description for this strategy.",
    pleaseStrategyTitle: "Please input a valid strategy title.",
    pleaseUploadCompounds: "Please upload at least one compound.",
    pleaseUploadFileLess100MB: "Please upload a file no larger than 100 MB.",
    pleaseUserDontExists: "This user do not exist.",
    pleaseUserExists: "This user already exists.",
    pleaseValidCode: "Please input a valid code.",
    pleaseValidEmail: "Please input a valid email address.",
    pleaseValidFamilyName: "Please input a valid family name.",
    pleaseValidFolderName: "Please input a valid folder name.",
    pleaseValidFullName:
      "Please enter a full name between 2 and 50 characters.",
    pleaseValidGivenName: "Please input a valid given name.",
    pleaseValidMessage: "Please valid message.",
    pleaseValidName: "Please input a valid name.",
    pleaseValidNumber: "Please input a valid number",
    pleaseValidPassword: "Please input a valid password.",
    pleaseValidPropertyName: "Please input a valid name for this assay.",
    pleaseValidStructure: "Please input a valid chemical structure.",
    pleaseValidTeamName: "Please input a valid team name.",
    pleaseValidUnits: "Please input valid units.",
    pleaseWait: "Please wait a moment",
    plusMore: "+ {count} more",
    popConfirmAddProjectText:
      "Would you like to add a strategy to this project now?",
    popConfirmRemoveProjectText:
      "Are you sure? This action will delete all strategies and reviewed compounds related to this project.",
    positive: "Positive",
    predictCompounds: "Predict compounds",
    predictedProperties: "Predicted properties",
    predictFrom: "Predict from",
    preparingImage: "Preparing image...",
    preview: "Preview",
    previewPlatformV2: "Preview Platform Beta",
    previewReport: "Preview report",
    previewResults: "Preview results",
    previous: "Previous",
    previousMolecule: "Previous compound",
    previousStep: "Previous step",
    priceAndLeadtimeContext:
      "Price from: {provider}{lineBreak}{lineBreak}Price is per 1 milligram and lead time days are weekdays. Price and lead time are estimates.",
    priceAndLeadtimeContextSimple:
      "Price is per 1 milligram and lead time days are weekdays. Price and lead time are estimates.",
    priceAndLeadTimeVary: "Price unavailable",
    priceDescription: "Estimated price per 1 milligram",
    priceMayVary: "Price unavailable",
    priceMayVaryTooltip:
      "Price and lead time information can not be found in any commercial provider catalog. Custom synthesis may be required.",
    pricePerMilligram: "Price",
    priority: "Review score",
    priorityHigh: "Prioritize",
    priorityLow: "Deprioritize",
    priorityNeutral: "Neutral",
    priorityUnreviewed: "Unreviewed",
    profileInformation: "Profile information",
    profileSettings: "Profile",
    progressSaved: "Progress saved successfully.",
    project: "Project",
    projectAddedNotificationDescription:
      "To consult your project at a later time, access the <strong>Projects</strong> page from the navigation menu.",
    projectAddedNotificationTitle: "Consulting your project",
    projectAddedSuccessfully:
      "Project <strong>{title}</strong> created successfully.",
    projectAlreadyExists: "A project with this name already exists.",
    projectDeletedSuccessfully:
      "Project <strong>{title}</strong> deleted successfully.",
    projectDescription: "Project description",
    projectOneMemberInviteMember: "Invite members",
    projects: "Projects",
    projectSavedSuccessfully:
      "Project <strong>{title}</strong> saved successfully.",
    projectsCount: "Projects count",
    projectsOverview: "Projects overview",
    projectsPageDescription: "Overview and manage the projects of your team.",
    projectsPageTitle: "Projects",
    projectsRemovedSuccessfully:
      "<strong>{title}</strong> was removed successfully.",
    propertyThresoldHI: "Highest value possible",
    propertyThresoldHW: "Highest within range",
    propertyThresoldJW: "Within range",
    propertyThresoldLO: "Lowest value possible",
    propertyThresoldLW: "Lowest within range",
    providers: "Providers",
    provideStructuresToSearchWith:
      "Perform structure searches and receive detailed information on identified compounds.",
    public: "Public",
    purchaseAvailability: "Purchase availability",
    quickActions: "Quick actions",
    quickView: "Quick view",
    rangeIsInvalid: "Invalid range",
    rank: "Rank",
    rawQuery: "Raw query",
    readouts: "Readouts",
    reason: "Reason:\n{reason}",
    recentChemicalSearches: "Recent Chemical Searches",
    recentFiles: "Recent files",
    recentFilesDescription: "View and manage recent files of your team.",
    recentStrategies: "Recent strategies",
    recentStructures: "Recent structures",
    redirectToHomePageOfNextOrg:
      "<p>You are about to be redirected to the home page of the team <strong>{organization}</strong>.</p><p>Information entered on this page will be lost.</p>",
    refresh: "Refresh",
    refreshDashboards: "Refresh dashboards",
    refreshedData: "Data refreshed!",
    refreshError: "Something went wrong!",
    refreshingData: "Refreshing data...",
    reinitialize: "Reinitialize",
    relatedProject: "Related project",
    relayout: "Relayout",
    remove: "Remove",
    removeInvalidStructures: "Remove or edit invalid structures and try again.",
    removeStatement: "Remove statement",
    removeStrategy: "Remove strategy",
    removeSuggestion: "Remove suggestion",
    removingSuggestion: "Removing suggestion...",
    rename: "Rename",
    renderingError: "Rendering error",
    reportUploadSuccessful: "Report was uploaded successfully.",
    requestedFileDoesNotExist:
      "Sorry, it looks like the requested file does not exist or is no longer available for download.",
    requestLimitIncrease: "Request limit increase",
    research: "Research",
    resendCode: "Resend code",
    reset: "Reset",
    resetAllFilters: "Reset all filters",
    resetFilters: "Reset filters",
    resetPassword: "Reset your password",
    resetPasswordButton: "Reset password",
    resetReview: "Reset review",
    resetUserPassword: "Reset password",
    restErrorAddingCompoundLibraryDescription:
      "<p>Something went wrong while adding a compound library to your team.</p><p>Try adding a compound library again and contact us if the error persists.</p>",
    restErrorAddingCompoundLibraryTitle: "Error adding compound library",
    restErrorAddingMemberDescription:
      "<p>Something went wrong while adding a member to your team.</p><p>Try adding a member again and contact us if the error persists.</p>",
    restErrorAddingMemberTitle: "Error adding member",
    restErrorAddingOrganizationDescription:
      "<p>Something went wrong while adding a team.</p><p>Try adding a team again and contact us if the error persists.</p>",
    restErrorAddingOrganizationTitle: "Error adding team",
    restErrorAddingProjectDescription:
      "<p>Something went wrong while creating the project.</p><p>Try creating the project again and contact us if the error persists.</p>",
    restErrorAddingProjectTitle: "Error adding project",
    restErrorAddingStrategyDescription:
      "<p>Something went wrong while adding a strategy.</p><p>Try creating a strategy again and contact us if the error persists.</p>",
    restErrorAddingStrategyTitle: "Error adding strategy",
    restErrorCreateStrategyDescription:
      "<p>Something went wrong while creating the strategy.</p><p>Try creating the strategy again and contact us if the error persists.</p>",
    restErrorCreateStrategyTitle: "Error creating strategy",
    restErrorDatasetUploadDescription:
      "<p>Something went wrong while uploading the dataset.</p><p>You must delete this strategy and recreate it from the start.</p><p>Make sure your dataset conforms to the dataset template and contact us if the error persists.</p>",
    restErrorDatasetUploadTitle: "Error uploading dataset",
    restErrorDeletingChemicalFilterDescription:
      "<p>Something went wrong while deleting your chemical filter.</p><p>Try deleting a chemical filter again and contact us if the error persists.</p>",
    restErrorDeletingChemicalFilterTitle: "Error deleting chemical filter",
    restErrorDeletingCompoundLibrariesDescription:
      "<p>Something went wrong while deleting your compound library.</p><p>Try deleting a compound library again and contact us if the error persists.</p>",
    restErrorDeletingCompoundLibrariesTitle: "Error deleting compound library",
    restErrorDeletingMemberDescription:
      "<p>Something went wrong while deleting a member.</p><p>Try deleting a member again and contact us if the error persists.</p>",
    restErrorDeletingMemberTitle: "Error deleting member",
    restErrorDeletingOrganizationDescription:
      "<p>Something went wrong while deleting a team.</p><p>Try deleting a team again and contact us if the error persists.</p>",
    restErrorDeletingOrganizationTitle: "Error deleting team",
    restErrorDeletingProjectsDescription:
      "<p>Something went wrong while deleting your project.</p><p>Try deleting a project again and contact us if the error persists.</p>",
    restErrorDeletingProjectsTitle: "Error deleting project",
    restErrorDeletingStrategiesDescription:
      "<p>Something went wrong while deleting your strategy.</p><p>Try deleting a strategy again and contact us if the error persists.</p>",
    restErrorDeletingStrategiesTitle: "Error deleting strategy",
    restErrorDeletingUserDescription:
      "<p>Something went wrong while deleting the user.</p><p>Try deleting the user again and contact us if the error persists.</p>",
    restErrorDeletingUserTitle: "Error deleting user.",
    restErrorEditingCompoundLibraryDescription:
      "<p>Something went wrong while editing a compound library.</p><p>Try editing a compound library again and contact us if the error persists.</p>",
    restErrorEditingCompoundLibraryTitle: "Error editing compound library",
    restErrorEditingMemberDescription:
      "<p>Something went wrong while editing a member.</p><p>Try editing a member again and contact us if the error persists.</p>",
    restErrorEditingMemberTitle: "Error editing member",
    restErrorEditingOrganizationDescription:
      "<p>Something went wrong while editing a team.</p><p>Try editing a team again and contact us if the error persists.</p>",
    restErrorEditingOrganizationTitle: "Error editing team",
    restErrorFetchingPatents: "Error fetching patents",
    restErrorPerformingActionDescription:
      "Something went wrong while performing this action. Try performing this action again and contact us if the error persists.",
    restErrorPerformingActionTitle: "Error performing action",
    restErrorResultRetry: "Retry",
    restErrorRouteFeedbackDescription:
      "<p>Something went wrong while sending your feedback.</p><p>Try sending your feedback again and contact us if the error persists.</p>",
    restErrorRouteFeedbackTitle: "Error while sending feedback",
    restErrorSavingProgressDescription:
      "<p>Something went wrong while saving your progress.</p><p>Try saving progress again and contact us if the error persists.</p>",
    restErrorSavingProgressTitle: "Error saving progress",
    restErrorSavingProjectDescription:
      "<p>Something went wrong while saving the project.</p><p>Try saving the project again and contact us if the error persists.</p>",
    restErrorSavingProjectTitle: "Error saving project",
    restErrorScoringTaskExistsDescription:
      "<p>You are trying to submit an empty list of compounds.</p><p>Or, some of the compounds you are trying to submit already exists in this project.</p><p>Try submitting a different set of compounds and contact us if the error persists.</p>",
    restErrorScoringTaskSubmissionDescription:
      "<p>Something went wrong while submitting your compounds.</p><p>Make sure the compounds were not already submitted and try performing this action again.</p><p>Contact us if the error persists.</p>",
    restErrorSubmittingReviewDescription:
      "<p>Something went wrong while submitting your review.</p><p>Try saving your review again and contact us if the error persists.</p>",
    restErrorSubmittingReviewTitle: "Error submitting review",
    restErrorUploadReportDescription:
      "<p>Something went wrong while uploading the report.</p><p>Try uploading the report again and contact us if the error persists.</p>",
    restErrorUploadReportTitle: "Error uploading report",
    restErrorUserFeedbackDescription:
      "<p>Something went wrong while sending your message.</p><p>Try sending your message again and contact us if the error persists.</p>",
    restErrorUserFeedbackTitle: "Error while sending message",
    restore: "Restore",
    restrict: "Restrict Access",
    restricted: "Restricted",
    restrictedNoMembers: "No one can see this resource",
    resubmit: "Resubmit",
    resubmitDataset: "Resubmit dataset(s)",
    results: "Results",
    resultsNotYetAvailable: "No results are available yet.",
    retrosynthesis: "Retrosynthesis",
    retrosynthesisScenarioDescription:
      "Perform a chemical search which will also identify various routes for synthesis.",
    retry: "Retry",
    returnToMoleculeReview: "Return to platform",
    reuse: "Reuse",
    review: "Review",
    reviewActions: "Review actions",
    reviewComment: "Review comment",
    reviewCommentHelp:
      "A review comment helps to understand the reason behind each review score and improve compounds generated.",
    reviewComments: "Review comments",
    reviewCompounds: "Review compounds",
    reviewers: "Reviewers",
    reviewersCandidatesHelp:
      "Reviewer candidates are administrators and collaborators of your team.",
    reviewersFilter: "Reviewers filter",
    reviewersPlural: "reviewer(s)",
    reviewNavigationHelp_groupActions_controlShift:
      "Use {controlShift} while hovering on table rows (or cards when using the grid view) to select multiple compounds efficiently.",
    reviewNavigationHelp_groupActions_dropdown:
      "Use <span class='ant-typography'><mark>right click</mark></span> on any selected row or the <span class='ant-typography'><mark>Group actions</mark></span> button at the top of the table to see all available group actions.",
    reviewNavigationHelp_groupActions_selectAll:
      "Select or deselect all compounds in the table with {controlA} (to select all) and {controlZ} (to deselect all).",
    reviewNavigationHelp_groupActions_selectMultiple:
      "Select multiple compounds at a time using the checkbox, {doubleClick}, or {controlClick} on any row in the table.",
    reviewNavigationHelp_groupActions_shortcuts:
      "Use {control1}, {control2}, {control3} (deprioritize, neutral, prioritize) to quickly score selected compounds.",
    reviewNavigationHelp_groupActionsTitle: "Group actions",
    reviewNavigationHelp_reviewActions_addComment:
      "Use the <span class='ant-typography'><mark>Add comment</mark></span> button to give additional context related to a compound.",
    reviewNavigationHelp_reviewActions_review:
      "Review any compound using the buttons near the compound structure (prioritize, neutral, deprioritize). You can select multiple compounds at a time for review (see Group actions section).",
    reviewNavigationHelp_reviewActions_saveProgress:
      "Save your review progress using {controlS} or the button {saveReview} at the top of this page.",
    reviewNavigationHelp_reviewActions_submitReview:
      "When you're finished reviewing compounds from this strategy, use the <span class='ant-typography'><mark>Submit review</mark></span> button to mark your review as completed.",
    reviewNavigationHelp_reviewActionsTitle:
      "Review actions (review mode only)",
    reviewNavigationHelp_structureActions_rowActions:
      "Use the actions under the <span class='ant-typography'><mark>Actions</mark></span> column (or at the bottom of each card when using the grid view) to perform actions based on a specific compound.",
    reviewNavigationHelp_structureActions_structure:
      "Use <span class='ant-typography'><mark>right click</mark></span> on any 2D structure to open a contextual menu with available actions.",
    reviewNavigationHelp_structureActions_zoom:
      "Click on a structure in the table to enlarge it and view additional information.",
    reviewNavigationHelp_structureActionsTitle: "Structure actions",
    reviewNavigationHelp_tableActions_filters:
      "Use the sort and filter options on each column of the table when available.",
    reviewNavigationHelp_tableActions_tableTop:
      "Additional actions are available at the top of the table, including fullscreen option, view options, advanced filters, download options, structure alignment, card size, and table columns management.",
    reviewNavigationHelp_tableActionsTitle: "Table & Grid actions",
    reviewResults: "Review compounds",
    reviews: "Reviews",
    reviewScore: "Review score",
    reviewStatus_CO: "Completed",
    reviewStatus_IP: "In progress",
    reviewStatus_NS: "Not yet started",
    reviewStatusDetailed_CO: "Review completed",
    reviewStatusDetailed_IP: "Review in progress",
    reviewStatusDetailed_NS: "Review not yet started",
    reviewStrategy: "Review strategy",
    reviewSubmission: "Review submission",
    reviewSubmitted: "Review submitted successfully.",
    reviewSummary: "Review summary",
    reviewsWithDeletedUser: "Reviews from deleted users",
    reviewType: "Type of review",
    reviewType_multiple: "Multiple reviewers",
    reviewType_team: "Team review",
    reviewTypeDescription_multiple:
      "Each reviewer performs an independent review of the compounds.",
    reviewTypeDescription_team:
      "The team shares a single review of the compounds.",
    revisionsHistory: "Revisions history",
    revisionsNotice: "This file has {nRevisions} revisions.",
    revisionUploadFailed: "Revision upload failed",
    revisionUploadFailedDescription:
      "Something went wrong while uploading the revision. Try again and contact us if the error persists.",
    role: "Role",
    roleAD: "Administrator",
    roleCO: "Collaborator",
    roleDescriptionAD:
      "<strong>Administrators</strong> can perform all possible actions inside of a team, <strong>including</strong> managing team members.",
    roleDescriptionCO:
      "<strong>Collaborators</strong> can perform all possible actions inside of a team, <strong>excluding</strong> managing team members.",
    roleDescriptionDM:
      "<strong>Data managers</strong> can only perform actions related to data management via Drive.",
    roleDescriptionNone: "Select a role to see its description.",
    roleDescriptionST:
      "<strong>Staff</strong> members are Valence Labs's staff assigned to your team.",
    roleDM: "Data manager",
    roleRestrictedPage:
      "You do not have the required role to access this page.",
    roleST: "Staff",
    roleSuper: "Super user",
    route: "Route",
    routeFeedbackDeletedSuccessfully: "Route feedback deleted successfully.",
    routeFeedbacksPlural:
      "{count, plural, =1 {1 route feedback} other {# route feedbacks}}",
    routeFeedbackUpdatedSuccessfully: "Route feedback updated successfully.",
    routeIsSolved: "This route is solved.",
    routeIsUnsolved: "This route is unsolved.",
    routes: "Routes",
    row: "Row",
    save: "Save",
    saved: "Saved",
    saveLimits: "Save limits",
    saveName: "Save name",
    saveProgress: "Save review",
    saveProgressKeyboard: "Save review ({keyboardShortcut})",
    saving: "Saving...",
    scenarioApplied: "{scenarioName} applied.",
    scenarioLaunched: "{scenarioName} launched.",
    schedule: "Schedule",
    score: "Score",
    scoreCompound: "Score compound",
    scoreCompounds: "Score compounds",
    scoredMoleculeslimitExceededDescription:
      "<p>The current submission will exceed the limit of 500 compounds for prediction per review strategy.</p><p>You can reduce the number of compounds you are about to submit, or remove some compounds that were already submitted for this strategy.</p>",
    scoredMoleculeslimitExceededTitle: "Limit exceeded",
    scoreFrom: "Score from",
    scoreNewCompounds: "Score new compounds",
    scoring: "Scoring",
    scoringCompounds: "Scoring compounds...",
    scoringConfigUpdateSuccess: "Scoring configuration updated successfully!",
    scoringConfiguration: "Scoring configuration",
    scoringPageTitle: "Scoring Tasks",
    scoringTaskHistoryDescription:
      "Track and access results of scoring jobs performed by your team.",
    scoringTaskHistoryTitle: "Scoring Tasks",
    search: "Search",
    searchActivities: "Search activities...",
    searchById: "Search by ID",
    searchByName: "Search by name",
    searchBySmiles: "Search by exact SMILES",
    searchByType: "Search by type",
    searchByUser: "Search by user",
    searchChemicalFilters: "Search chemical filters",
    searchChemicalSpace: "Search chemical space",
    searchChemicalSpaceMode_editor: "Editor",
    searchChemicalSpaceMode_text: "Text",
    searchChemicalSpaceModeHelp_editor:
      "For sub-structure searches, draw up to ten structures in the editor. For exact and similarity searches, draw a single structure.",
    searchChemicalSpaceModeHelp_text:
      "For sub-structure searches, enter up to ten SMARTS or SMILES in the text area. For exact and similarity searches, enter a single structure.",
    searchChemicalType_exact: "Exact",
    searchChemicalType_exact_search: "Exact Search",
    searchChemicalType_similarity: "Similarity",
    searchChemicalType_similarity_search: "Similarity Search",
    searchChemicalType_substruct: "Sub-structure",
    searchChemicalType_substruct_search: "Sub-structure Search",
    searchChemicalTypeHelp_exact:
      "Search for compounds from the current strategy by exact structures. The OR operator will be applied if multiple structures are provided.",
    searchChemicalTypeHelp_similarity:
      "Search for compounds from this strategy by similarity. Use a single structure.",
    searchChemicalTypeHelp_substruct:
      "Search for compounds from this strategy with specific sub-structures. The AND operator will be applied if multiple sub-structures are provided.",
    searchConfiguration: "Configuration",
    searchError: "Search Error",
    searchExactOrSub: "Search exact or substructures...",
    searchFilesAndFolders: "Search files and folders",
    searchFilters: "Search Filters",
    searchFrom: "Search from",
    searchHistory: "Search History",
    searchInvalid: "This search was invalid.",
    searchOrganizationMolecules: "Search by ID, Inchi Key, SMARTS or SMILES",
    searchResults: "Search results",
    searchResultsAppearHere: "Search results will appear here when available.",
    searchStatus: "Search Status",
    searchSuccess: "Search Completed!",
    seeAllCatalogs: "See all catalogs",
    seeAllFeedbacks: "See all feedbacks",
    seeCompoundDetails: "See compound details",
    seeGroup: "See Group",
    seeLogs: "See logs",
    seeMyReview: "See my review",
    seeReference: "See Reference",
    seeUnreviewedCompounds: "See unreviewed compounds",
    select: "Select",
    selectAlert: "Select an alert",
    selectAll: "Select All",
    selectAType: "Select a type",
    selectCDDVaultState: "Select a CDD Vault status",
    selectDescriptor: "Select a descriptor",
    selectDescriptors: "Select descriptors",
    selectedStructure: "Selected structure",
    selectFile: "Select file",
    selectFileForScoring:
      "Submit a file of size under {maxSize} and containing a maximum of {maxCompounds} compounds.",
    selectFileFromDrive: "Select a file from Drive Space",
    selectFilter: "Select a filter",
    selectFolder: "Select folder",
    selectFolderFromDrive: "Select a folder from Drive Space",
    selectFunctionalGroup: "Select a functional group",
    selectionConfig: "Selection configuration",
    selectModelsForScoring:
      "Select the relevant machine learning models for your scoring job (optional)",
    selectOption: "Select an option",
    selectProjectForScoring: "Select a project for your scoring job.",
    selectRelatedProject: "Select a related project.",
    selectRole: "Select a role",
    selectRule: "Select a rule",
    selectStrategyType: "Select a type of strategy.",
    selectStructure: "Select a structure",
    selectTeamMembersOrGroups: "Select team members or groups",
    selectThresold: "Select a thresold",
    sendCode: "Send code",
    sendReportToPartners: "Send report to partners",
    services: "Services",
    servicesPageDescription:
      "Manage services users and organizations can have access to.",
    servicesPageTitle: "Services",
    session: "Session",
    setAsCurrent: "Set as current",
    settings: "Settings",
    shareFile: "Share",
    shareFileURLCopied: "Share URL copied to your clipboard",
    showingCompounds:
      "The table shows {displayedCount} out of {allCount} compounds based on the filters that are currently applied.",
    shown: "Shown",
    showSuggested: "Show suggestions",
    signIn: "Sign in",
    signInTagLine: "Find high-quality compoundLibraries, fast.",
    signOut: "Sign out",
    signUp: "Sign up",
    signupComplete: "Sign up completed! You can now sign in!",
    signupCompleteWelcome: "Sign up completed! Welcome to {appName}!",
    signupSuccessful: "Sign Up Successful!",
    signUpToCircus: "Sign Up to Circus",
    similarity: "Similarity",
    similarityScore: "Similarity score",
    similarityScoreDescription: "Specify a similarity score range.",
    similaritySearch: "Similarity search",
    similaritySearchResultsForAllMolecules:
      "Similarity results for all molecules",
    similaritySearchResultsForCurrentMolecule:
      "Similarity results for current molecule",
    similaritySearchThreshold: "Similarity threshold",
    similaritySearchThresholdDescription:
      "Threshold used for similarity search.",
    size: "Size",
    small: "Small",
    SMARTS: "SMARTS",
    smartsSearch: "SMARTS search",
    smartsSearchScenarioDescription:
      "Search for simple or complex substructural patterns using the SMARTS syntax.",
    smiles: "SMILES",
    SMILES: "SMILES",
    smilesColumn: "SMILES column",
    smilesCopied: "SMILES copied",
    smilesList: "SMILES list",
    solved: "Solved",
    solvedRouteDescription:
      "A route is solved when all the leaf reactants are commercially available.",
    someNegativeScore: "Some negative",
    someNeutralScore: "Some neutral",
    somePositiveScore: "Some positive",
    someReviewsAreFiltered: "Some reviews are currently filtered.",
    someStructuresInvalid: "Some structures are invalid.",
    somethingWentWrong: "Something went wrong.",
    somethingWentWrongContactUs:
      "Something went wrong. Retry and contact us if the error persists.",
    someUnreviewedScore: "Some unreviewed",
    sort: "Sort",
    sortAscending: "Sort ascending",
    sortBy: "Sort by",
    sortDescending: "Sort descending",
    "sortType_alerts.length": "# Alerts",
    "sortType_descriptors.crippen_clog_p": "cLogP",
    "sortType_descriptors.fraction_csp3": "Fsp3",
    "sortType_descriptors.molecular_weight": "Molecular Weight",
    "sortType_descriptors.num_hba": "# HBA",
    "sortType_descriptors.num_hbd": "# HBD",
    "sortType_descriptors.num_rings": "# Rings",
    "sortType_descriptors.qed": "QED",
    sortType_is_commercially_available: "Commercially available",
    sortType_score: "Score",
    source: "Source",
    startDate: "Start date",
    startEnterEmailAddress: "Start by entering your email address below.",
    startReview: "Start review",
    startSearch: "Search",
    statementType: "Statement type",
    statementType_AL: "Alert",
    statementType_CH: "Chemical Filter",
    statementType_DE: "Descriptor",
    statementType_GR: "Functional group",
    statementType_RU: "Rule",
    statementType_ST: "Substructure",
    statementType_SU: "Superstructure",
    status: "Status",
    statusPage: "All Systems Operational",
    steps: "Steps",
    strategies: "Strategies",
    strategiesCount: "Strategies count",
    strategiesPageDescription:
      "Manage your strategies and receive detailed analysis results.",
    strategiesPageTitle: "Strategies",
    strategiesRemovedSuccessfully:
      "<strong>{title}</strong> was removed successfully.",
    strategiestateAP: "Analysis in progress",
    strategiestateER: "In error",
    strategiestateRE: "Review completed",
    strategiestateRS: "Open for review",
    strategy: "Strategy",
    strategyAddedSuccessfully:
      "<strong>{title}</strong> was added successfully.",
    strategyalData: "Assay results",
    strategyAlreadyExists: "An strategy with this title already exists.",
    strategyData: "Strategy data",
    strategyDetails: "Strategy details",
    strategyHasBeenReviewed:
      "This review or strategy review period is completed. You can no longer make changes.",
    strategyModelVersionsUpdatedSuccessfully:
      "Strategy's model versions updated successfully.",
    strategyOneMemberInOrg: "There is only one member in this team.",
    strategyOneMemberInviteMember: "Invite members",
    strategyReviewDescription: "Review and prioritize compounds.",
    strategyReviewTitle: "Strategy review",
    strategyRoles: "Strategy roles",
    strategyType: "Type",
    strategyTypeGN: "Generative design",
    strategyTypeOP: "Optimization",
    strategyTypeSC: "Virtual screening",
    structural: "Structural",
    structure: "Structure",
    structureAdded: "Structures added successfully.",
    structureAlignment: "Structure alignment",
    structureAlreadyExist: "Structure already added.",
    structureForFilteringWillAppear:
      "Structure used for filtering will appear here.",
    structureInformation: "Structure information",
    structureMissing: "Structure is missing",
    structurePlural: "{count, plural, =1 {1 structure} other {# structures}}",
    structureRemoved: "Structure removed successfully.",
    structures: "Structures",
    structuresForSearchWillAppear:
      "Structures used for search will appear here.",
    structureSimilarity: "Structure similarity",
    structuresList: "List of structures",
    structureSortInfo:
      "Sorting on this column will order molecules by structural similarity",
    submit: "Submit",
    submitComment: "Submit comment",
    submitCompoundForPrediction: "Submit compounds for scoring",
    submitCompoundForSuggestion: "Submit compound as suggestion",
    submitCompoundsForPrediction: "Submit compounds for scoring",
    submitCompoundsForSuggestion: "Submit compounds as suggestions",
    submitDataset: "Submit dataset(s)",
    submitFile: "Submit file",
    submitForPrediction: "Submit for prediction",
    submitForSuggestion: "Submit suggestion",
    submitForVisualization: "Submit for visualization",
    submitNewCompounds: "Submit new compounds",
    submitNewFile: "Submit another file",
    submitNewSetOfCompounds: "Submit additional compounds",
    submitReview: "Submit review",
    submitReviewAnyway: "Submit anyway",
    submittingCompounds: "Submitting compounds...",
    substructureSearch: "Substructure search",
    substructureSearchResultsForAllMolecules:
      "Substructure results for all molecules",
    substructureSearchResultsForCurrentMolecule:
      "Substructure results for current molecule",
    suggest: "Suggest",
    suggestCompound: "Suggest compound",
    suggestCompounds: "Suggest compounds",
    suggested: "Suggested",
    suggestedBy: "Suggested by {user}",
    suggestedMoleculeFileSupport:
      "File with .csv or .xlsx format should contain a column named <strong>SMILES</strong>, <strong>smiles</strong>, or <strong>Smiles</strong>.",
    suggestFrom: "Suggest from",
    suggestionCommentHelp:
      "Please leave a comment to help your team understand your suggestion. (optional)",
    suggestionRemoved: "Suggestion removed!",
    suggestionsSaved: "Suggestions saved successfully.",
    suggestNewCompounds: "Suggest new compounds",
    superUser: "Super",
    superUserStaff: "Staff",
    superUserSuper: "Super",
    supportsSingleFileOrBulk: "Supports single file or bulk upload.",
    swagger: "Swagger",
    switch: "Switch",
    switchOrganization: "Switch team",
    switchStructure: "Switch structure",
    switchTo: "Switch to",
    switchToAdmin: "Switch to admin",
    switchToOrganization: "Switch to this team",
    switchToStaff: "Switch to staff",
    syncCDDVault: "Sync CDD Vault",
    syncCDDVaultDisabledDescription:
      "You can only sync your CDD Vault once per hour.",
    synthesis: "Synthesis",
    synthesisRoutes: "Synthesis routes",
    tableView: "Table view",
    tagName: "Tag",
    tags: "Tags",
    tanimotoThreshold: "Tanimoto threshold",
    tasks: "Tasks",
    taskStateCR: "Created",
    taskStateER: "Error",
    taskStateFA: "Failed",
    taskStateIN: "Created",
    taskStateMI: "Missing",
    taskStatePD: "Queued",
    taskStateRU: "Running",
    taskStateSB: "Submitted",
    taskStateSU: "Succeeded",
    taskStateUnknown: "Unknown",
    taskTypeLabel_dataprocess: "Data processing",
    taskTypeLabel_descriptors: "Descriptors",
    taskTypeLabel_distributedscoring: "Scoring (internal)",
    taskTypeLabel_featurize: "Featurization",
    taskTypeLabel_modelanalysis: "Model analysis",
    taskTypeLabel_modelselection: "Model selection",
    taskTypeLabel_scoring: "Scoring",
    teamDrive: "Team drive",
    teamName: "Team name",
    teamReview: "Team review",
    templateAlreadyExists: "A template with this name already exists.",
    templateData: "Template content",
    templateRemovedSuccessfully: "Template was removed successfully.",
    templates: "Templates",
    termsOfService: "Terms of service",
    text: "Text",
    thankYou: "Thank you!",
    thankYouForFeedback: "Thank you for your message!",
    thankYouForYourRouteFeedback: "Thank you for your feedback!",
    title: "Title",
    toggleLegend:
      "Click on a legend item to hide or display a specific set of compounds.",
    toggleOrganizations: "Toggle teams",
    toggleServices: "Toggle services",
    toggleUsers: "Enter an email address and press enter to add a user.",
    tooManyValues: "Too many values are specified",
    totalCompounds: "Total compounds",
    totalCompoundsSubmitted: "Total compounds submitted",
    totalCompoundsSuggested:
      "Number of compounds suggested for current project",
    totalLibraries: "Total libraries",
    totalRunCount: "Run count",
    trainingMolecules: "Provided compounds",
    trainingMoleculesCollapse: "Training compounds (always optional)",
    transformerData: "Transformer data",
    transformerInitialValue: "# Use a template or add data below",
    trash: "Trash",
    trashed: "Trashed",
    turnOff: "Turn off",
    turnOn: "Turn on",
    type: "Type",
    typeSomethingAndPressEnter:
      "Type something and press enter (ex. transformers, featurization, etc.)",
    unableToDownloadSearchResults:
      "Unable to download search results. Please try again.",
    unableToFindStructure: "This structure was not found in any catalog.",
    unableToReadFileFormat: "Unable to read file format",
    unauthorizedPageAction: "Return to previous page",
    unauthorizedPageTitle: "Sorry, you are not authorized to access this page.",
    unavailable: "Unavailable",
    undo: "Undo",
    units: "Units",
    unrestricted: "Unrestricted",
    unsavedChanges: "You have unsaved changes, are you sure you want to leave?",
    unset: "Unset",
    unsolved: "Unsolved",
    update: "Update",
    updatedBy: "Updated by",
    updateReadouts: "Update compounds information",
    upload: "Upload",
    uploadDataToStart: "Upload data to get started",
    uploadFiles: "Upload Files",
    uploadingRevision: "Uploading revision...",
    uploadingSubmission: "Uploading submission",
    uploadingSubmissionDescription:
      "This may take a few seconds as we prepare your environment to review compounds",
    uploadInstructions:
      "Click or drag file(s) to this area to launch information extraction.",
    uploadInstructionsSubtitle: "Only pdf documents are supported.",
    uploadResults: "Upload results",
    uploadRevision: "Upload revision",
    upperLimit: "Upper limit",
    useAsTableFilter: "Use as table filter",
    user: "User",
    userAddedSuccessfully: "<strong>{email}</strong> was added to your team.",
    userAlreadyInOrganization: "This user is already a team member.",
    userEditedSuccessfully: "<strong>{email}</strong> was edited successfully.",
    userHasNoOrganization: "User {email} is not associated to any team.",
    userInformation: "User information",
    userPwdReset: "Reset password",
    userRemovedSuccessfully:
      "<strong>{email}</strong> was removed successfully.",
    users: "Users",
    userSince: "User since",
    usersPageDescription: "View all users registered on the platform.",
    usersPageTitle: "Users",
    useTemplate: "Use a template",
    UUID: "UUID",
    validatingCompounds: "Validating compounds...",
    validPasswordLengthConstraint: "Between 8 and 25 characters",
    verified: "Verified",
    verifiedTooltip:
      "The 'verified' mark guarantees that a file is attached to the revision",
    version: "Version",
    versions: "Versions",
    view: "View",
    viewAll: "View all",
    viewAllChemicalSearches: "View all chemical searches",
    viewAllLibraries: "View all libraries",
    viewAllMembers: "View all members",
    viewAllOrganizations: "View all teams",
    viewAllProjects: "View all projects",
    viewAllStrategies: "View all strategies",
    viewBlocks: "Explore blocks",
    viewCatalogs: "View catalogs",
    viewComment: "View comment",
    viewDetails: "View details",
    viewerDescription: "Viewers can view the resource.",
    viewerLabel: "Viewer",
    viewExamples: "View examples",
    viewInCDD: "View in CDD",
    viewProject: "View",
    viewProjectPageDescription: "Manage your project information.",
    viewProjectPageTitle: "View project",
    viewProjectSubmissions: "View project submissions",
    viewReport: "View report",
    viewStrategy: "View strategy",
    viewStrategyDescription: "Review the details of your strategy.",
    viewTableFullScreen: "View table in full screen",
    visible: "Visible",
    visibleColumns: "Visible columns",
    visibleToAll: "This resource is visible to all team members",
    visualization: "Visualization",
    visualizeChemicalSpace: "Visualize chemical space",
    visualizeChemicalSpaceNA: "Visualize chemical space (not available)",
    visualizedReadout: "Visualized readout",
    wantMoreResults: "Want more results?",
    warning: "Warning",
    warningMoleculeScoring:
      "This job normally takes under a minute to finish, but it may take significantly longer if machines are not readily available or a large number of compounds was submitted. You can always consult the results later.",
    warningRemovingReviewers:
      "Removing reviewers will delete their associated reviews forever.",
    warningResubmit:
      "Molecules have already been submitted. If you resubmit a new file, old compounds will be deleted and replaced by those contained in the new file.",
    warningResubmitGenerated:
      "Molecules have already been submitted.{lineBreak}{lineBreak}<strong>If you submit a new file for resubmission</strong>, old compounds will be replaced by those contained in the new file and the review score for each molecule will reset to neutral. The strategy status will also reset to <i>Ready for review</i>.{lineBreak}{lineBreak}<strong>If you submit a new file for visualization</strong>, only the columns <i>id</i>, <i>embedding_x</i>. <i>embedding_y</i>, and <i>embedding_z</i> will be sent to the server to update molecule information related to chemical space visualization. Make sure you also provide training compounds containing embedding information of the same dimensions (two-dimensional vs three-dimensional).",
    warningUnreviewedCompounds:
      "You currently have {unreviewedCount} unreviewed compounds.\n\nMake sure to review all compounds before submitting your review.",
    warningUpload:
      "Leave this window open until all files have finished uploading. Otherwise, these files will not be accessible by members of your team.",
    weekDays: "{count, plural, =1 {1 weekday} other {# weekdays}}",
    weeks: "{count, plural, =1 {1 week} other {# weeks}}",
    welcomeName: "Welcome, {full_name}",
    welcomeUser: "Welcome, {name}!",
    willGetBackToYouShortly:
      "Your message was received. We will get back to you shortly.",
    workflowDesign: "Workflows",
    workspace: "Workspace",
    workspaceSessionLoading: "Workspace session is loading...",
    xlsxFileSupport: "Reminder: The file should be in a .xlsx format.",
    yes: "Yes",
    youAreAboutToCreateYourStrategy: "You are about to create a strategy.",
    youAreAboutToCreateYourStrategyDescription:
      "<p>Please note that you will not be able to edit some information after your strategy has been created.</p><p>You can review your strategy to make sure that all information is accurate.</p><p>Click on <strong>Create strategy</strong> to complete the strategy creation process when you are ready.</p>",
    yourOrganizations: "Your teams",
    zero: "Neutral"
  }
};
