module.exports = {
  SMARTS: "SMARTS",
  SMILES: "SMILES",
  UUID: "UUID",
  aboutToChangeStrategiestate: "aboutToChangeStrategiestate",
  accessRestriction: "accessRestriction",
  accessRestrictionTooltip: "accessRestrictionTooltip",
  actionInProgress: "actionInProgress",
  actionSuccessful: "actionSuccessful",
  actions: "actions",
  activateDistributedMode: "activateDistributedMode",
  activeMembers: "activeMembers",
  activeMembersDescription: "activeMembersDescription",
  activities: "activities",
  activitiesPageDescription: "activitiesPageDescription",
  activitiesPageTitle: "activitiesPageTitle",
  add3DScatterPlot: "add3DScatterPlot",
  addChemicalFilter: "addChemicalFilter",
  addChemicalFilterPageDescription: "addChemicalFilterPageDescription",
  addChemicalFilterPageTitle: "addChemicalFilterPageTitle",
  addChemicalFilterStatement: "addChemicalFilterStatement",
  addChemicalSearch: "addChemicalSearch",
  addChemicalSearch2: "addChemicalSearch2",
  addComment: "addComment",
  addCompoundLibrary: "addCompoundLibrary",
  addCompoundLibraryPageDescription: "addCompoundLibraryPageDescription",
  addCompoundLibraryPageTitle: "addCompoundLibraryPageTitle",
  addDescriptorsTask: "addDescriptorsTask",
  addDescriptorsTaskDescription: "addDescriptorsTaskDescription",
  addDistributedScoringTask: "addDistributedScoringTask",
  addDistributedScoringTaskDescription: "addDistributedScoringTaskDescription",
  addFeatureFlag: "addFeatureFlag",
  addFeatureFlagOrg: "addFeatureFlagOrg",
  addFeatureFlagPageDescription: "addFeatureFlagPageDescription",
  addFeatureFlagPageTitle: "addFeatureFlagPageTitle",
  addFeatureFlagUser: "addFeatureFlagUser",
  addFeaturizeTask: "addFeaturizeTask",
  addFeaturizeTaskDescription: "addFeaturizeTaskDescription",
  addFilter: "addFilter",
  addFlag: "addFlag",
  addForSubmission: "addForSubmission",
  addGenericTemplateDescription: "addGenericTemplateDescription",
  addGenericTemplateTitle: "addGenericTemplateTitle",
  addGroup: "addGroup",
  addGroupPageDescription: "addGroupPageDescription",
  addGroupPageTitle: "addGroupPageTitle",
  addHistogram: "addHistogram",
  addMember: "addMember",
  addMemberPageDescription: "addMemberPageDescription",
  addMemberPageTitle: "addMemberPageTitle",
  addModelSelectionTask: "addModelSelectionTask",
  addModelSelectionTaskDescription: "addModelSelectionTaskDescription",
  addMoleculeGrid: "addMoleculeGrid",
  addNewChemicalSearch: "addNewChemicalSearch",
  addNewMember: "addNewMember",
  addNewProject: "addNewProject",
  addNewStrategy: "addNewStrategy",
  addOrganization: "addOrganization",
  addOrganizationPageDescription: "addOrganizationPageDescription",
  addOrganizationPageTitle: "addOrganizationPageTitle",
  addProject: "addProject",
  addProjectOnly: "addProjectOnly",
  addProjectPageDescription: "addProjectPageDescription",
  addProjectPageTitle: "addProjectPageTitle",
  addProperty: "addProperty",
  addRadarPlot: "addRadarPlot",
  addScatterPlot: "addScatterPlot",
  addStrategiestep1Title: "addStrategiestep1Title",
  addStrategiestep2Title: "addStrategiestep2Title",
  addStrategiestep3Title: "addStrategiestep3Title",
  addStrategy: "addStrategy",
  addStrategyCompoundPropertiesIntro: "addStrategyCompoundPropertiesIntro",
  addStrategyDatasetInfo: "addStrategyDatasetInfo",
  addStrategyDatasetIntro: "addStrategyDatasetIntro",
  addStrategyDetailsIntro: "addStrategyDetailsIntro",
  addStrategyPageDescription: "addStrategyPageDescription",
  addStrategyPageTitle: "addStrategyPageTitle",
  addStrategyToProject: "addStrategyToProject",
  addSuperUser: "addSuperUser",
  addSuperUserDescription: "addSuperUserDescription",
  addSuperUserTitle: "addSuperUserTitle",
  addTemplate: "addTemplate",
  addToSearch: "addToSearch",
  additionalCatalogs: "additionalCatalogs",
  additionalDetails: "additionalDetails",
  additionalExamplesProperties: "additionalExamplesProperties",
  additionalInfo: "additionalInfo",
  additionalInformation: "additionalInformation",
  adminDescription: "adminDescription",
  adminLabel: "adminLabel",
  administration: "administration",
  alerts: "alerts",
  alignFromApply: "alignFromApply",
  alignFromEditor: "alignFromEditor",
  all: "all",
  allCatalogs: "allCatalogs",
  allCatalogsScenarioDescription: "allCatalogsScenarioDescription",
  allCompoundsLoaded: "allCompoundsLoaded",
  allMolecules: "allMolecules",
  allPrices: "allPrices",
  allStructuresExist: "allStructuresExist",
  allTasks: "allTasks",
  allTasksPageDescription: "allTasksPageDescription",
  allTasksPageTitle: "allTasksPageTitle",
  alreadyHaveCode: "alreadyHaveCode",
  alreadySignedUp: "alreadySignedUp",
  any: "any",
  applicationLoadFailed: "applicationLoadFailed",
  appliedChemicalSearch: "appliedChemicalSearch",
  appliedFilters: "appliedFilters",
  apply: "apply",
  applyFilters: "applyFilters",
  areYouSure: "areYouSure",
  areYouSureToDeleteForever: "areYouSureToDeleteForever",
  areYouSureToEmptyTrash: "areYouSureToEmptyTrash",
  areYouSureToRemoveChemicalFilter: "areYouSureToRemoveChemicalFilter",
  areYouSureToRemoveCompoundLibraries: "areYouSureToRemoveCompoundLibraries",
  areYouSureToRemoveFeatureFlag: "areYouSureToRemoveFeatureFlag",
  areYouSureToRemoveGroup: "areYouSureToRemoveGroup",
  areYouSureToRemoveMember: "areYouSureToRemoveMember",
  areYouSureToRemoveOrganization: "areYouSureToRemoveOrganization",
  areYouSureToRemoveProjects: "areYouSureToRemoveProjects",
  areYouSureToRemoveStrategies: "areYouSureToRemoveStrategies",
  areYouSureToReset: "areYouSureToReset",
  areYouSureUpdateReport: "areYouSureUpdateReport",
  areYouSureUploadReport: "areYouSureUploadReport",
  assayReadouts: "assayReadouts",
  assayResults: "assayResults",
  assays: "assays",
  assignChemicalFilters: "assignChemicalFilters",
  assignModelVersions: "assignModelVersions",
  assignModels: "assignModels",
  assignedChemicalFilters: "assignedChemicalFilters",
  assignedModelVersions: "assignedModelVersions",
  assignedModels: "assignedModels",
  autoSavingChanges: "autoSavingChanges",
  availability: "availability",
  back: "back",
  backToChemicalFilters: "backToChemicalFilters",
  backToGroups: "backToGroups",
  backToProjects: "backToProjects",
  backToSignIn: "backToSignIn",
  backToStrategies: "backToStrategies",
  backToTop: "backToTop",
  batchSize: "batchSize",
  betaNotice: "betaNotice",
  betaNoticeShort: "betaNoticeShort",
  blindModeActivated: "blindModeActivated",
  blindModeDeactivated: "blindModeDeactivated",
  blindReview: "blindReview",
  blindReviewDescription: "blindReviewDescription",
  buildingBlocks: "buildingBlocks",
  cancel: "cancel",
  cannotAccessThisPage: "cannotAccessThisPage",
  cannotAccessThisResource: "cannotAccessThisResource",
  cannotAddTwoPropertyWithSameName: "cannotAddTwoPropertyWithSameName",
  cannotEditRestrictions: "cannotEditRestrictions",
  cardsView: "cardsView",
  catalogLabel_chebi: "catalogLabel_chebi",
  catalogLabel_chembl: "catalogLabel_chembl",
  catalogLabel_chemspace: "catalogLabel_chemspace",
  catalogLabel_drugbank: "catalogLabel_drugbank",
  catalogLabel_emolecules: "catalogLabel_emolecules",
  catalogLabel_labnetwork: "catalogLabel_labnetwork",
  catalogLabel_mcule: "catalogLabel_mcule",
  catalogLabel_molport: "catalogLabel_molport",
  catalogLabel_pubchem: "catalogLabel_pubchem",
  catalogLabel_real: "catalogLabel_real",
  "catalogLabel_real-bb": "catalogLabel_real-bb",
  "catalogLabel_real-diverse-druglike": "catalogLabel_real-diverse-druglike",
  catalogLabel_surechembl: "catalogLabel_surechembl",
  catalogLabel_zinc: "catalogLabel_zinc",
  catalogs: "catalogs",
  catalogsDescription: "catalogsDescription",
  cddConfigurationForm: {
    apiKey: "cddConfigurationForm.apiKey",
    apiKeyPlaceholder: "cddConfigurationForm.apiKeyPlaceholder",
    apiKeyTooltip: "cddConfigurationForm.apiKeyTooltip",
    cddVaultIntegration: "cddConfigurationForm.cddVaultIntegration",
    configuredBy: "cddConfigurationForm.configuredBy",
    lastConfigured: "cddConfigurationForm.lastConfigured",
    valueLikelyInvalid: "cddConfigurationForm.valueLikelyInvalid",
    vaultID: "cddConfigurationForm.vaultID",
    vaultIDPlaceholder: "cddConfigurationForm.vaultIDPlaceholder",
    vaultIDTooltip: "cddConfigurationForm.vaultIDTooltip"
  },
  cddFilterInVaultWithData: "cddFilterInVaultWithData",
  cddFilterInVaultWithoutData: "cddFilterInVaultWithoutData",
  cddFilterNotInVault: "cddFilterNotInVault",
  cddID: "cddID",
  cddVault: "cddVault",
  cddVaultAvailableData: "cddVaultAvailableData",
  cddVaultInformation: "cddVaultInformation",
  cddvaultSyncState_ER: "cddvaultSyncState_ER",
  cddvaultSyncState_NS: "cddvaultSyncState_NS",
  cddvaultSyncState_QE: "cddvaultSyncState_QE",
  cddvaultSyncState_SC: "cddvaultSyncState_SC",
  cddvaultSyncState_SP: "cddvaultSyncState_SP",
  cddvaultSyncState_undefined: "cddvaultSyncState_undefined",
  changeChemicalSearchParameters: "changeChemicalSearchParameters",
  changeStrategiestate: "changeStrategiestate",
  changeYourProfileImage: "changeYourProfileImage",
  changesWereSavedSuccessfully: "changesWereSavedSuccessfully",
  checkInboxForCode: "checkInboxForCode",
  chemicalFilter: "chemicalFilter",
  chemicalFilterAlreadyExists: "chemicalFilterAlreadyExists",
  chemicalFilterDeletedSuccessfully: "chemicalFilterDeletedSuccessfully",
  chemicalFilterOperator: "chemicalFilterOperator",
  chemicalFilterStatements: "chemicalFilterStatements",
  chemicalFilterStatementsLogic: "chemicalFilterStatementsLogic",
  chemicalFilterStructure: "chemicalFilterStructure",
  chemicalFilters: "chemicalFilters",
  chemicalFiltersAssignmentTitle: "chemicalFiltersAssignmentTitle",
  chemicalFiltersPageDescription: "chemicalFiltersPageDescription",
  chemicalFiltersPageTitle: "chemicalFiltersPageTitle",
  chemicalFiltersUpdatedSuccessfully: "chemicalFiltersUpdatedSuccessfully",
  chemicalSearch: "chemicalSearch",
  chemicalSearchAromatizeNotice: "chemicalSearchAromatizeNotice",
  chemicalSearchHistoryDescription: "chemicalSearchHistoryDescription",
  chemicalSearchHistoryTitle: "chemicalSearchHistoryTitle",
  chemicalSearchOperator: "chemicalSearchOperator",
  chemicalSearchOperatorContext_AND: "chemicalSearchOperatorContext_AND",
  chemicalSearchOperatorContext_OR: "chemicalSearchOperatorContext_OR",
  chemicalSearchRoutes: "chemicalSearchRoutes",
  chemicalSearchStateCO: "chemicalSearchStateCO",
  chemicalSearchStateER: "chemicalSearchStateER",
  chemicalSearchStateIN: "chemicalSearchStateIN",
  chemicalSearchThresholdUsed: "chemicalSearchThresholdUsed",
  chemicalSearches: "chemicalSearches",
  chemicalSearchesPageTitle: "chemicalSearchesPageTitle",
  circusLabel: "circusLabel",
  clear: "clear",
  click: "click",
  clickHere: "clickHere",
  clickOrDragDatasetToSubmit: "clickOrDragDatasetToSubmit",
  clickOrDragFileToEditCompoundLibrary: "clickOrDragFileToEditCompoundLibrary",
  clickOrDragFileToUpload: "clickOrDragFileToUpload",
  clickOrDragMoleculesFile: "clickOrDragMoleculesFile",
  clickOrDragSuggestedMoleculeFile: "clickOrDragSuggestedMoleculeFile",
  clickOrDragToUpload: "clickOrDragToUpload",
  clickOrDragTrainingMoleculesFile: "clickOrDragTrainingMoleculesFile",
  clickOrLeftArrow: "clickOrLeftArrow",
  clickOrRightArrow: "clickOrRightArrow",
  clickToCopy: "clickToCopy",
  clickToEnlarge: "clickToEnlarge",
  clickToOpenDrive: "clickToOpenDrive",
  clickToSelect: "clickToSelect",
  clickToSwitchOrg: "clickToSwitchOrg",
  clickToUpload: "clickToUpload",
  close: "close",
  closePreview: "closePreview",
  clustersPlural: "clustersPlural",
  code: "code",
  codeSentSuccessfully: "codeSentSuccessfully",
  collaborators: "collaborators",
  collapse: "collapse",
  comeBackLater: "comeBackLater",
  comment: "comment",
  commentAndSubmit: "commentAndSubmit",
  commentPotentialPlural: "commentPotentialPlural",
  commerciallyAvailableOnly: "commerciallyAvailableOnly",
  commerciallyAvailableOnlyCheckedDescription:
    "commerciallyAvailableOnlyCheckedDescription",
  commerciallyAvailableOnlyDescription: "commerciallyAvailableOnlyDescription",
  commerciallyAvailableOnlyNotCheckedDescription:
    "commerciallyAvailableOnlyNotCheckedDescription",
  completeProfileBadge: "completeProfileBadge",
  completeProfileInformation: "completeProfileInformation",
  completeSignHeader: "completeSignHeader",
  completeSignInButton: "completeSignInButton",
  completeSignInDescription: "completeSignInDescription",
  compoundForSubmission: "compoundForSubmission",
  compoundId: "compoundId",
  compoundIdCopied: "compoundIdCopied",
  compoundIdCopy: "compoundIdCopy",
  compoundLibraries: "compoundLibraries",
  compoundLibrariesPageDescription: "compoundLibrariesPageDescription",
  compoundLibrariesPageTitle: "compoundLibrariesPageTitle",
  compoundLibrariesRemovedSuccessfully: "compoundLibrariesRemovedSuccessfully",
  compoundLibrary: "compoundLibrary",
  compoundLibraryAddedSuccessfully: "compoundLibraryAddedSuccessfully",
  compoundLibraryAlreadyExists: "compoundLibraryAlreadyExists",
  compoundLibraryCurrentFile: "compoundLibraryCurrentFile",
  compoundLibraryEditedSuccessfully: "compoundLibraryEditedSuccessfully",
  compoundLibraryFileWillBeReplaced: "compoundLibraryFileWillBeReplaced",
  compoundModalTitle: "compoundModalTitle",
  compoundProperties: "compoundProperties",
  compoundSuggestedSuccessfully: "compoundSuggestedSuccessfully",
  compoundsAlreadySubmittedForPrediction:
    "compoundsAlreadySubmittedForPrediction",
  compoundsAlreadySubmittedForPredictionStrategy:
    "compoundsAlreadySubmittedForPredictionStrategy",
  compoundsCount: "compoundsCount",
  compoundsForSubmission: "compoundsForSubmission",
  compoundsForSubmissionList: "compoundsForSubmissionList",
  compoundsFound: "compoundsFound",
  compoundsMaxDisplayed: "compoundsMaxDisplayed",
  compoundsPreviouslySubmitted: "compoundsPreviouslySubmitted",
  compoundsPreviouslySubmittedCurrent: "compoundsPreviouslySubmittedCurrent",
  compoundsUpdated: "compoundsUpdated",
  computationalTasks: "computationalTasks",
  configuration: "configuration",
  confirm: "confirm",
  confirmPassword: "confirmPassword",
  confirmYourEmailAddress: "confirmYourEmailAddress",
  confirmYourEmailAddressDescription: "confirmYourEmailAddressDescription",
  confirmationCode: "confirmationCode",
  connectingToPlatform: "connectingToPlatform",
  consultChemicalSearchResults: "consultChemicalSearchResults",
  consultReview: "consultReview",
  contactUs: "contactUs",
  containerImageName: "containerImageName",
  containerImageTag: "containerImageTag",
  continue: "continue",
  continueReview: "continueReview",
  copied: "copied",
  copyCXSmiles: "copyCXSmiles",
  copyImageToClipboard: "copyImageToClipboard",
  copyInchi: "copyInchi",
  copyInchikey: "copyInchikey",
  copyListToClipboard: "copyListToClipboard",
  copyMolBlock: "copyMolBlock",
  copySmarts: "copySmarts",
  copySmiles: "copySmiles",
  copyToClipboard: "copyToClipboard",
  copyright: "copyright",
  createChemicalFilter: "createChemicalFilter",
  createDemoOrganization: "createDemoOrganization",
  createDemoOrganizationDescription: "createDemoOrganizationDescription",
  createFolder: "createFolder",
  createStrategy: "createStrategy",
  created: "created",
  createdBy: "createdBy",
  creationSuccessfulDemoOrganization: "creationSuccessfulDemoOrganization",
  cron: "cron",
  csvFileSupport: "csvFileSupport",
  current: "current",
  currentOrganization: "currentOrganization",
  currentStructure: "currentStructure",
  currentSubmission: "currentSubmission",
  currentView: "currentView",
  custom: "custom",
  customRoute: "customRoute",
  dataFiles: "dataFiles",
  dataProcessingTasksSideNavTitle: "dataProcessingTasksSideNavTitle",
  dataset: "dataset",
  datasetType: "datasetType",
  datasetTypeExplanation_generated: "datasetTypeExplanation_generated",
  datasetTypeExplanation_training: "datasetTypeExplanation_training",
  datasetType_generated: "datasetType_generated",
  datasetType_training: "datasetType_training",
  datasets: "datasets",
  datasetsEmpty: "datasetsEmpty",
  date: "date",
  dateAdded: "dateAdded",
  days: "days",
  delete: "delete",
  deleteChemicalFilter: "deleteChemicalFilter",
  deleteForever: "deleteForever",
  deleteReview: "deleteReview",
  deleteRouteFeedback: "deleteRouteFeedback",
  deleteTreeNode: "deleteTreeNode",
  deletionSuccessful: "deletionSuccessful",
  demoOrganizationNamePresentation: "demoOrganizationNamePresentation",
  description: "description",
  descriptor_CrippenClogP: "descriptor_CrippenClogP",
  descriptor_CrippenMR: "descriptor_CrippenMR",
  descriptor_FractionCSP3: "descriptor_FractionCSP3",
  descriptor_NumAliphaticHeterocycles: "descriptor_NumAliphaticHeterocycles",
  descriptor_NumAliphaticRings: "descriptor_NumAliphaticRings",
  descriptor_NumAmideBonds: "descriptor_NumAmideBonds",
  descriptor_NumAromaticHeterocycles: "descriptor_NumAromaticHeterocycles",
  descriptor_NumAromaticRings: "descriptor_NumAromaticRings",
  descriptor_NumAtomStereoCenters: "descriptor_NumAtomStereoCenters",
  descriptor_NumBridgeheadAtoms: "descriptor_NumBridgeheadAtoms",
  descriptor_NumHBA: "descriptor_NumHBA",
  descriptor_NumHBD: "descriptor_NumHBD",
  descriptor_NumHeteroatoms: "descriptor_NumHeteroatoms",
  descriptor_NumHeterocycles: "descriptor_NumHeterocycles",
  descriptor_NumRings: "descriptor_NumRings",
  descriptor_NumRotatableBonds: "descriptor_NumRotatableBonds",
  descriptor_NumSaturatedHeterocycles: "descriptor_NumSaturatedHeterocycles",
  descriptor_NumSaturatedRings: "descriptor_NumSaturatedRings",
  descriptor_NumSpiroAtoms: "descriptor_NumSpiroAtoms",
  descriptor_NumUnspecifiedAtomStereoCenters:
    "descriptor_NumUnspecifiedAtomStereoCenters",
  descriptor_amw: "descriptor_amw",
  descriptor_clogp: "descriptor_clogp",
  descriptor_crippen_clog_p: "descriptor_crippen_clog_p",
  descriptor_exactmw: "descriptor_exactmw",
  descriptor_fraction_csp3: "descriptor_fraction_csp3",
  descriptor_fsp3: "descriptor_fsp3",
  descriptor_fsp_3: "descriptor_fsp_3",
  descriptor_labuteASA: "descriptor_labuteASA",
  descriptor_lipinskiHBA: "descriptor_lipinskiHBA",
  descriptor_lipinskiHBD: "descriptor_lipinskiHBD",
  descriptor_molecularWeight: "descriptor_molecularWeight",
  descriptor_molecular_weight: "descriptor_molecular_weight",
  descriptor_mw: "descriptor_mw",
  descriptor_n_NHOH: "descriptor_n_NHOH",
  descriptor_n_NO: "descriptor_n_NO",
  descriptor_n_lipinski_hba: "descriptor_n_lipinski_hba",
  descriptor_n_lipinski_hbd: "descriptor_n_lipinski_hbd",
  descriptor_num_aromatic_rings: "descriptor_num_aromatic_rings",
  descriptor_num_hba: "descriptor_num_hba",
  descriptor_num_hbd: "descriptor_num_hbd",
  descriptor_num_heavy_atoms: "descriptor_num_heavy_atoms",
  descriptor_num_heteroatoms: "descriptor_num_heteroatoms",
  descriptor_num_rings: "descriptor_num_rings",
  descriptor_qed: "descriptor_qed",
  descriptor_refractivity: "descriptor_refractivity",
  descriptor_sas: "descriptor_sas",
  descriptor_tpsa: "descriptor_tpsa",
  descriptors: "descriptors",
  descriptorsList: "descriptorsList",
  descriptorsPrefix: "descriptorsPrefix",
  descriptorsSideNavTitle: "descriptorsSideNavTitle",
  descriptorsTaskHistoryDescription: "descriptorsTaskHistoryDescription",
  descriptorsTaskHistoryTitle: "descriptorsTaskHistoryTitle",
  deselectAll: "deselectAll",
  dimensional: "dimensional",
  displayName: "displayName",
  distributed: "distributed",
  distributedScoringSideNavTitle: "distributedScoringSideNavTitle",
  distributedScoringTasksPageDescription:
    "distributedScoringTasksPageDescription",
  distributedScoringTasksPageTitle: "distributedScoringTasksPageTitle",
  doNotUseForScoring: "doNotUseForScoring",
  dockerImageName: "dockerImageName",
  dockerImageTag: "dockerImageTag",
  docs: "docs",
  documentation: "documentation",
  donwloadCurrentViewTooltip: "donwloadCurrentViewTooltip",
  doubleClick: "doubleClick",
  download: "download",
  downloadAsPNG: "downloadAsPNG",
  downloadAsSVG: "downloadAsSVG",
  downloadCompounds: "downloadCompounds",
  downloadCurrentReview: "downloadCurrentReview",
  downloadFailed: "downloadFailed",
  downloadFailedDescription: "downloadFailedDescription",
  downloadImage: "downloadImage",
  downloadInputFile: "downloadInputFile",
  downloadOriginalFile: "downloadOriginalFile",
  downloadOriginalFileRaw: "downloadOriginalFileRaw",
  downloadResults: "downloadResults",
  downloadReview: "downloadReview",
  downloadScope: "downloadScope",
  downloadStrategyConfig: "downloadStrategyConfig",
  downloadTemplate: "downloadTemplate",
  downloadTransformerFile: "downloadTransformerFile",
  downloading: "downloading",
  downloadingFileContext: "downloadingFileContext",
  dragReportHere: "dragReportHere",
  drawStructure: "drawStructure",
  drive: "drive",
  driveDeletionInsufficientPermissions: "driveDeletionInsufficientPermissions",
  driveFile: "driveFile",
  driveNotificationSettings: "driveNotificationSettings",
  driveNotifications: "driveNotifications",
  drivePageDescription: "drivePageDescription",
  drivePageTitle: "drivePageTitle",
  driveRestorationAncestorInTrash: "driveRestorationAncestorInTrash",
  driveTrash: "driveTrash",
  driveTrash30DaysNotice: "driveTrash30DaysNotice",
  driveTrashPageDescription: "driveTrashPageDescription",
  driveTrashPageTitle: "driveTrashPageTitle",
  driveWhatToTrash: "driveWhatToTrash",
  edit: "edit",
  editChemicalFilter: "editChemicalFilter",
  editChemicalFilterPageDescription: "editChemicalFilterPageDescription",
  editChemicalFilterPageTitle: "editChemicalFilterPageTitle",
  editComment: "editComment",
  editCompoundLibraryPageDescription: "editCompoundLibraryPageDescription",
  editCompoundLibraryPageTitle: "editCompoundLibraryPageTitle",
  editFilter: "editFilter",
  editFlagPageDescription: "editFlagPageDescription",
  editFlagPageTitle: "editFlagPageTitle",
  editGenericTemplateTitle: "editGenericTemplateTitle",
  editGroupPageDescription: "editGroupPageDescription",
  editGroupPageTitle: "editGroupPageTitle",
  editMemberPageDescription: "editMemberPageDescription",
  editMemberPageTitle: "editMemberPageTitle",
  editOrganization: "editOrganization",
  editOrganizationPageDescription: "editOrganizationPageDescription",
  editOrganizationPageTitle: "editOrganizationPageTitle",
  editParameters: "editParameters",
  editProject: "editProject",
  editProjectDetails: "editProjectDetails",
  editProjectPageDescription: "editProjectPageDescription",
  editProjectPageTitle: "editProjectPageTitle",
  editStrategy: "editStrategy",
  editStrategyDetails: "editStrategyDetails",
  editSubmit: "editSubmit",
  editTemplate: "editTemplate",
  editor: "editor",
  editorDescription: "editorDescription",
  editorLabel: "editorLabel",
  editorSubmission: "editorSubmission",
  elapsedTime: "elapsedTime",
  emailAddress: "emailAddress",
  emptyTrash: "emptyTrash",
  enableRetrosynthesisSearch: "enableRetrosynthesisSearch",
  enableRetrosynthesisSearchDescription:
    "enableRetrosynthesisSearchDescription",
  enabled: "enabled",
  enterAFileName: "enterAFileName",
  enterChemicalFilterDescription: "enterChemicalFilterDescription",
  enterChemicalFilterName: "enterChemicalFilterName",
  enterComment: "enterComment",
  enterCompoundLibraryDescription: "enterCompoundLibraryDescription",
  enterCompoundLibraryName: "enterCompoundLibraryName",
  enterDescription: "enterDescription",
  enterDisplayName: "enterDisplayName",
  enterDockerImageName: "enterDockerImageName",
  enterDockerImageTag: "enterDockerImageTag",
  enterEmailAddress: "enterEmailAddress",
  enterEmailAndPassword: "enterEmailAndPassword",
  enterFeatureFlagName: "enterFeatureFlagName",
  enterFolderName: "enterFolderName",
  enterGroupDescription: "enterGroupDescription",
  enterGroupName: "enterGroupName",
  enterMachineName: "enterMachineName",
  enterMembersForGroup: "enterMembersForGroup",
  enterModelDescription: "enterModelDescription",
  enterModelVersion: "enterModelVersion",
  enterName: "enterName",
  enterOneSmartsOrSmiles: "enterOneSmartsOrSmiles",
  enterOrganizationName: "enterOrganizationName",
  enterProjectDescription: "enterProjectDescription",
  enterProjectName: "enterProjectName",
  enterReviewComment: "enterReviewComment",
  enterReviewers: "enterReviewers",
  enterSmarts: "enterSmarts",
  enterSmartsOrSmiles: "enterSmartsOrSmiles",
  enterSmiles: "enterSmiles",
  enterStrategyCollaborators: "enterStrategyCollaborators",
  enterStrategyDescription: "enterStrategyDescription",
  enterStrategyName: "enterStrategyName",
  enterUnits: "enterUnits",
  enterValue: "enterValue",
  entityAddedSuccessfully: "entityAddedSuccessfully",
  error: "error",
  errorCode_SUGGESTED_MOLECULES_ALREADY_EXIST_description:
    "errorCode_SUGGESTED_MOLECULES_ALREADY_EXIST_description",
  errorCode_SUGGESTED_MOLECULES_ALREADY_EXIST_title:
    "errorCode_SUGGESTED_MOLECULES_ALREADY_EXIST_title",
  errorCode_SUGGESTED_MOLECULES_MAX_EXCEEDED_description:
    "errorCode_SUGGESTED_MOLECULES_MAX_EXCEEDED_description",
  errorCode_SUGGESTED_MOLECULES_MAX_EXCEEDED_title:
    "errorCode_SUGGESTED_MOLECULES_MAX_EXCEEDED_title",
  everyone: "everyone",
  exactSearch: "exactSearch",
  exactSearchResults: "exactSearchResults",
  exampleBindingIC50Name: "exampleBindingIC50Name",
  exampleBindingIC50Thresold: "exampleBindingIC50Thresold",
  exampleBindingIC50Title: "exampleBindingIC50Title",
  exampleBindingIC50Units: "exampleBindingIC50Units",
  exampleCellDeathName: "exampleCellDeathName",
  exampleCellDeathThresold: "exampleCellDeathThresold",
  exampleCellDeathTitle: "exampleCellDeathTitle",
  exampleCellDeathUnits: "exampleCellDeathUnits",
  exampleScenarios: "exampleScenarios",
  exclude: "exclude",
  exitFullScreen: "exitFullScreen",
  exitFullScreenSimple: "exitFullScreenSimple",
  expand: "expand",
  exploreChemicalSearchRoutes: "exploreChemicalSearchRoutes",
  exploreResults: "exploreResults",
  exploreRoute: "exploreRoute",
  exploreScenarios: "exploreScenarios",
  export: "export",
  exportCsv: "exportCsv",
  exportRoute: "exportRoute",
  familyName: "familyName",
  featureFlagAddedSuccessfully: "featureFlagAddedSuccessfully",
  featureFlagAlreadyExists: "featureFlagAlreadyExists",
  featureFlagEditedSuccessfully: "featureFlagEditedSuccessfully",
  featureFlagRemovedSuccessfully: "featureFlagRemovedSuccessfully",
  featureFlags: "featureFlags",
  featurize: "featurize",
  featurizePageTitle: "featurizePageTitle",
  featurizeTaskHistoryDescription: "featurizeTaskHistoryDescription",
  featurizeTaskHistoryTitle: "featurizeTaskHistoryTitle",
  feedbackReceivedWillBeConsidered: "feedbackReceivedWillBeConsidered",
  ffp2: "ffp2",
  ffp2Help: "ffp2Help",
  file: "file",
  fileFormat: "fileFormat",
  fileName: "fileName",
  fileNameContainsIllegalChars: "fileNameContainsIllegalChars",
  fileNotUploadedProperly: "fileNotUploadedProperly",
  filePreview: "filePreview",
  fileSize: "fileSize",
  fileSubmission: "fileSubmission",
  fileTooLargeContent: "fileTooLargeContent",
  fileTooLargeTitle: "fileTooLargeTitle",
  filter: "filter",
  filterBySpecificInfo: "filterBySpecificInfo",
  filterFrom: "filterFrom",
  filters: "filters",
  filtersAndVisualizations: "filtersAndVisualizations",
  fingerprintType: "fingerprintType",
  flagRoute: "flagRoute",
  flagsPageDescription: "flagsPageDescription",
  flagsPageTitle: "flagsPageTitle",
  forgotPassword: "forgotPassword",
  foundResults: "foundResults",
  fullName: "fullName",
  fullNameLabel: "fullNameLabel",
  fullNamePlaceholder: "fullNamePlaceholder",
  fullNameTip: "fullNameTip",
  general: "general",
  generalComment: "generalComment",
  generalComments: "generalComments",
  generatedMolecules: "generatedMolecules",
  generatedMoleculesCollapse: "generatedMoleculesCollapse",
  genericTemplateLabel_json: "genericTemplateLabel_json",
  genericTemplateLabel_py: "genericTemplateLabel_py",
  genericTemplateLabel_txt: "genericTemplateLabel_txt",
  genericTemplateLabel_yaml: "genericTemplateLabel_yaml",
  genericTemplatesPageDescription: "genericTemplatesPageDescription",
  genericTemplatesPageTitle: "genericTemplatesPageTitle",
  giveNameToFirstTeam: "giveNameToFirstTeam",
  givenName: "givenName",
  goBack: "goBack",
  goBackToDrive: "goBackToDrive",
  goBackToGroups: "goBackToGroups",
  goBackToReview: "goBackToReview",
  goFullScreen: "goFullScreen",
  goFullScreenSimple: "goFullScreenSimple",
  goTo: "goTo",
  goToDrive: "goToDrive",
  goToLocation: "goToLocation",
  goToReviewSummary: "goToReviewSummary",
  goToValencePlatform: "goToValencePlatform",
  group: "group",
  groupActions: "groupActions",
  groupAlreadyExists: "groupAlreadyExists",
  groupBy: "groupBy",
  groupType_main_fragment: "groupType_main_fragment",
  groupType_scaffold: "groupType_scaffold",
  groups: "groups",
  groupsPageDescription: "groupsPageDescription",
  groupsPageTitle: "groupsPageTitle",
  help: "help",
  helpWithShortcut: "helpWithShortcut",
  hidden: "hidden",
  hiddenColumns: "hiddenColumns",
  hideSuggested: "hideSuggested",
  hideThis: "hideThis",
  history: "history",
  home: "home",
  homePageActiveProjects: "homePageActiveProjects",
  homePageChemicalSearchesDescription: "homePageChemicalSearchesDescription",
  homePageDescription: "homePageDescription",
  homePageNoChemicalSearchesWarningDescription:
    "homePageNoChemicalSearchesWarningDescription",
  homePageNoCompoundLibrariesWarningDescription:
    "homePageNoCompoundLibrariesWarningDescription",
  homePageNoMembersWarningDescription: "homePageNoMembersWarningDescription",
  homePageNoMembersWarningTitle: "homePageNoMembersWarningTitle",
  homePageNoProjectsWarningDescription: "homePageNoProjectsWarningDescription",
  homePageNoStrategiesWarningDescription:
    "homePageNoStrategiesWarningDescription",
  homePageOneMemberWarningDescription: "homePageOneMemberWarningDescription",
  homePageStrategiesDescription: "homePageStrategiesDescription",
  hoverStructureWillAppear: "hoverStructureWillAppear",
  howCanWeHelp: "howCanWeHelp",
  howCanWeHelpDescription: "howCanWeHelpDescription",
  howCanWeHelpMessageFieldLabel: "howCanWeHelpMessageFieldLabel",
  howCanWeHelpMessageFieldPlaceholder: "howCanWeHelpMessageFieldPlaceholder",
  howCanWeHelpOptionMessageTopicFieldLabel:
    "howCanWeHelpOptionMessageTopicFieldLabel",
  howCanWeHelpOptionMessageTopicField_FE:
    "howCanWeHelpOptionMessageTopicField_FE",
  howCanWeHelpOptionMessageTopicField_LI:
    "howCanWeHelpOptionMessageTopicField_LI",
  howCanWeHelpOptionMessageTopicField_PR:
    "howCanWeHelpOptionMessageTopicField_PR",
  howCanWeHelpOptionMessageTopicField_QU:
    "howCanWeHelpOptionMessageTopicField_QU",
  howCanWeHelpSend: "howCanWeHelpSend",
  howCanWeHelpTitle: "howCanWeHelpTitle",
  id: "id",
  idReservedColTooltip: "idReservedColTooltip",
  identifiers: "identifiers",
  imageActions: "imageActions",
  imageCopyUnsupportedForThisNavigator: "imageCopyUnsupportedForThisNavigator",
  import: "import",
  inchikey: "inchikey",
  inchikeySearchPlaceholder: "inchikeySearchPlaceholder",
  include: "include",
  information: "information",
  inputFile: "inputFile",
  inputPath: "inputPath",
  inputRemoteHelp: "inputRemoteHelp",
  integrations: "integrations",
  interval: "interval",
  invalidAddProperty: "invalidAddProperty",
  invalidStatementsLogic: "invalidStatementsLogic",
  isItSolved: "isItSolved",
  isSmartsOrSmiles: "isSmartsOrSmiles",
  kernelLabel: "kernelLabel",
  label: "label",
  large: "large",
  lastLibraryAdded: "lastLibraryAdded",
  lastMemberInvited: "lastMemberInvited",
  lastRefresh: "lastRefresh",
  lastRunAt: "lastRunAt",
  lastSaved: "lastSaved",
  lastUpdate: "lastUpdate",
  lastUpdateWithTime: "lastUpdateWithTime",
  launch: "launch",
  launchNamedSearch: "launchNamedSearch",
  launchQuickSearch: "launchQuickSearch",
  launchScoringJob: "launchScoringJob",
  launchSearch: "launchSearch",
  launchSubmission: "launchSubmission",
  leadTimeDescription: "leadTimeDescription",
  leadTimeInDays: "leadTimeInDays",
  leadTimeMayVary: "leadTimeMayVary",
  leaveAReviewComment: "leaveAReviewComment",
  leaveARouteFlaggingComment: "leaveARouteFlaggingComment",
  leaveFeedbackAboutRoute: "leaveFeedbackAboutRoute",
  legacyReview: "legacyReview",
  legacyReviewContext: "legacyReviewContext",
  library: "library",
  license: "license",
  licenseLimitSuffix_limit_chemfilters: "licenseLimitSuffix_limit_chemfilters",
  licenseLimitSuffix_limit_chemsearch_max_results:
    "licenseLimitSuffix_limit_chemsearch_max_results",
  licenseLimitSuffix_limit_chemsearch_per_day:
    "licenseLimitSuffix_limit_chemsearch_per_day",
  licenseLimitSuffix_limit_drive_storage:
    "licenseLimitSuffix_limit_drive_storage",
  licenseLimitSuffix_limit_organization_members:
    "licenseLimitSuffix_limit_organization_members",
  licenseLimitSuffix_limit_projects: "licenseLimitSuffix_limit_projects",
  licenseLimitSuffix_limit_scoring_per_day:
    "licenseLimitSuffix_limit_scoring_per_day",
  licenseLimitSuffix_limit_strategies: "licenseLimitSuffix_limit_strategies",
  licenseLimit_limit_chemfilters: "licenseLimit_limit_chemfilters",
  licenseLimit_limit_chemsearch_max_results:
    "licenseLimit_limit_chemsearch_max_results",
  licenseLimit_limit_chemsearch_per_day:
    "licenseLimit_limit_chemsearch_per_day",
  licenseLimit_limit_drive_storage: "licenseLimit_limit_drive_storage",
  licenseLimit_limit_organization_members:
    "licenseLimit_limit_organization_members",
  licenseLimit_limit_projects: "licenseLimit_limit_projects",
  licenseLimit_limit_scoring_per_day: "licenseLimit_limit_scoring_per_day",
  licenseLimit_limit_strategies: "licenseLimit_limit_strategies",
  limit_CHEMICAL_SEARCH_LIMIT_EXCEEDED: "limit_CHEMICAL_SEARCH_LIMIT_EXCEEDED",
  limit_CHEMICAL_SEARCH_MAX_RESULTS_LIMIT_EXCEEDED:
    "limit_CHEMICAL_SEARCH_MAX_RESULTS_LIMIT_EXCEEDED",
  limit_DRIVE_STORAGE_LIMIT_EXCEEDED: "limit_DRIVE_STORAGE_LIMIT_EXCEEDED",
  limit_ORGANIZATION_MEMBER_LIMIT_EXCEEDED:
    "limit_ORGANIZATION_MEMBER_LIMIT_EXCEEDED",
  limit_SCORING_JOBS_LIMIT_EXCEEDED: "limit_SCORING_JOBS_LIMIT_EXCEEDED",
  limits: "limits",
  loading: "loading",
  logicMissing: "logicMissing",
  logicType_EX: "logicType_EX",
  logicType_IN: "logicType_IN",
  logicalOperator_AND: "logicalOperator_AND",
  logicalOperator_OR: "logicalOperator_OR",
  lowerLimit: "lowerLimit",
  machineLearning: "machineLearning",
  machineName: "machineName",
  machineType: "machineType",
  mainDescriptors: "mainDescriptors",
  mainFragmentOnly: "mainFragmentOnly",
  mainFragmentOnlyCheckedDescription: "mainFragmentOnlyCheckedDescription",
  mainFragmentOnlyDescription: "mainFragmentOnlyDescription",
  mainFragmentOnlyNotCheckedDescription:
    "mainFragmentOnlyNotCheckedDescription",
  mainFragmentOnlyScenarioDescription: "mainFragmentOnlyScenarioDescription",
  mainName: "mainName",
  manage: "manage",
  manageColumns: "manageColumns",
  manageUsers: "manageUsers",
  markAsComplete: "markAsComplete",
  markAsOpen: "markAsOpen",
  matchMaxValue: "matchMaxValue",
  matchMinValue: "matchMinValue",
  matchType: "matchType",
  matchTypeMissing: "matchTypeMissing",
  matchType_EQ: "matchType_EQ",
  matchType_GT: "matchType_GT",
  matchType_GTE: "matchType_GTE",
  matchType_LT: "matchType_LT",
  matchType_LTE: "matchType_LTE",
  matchType_WR: "matchType_WR",
  matchValue: "matchValue",
  matchValueMissing: "matchValueMissing",
  maxFilesUploadExceeded: "maxFilesUploadExceeded",
  maxFilesUploadExceededDescription: "maxFilesUploadExceededDescription",
  maxSearchCompoundsExceededDescription:
    "maxSearchCompoundsExceededDescription",
  maxSearchCompoundsExceededTitle: "maxSearchCompoundsExceededTitle",
  meaningfulNameSearch: "meaningfulNameSearch",
  medchemAlertDescription_: "medchemAlertDescription_",
  medchemAlertDescription_alarm_nmr: "medchemAlertDescription_alarm_nmr",
  medchemAlertDescription_alphascreen: "medchemAlertDescription_alphascreen",
  medchemAlertDescription_bms: "medchemAlertDescription_bms",
  medchemAlertDescription_brenk: "medchemAlertDescription_brenk",
  medchemAlertDescription_dnabinder: "medchemAlertDescription_dnabinder",
  medchemAlertDescription_dundee: "medchemAlertDescription_dundee",
  medchemAlertDescription_electrophilic:
    "medchemAlertDescription_electrophilic",
  medchemAlertDescription_glaxo: "medchemAlertDescription_glaxo",
  medchemAlertDescription_hitters: "medchemAlertDescription_hitters",
  medchemAlertDescription_inpharmatica: "medchemAlertDescription_inpharmatica",
  medchemAlertDescription_ld50_oral: "medchemAlertDescription_ld50_oral",
  medchemAlertDescription_lint: "medchemAlertDescription_lint",
  medchemAlertDescription_luciferase: "medchemAlertDescription_luciferase",
  medchemAlertDescription_mlsmr: "medchemAlertDescription_mlsmr",
  medchemAlertDescription_nibr: "medchemAlertDescription_nibr",
  medchemAlertDescription_nih: "medchemAlertDescription_nih",
  medchemAlertDescription_pains: "medchemAlertDescription_pains",
  medchemAlertDescription_pains_b: "medchemAlertDescription_pains_b",
  medchemAlertDescription_pains_c: "medchemAlertDescription_pains_c",
  medchemAlertDescription_schembl: "medchemAlertDescription_schembl",
  medchemAlertDescription_toxicophore: "medchemAlertDescription_toxicophore",
  medchemAlertDescription_zinc: "medchemAlertDescription_zinc",
  medchemAlertRef_alarm_nmr: "medchemAlertRef_alarm_nmr",
  medchemAlertRef_bms: "medchemAlertRef_bms",
  medchemAlertRef_brenk: "medchemAlertRef_brenk",
  medchemAlertRef_dundee: "medchemAlertRef_dundee",
  medchemAlertRef_glaxo: "medchemAlertRef_glaxo",
  medchemAlertRef_inpharmatica: "medchemAlertRef_inpharmatica",
  medchemAlertRef_lint: "medchemAlertRef_lint",
  medchemAlertRef_mlsmr: "medchemAlertRef_mlsmr",
  medchemAlertRef_nibr: "medchemAlertRef_nibr",
  medchemAlertRef_nih: "medchemAlertRef_nih",
  medchemAlertRef_pains: "medchemAlertRef_pains",
  medchemAlertRef_pains_a: "medchemAlertRef_pains_a",
  medchemAlertRef_pains_b: "medchemAlertRef_pains_b",
  medchemAlertRef_pains_c: "medchemAlertRef_pains_c",
  medchemAlertRef_schembl: "medchemAlertRef_schembl",
  medchemAlertRef_zinc: "medchemAlertRef_zinc",
  medchemAlert_alarm_nmr: "medchemAlert_alarm_nmr",
  medchemAlert_alphascreen: "medchemAlert_alphascreen",
  medchemAlert_bms: "medchemAlert_bms",
  medchemAlert_brenk: "medchemAlert_brenk",
  medchemAlert_dnabinder: "medchemAlert_dnabinder",
  medchemAlert_dundee: "medchemAlert_dundee",
  medchemAlert_electrophilic: "medchemAlert_electrophilic",
  medchemAlert_glaxo: "medchemAlert_glaxo",
  medchemAlert_hitters: "medchemAlert_hitters",
  medchemAlert_inpharmatica: "medchemAlert_inpharmatica",
  medchemAlert_ld50_oral: "medchemAlert_ld50_oral",
  medchemAlert_lint: "medchemAlert_lint",
  medchemAlert_luciferase: "medchemAlert_luciferase",
  medchemAlert_mlsmr: "medchemAlert_mlsmr",
  medchemAlert_nibr: "medchemAlert_nibr",
  medchemAlert_nih: "medchemAlert_nih",
  medchemAlert_pains: "medchemAlert_pains",
  medchemAlert_pains_a: "medchemAlert_pains_a",
  medchemAlert_pains_b: "medchemAlert_pains_b",
  medchemAlert_pains_c: "medchemAlert_pains_c",
  medchemAlert_schembl: "medchemAlert_schembl",
  medchemAlert_toxicophore: "medchemAlert_toxicophore",
  medchemAlert_zinc: "medchemAlert_zinc",
  medchemRuleDescription_rule_of_cns: "medchemRuleDescription_rule_of_cns",
  medchemRuleDescription_rule_of_egan: "medchemRuleDescription_rule_of_egan",
  medchemRuleDescription_rule_of_five: "medchemRuleDescription_rule_of_five",
  medchemRuleDescription_rule_of_five_beyond:
    "medchemRuleDescription_rule_of_five_beyond",
  medchemRuleDescription_rule_of_four: "medchemRuleDescription_rule_of_four",
  medchemRuleDescription_rule_of_ghose: "medchemRuleDescription_rule_of_ghose",
  medchemRuleDescription_rule_of_gsk_4_400:
    "medchemRuleDescription_rule_of_gsk_4_400",
  medchemRuleDescription_rule_of_leadlike_soft:
    "medchemRuleDescription_rule_of_leadlike_soft",
  medchemRuleDescription_rule_of_oprea: "medchemRuleDescription_rule_of_oprea",
  medchemRuleDescription_rule_of_pfizer_3_75:
    "medchemRuleDescription_rule_of_pfizer_3_75",
  medchemRuleDescription_rule_of_reos: "medchemRuleDescription_rule_of_reos",
  medchemRuleDescription_rule_of_three: "medchemRuleDescription_rule_of_three",
  medchemRuleDescription_rule_of_three_extended:
    "medchemRuleDescription_rule_of_three_extended",
  medchemRuleDescription_rule_of_veber: "medchemRuleDescription_rule_of_veber",
  medchemRuleDescription_rule_of_zinc: "medchemRuleDescription_rule_of_zinc",
  medchemRuleRef_rule_of_cns: "medchemRuleRef_rule_of_cns",
  medchemRuleRef_rule_of_egan: "medchemRuleRef_rule_of_egan",
  medchemRuleRef_rule_of_five: "medchemRuleRef_rule_of_five",
  medchemRuleRef_rule_of_five_beyond: "medchemRuleRef_rule_of_five_beyond",
  medchemRuleRef_rule_of_four: "medchemRuleRef_rule_of_four",
  medchemRuleRef_rule_of_ghose: "medchemRuleRef_rule_of_ghose",
  medchemRuleRef_rule_of_gsk_4_400: "medchemRuleRef_rule_of_gsk_4_400",
  medchemRuleRef_rule_of_leadlike_soft: "medchemRuleRef_rule_of_leadlike_soft",
  medchemRuleRef_rule_of_oprea: "medchemRuleRef_rule_of_oprea",
  medchemRuleRef_rule_of_pfizer_3_75: "medchemRuleRef_rule_of_pfizer_3_75",
  medchemRuleRef_rule_of_reos: "medchemRuleRef_rule_of_reos",
  medchemRuleRef_rule_of_three: "medchemRuleRef_rule_of_three",
  medchemRuleRef_rule_of_veber: "medchemRuleRef_rule_of_veber",
  medchemRuleRef_rule_of_zinc: "medchemRuleRef_rule_of_zinc",
  medchemRule_rule_of_cns: "medchemRule_rule_of_cns",
  medchemRule_rule_of_egan: "medchemRule_rule_of_egan",
  medchemRule_rule_of_five: "medchemRule_rule_of_five",
  medchemRule_rule_of_five_beyond: "medchemRule_rule_of_five_beyond",
  medchemRule_rule_of_four: "medchemRule_rule_of_four",
  medchemRule_rule_of_ghose: "medchemRule_rule_of_ghose",
  medchemRule_rule_of_gsk_4_400: "medchemRule_rule_of_gsk_4_400",
  medchemRule_rule_of_leadlike_soft: "medchemRule_rule_of_leadlike_soft",
  medchemRule_rule_of_oprea: "medchemRule_rule_of_oprea",
  medchemRule_rule_of_pfizer_3_75: "medchemRule_rule_of_pfizer_3_75",
  medchemRule_rule_of_reos: "medchemRule_rule_of_reos",
  medchemRule_rule_of_three: "medchemRule_rule_of_three",
  medchemRule_rule_of_three_extended: "medchemRule_rule_of_three_extended",
  medchemRule_rule_of_veber: "medchemRule_rule_of_veber",
  medchemRule_rule_of_zinc: "medchemRule_rule_of_zinc",
  medicinalChemistryAlerts: "medicinalChemistryAlerts",
  medium: "medium",
  member: "member",
  memberSince: "memberSince",
  memberSinceDate: "memberSinceDate",
  members: "members",
  membersPageDescription: "membersPageDescription",
  membersPageTitle: "membersPageTitle",
  mfp2: "mfp2",
  mfp2Help: "mfp2Help",
  mode: "mode",
  modelAnalysisTasksSideNavTitle: "modelAnalysisTasksSideNavTitle",
  modelDescription: "modelDescription",
  modelInformation: "modelInformation",
  modelName: "modelName",
  modelSelection: "modelSelection",
  modelSelectionTasksPageDescription: "modelSelectionTasksPageDescription",
  modelSelectionTasksPageTitle: "modelSelectionTasksPageTitle",
  modelSelectionTasksSideNavTitle: "modelSelectionTasksSideNavTitle",
  modelVersion: "modelVersion",
  models: "models",
  modelsAssignmentTitle: "modelsAssignmentTitle",
  modelsConfiguration: "modelsConfiguration",
  modelsInformation: "modelsInformation",
  modelsList: "modelsList",
  modelversionsAssignmentTitle: "modelversionsAssignmentTitle",
  modify: "modify",
  molecularEditor: "molecularEditor",
  molecularSketching: "molecularSketching",
  moleculeAlerts: "moleculeAlerts",
  moleculeEditor: "moleculeEditor",
  moleculeResultModalTitle: "moleculeResultModalTitle",
  moleculeReview: "moleculeReview",
  moleculeSubmissionConfirmation: "moleculeSubmissionConfirmation",
  moleculeSubmissionError_EMBEDDING_COL_MISSING:
    "moleculeSubmissionError_EMBEDDING_COL_MISSING",
  moleculeSubmissionError_FILE_EMPTY: "moleculeSubmissionError_FILE_EMPTY",
  moleculeSubmissionError_MANDATORY_COL_MISSING:
    "moleculeSubmissionError_MANDATORY_COL_MISSING",
  molecules: "molecules",
  moleculesDeletedSuccessfully: "moleculesDeletedSuccessfully",
  moleculesPlural: "moleculesPlural",
  moleculesSubmissionTitle: "moleculesSubmissionTitle",
  moleculesSubmittedSuccessfully: "moleculesSubmittedSuccessfully",
  moreActions: "moreActions",
  moreDetails: "moreDetails",
  moreInfo: "moreInfo",
  moveTo: "moveTo",
  moveToTrash: "moveToTrash",
  multiFileSupport: "multiFileSupport",
  multipleStructures: "multipleStructures",
  multipleStructuresScenarioDescription:
    "multipleStructuresScenarioDescription",
  mustBePositive: "mustBePositive",
  nBlocks: "nBlocks",
  nCompoundsWillBeScored: "nCompoundsWillBeScored",
  nCompoundsWillBeScoredSub: "nCompoundsWillBeScoredSub",
  nSteps: "nSteps",
  na: "na",
  name: "name",
  nameForSubmission: "nameForSubmission",
  nameMissing: "nameMissing",
  nameYourSearch: "nameYourSearch",
  navigation: "navigation",
  navigationHelp: "navigationHelp",
  negative: "negative",
  newFolder: "newFolder",
  newPassword: "newPassword",
  newSearch: "newSearch",
  next: "next",
  nextMolecule: "nextMolecule",
  nextStep: "nextStep",
  no: "no",
  noActivities: "noActivities",
  noAdditionalCatalogsFound: "noAdditionalCatalogsFound",
  noAlerts: "noAlerts",
  noAlertsSimple: "noAlertsSimple",
  noAssignedReviewers: "noAssignedReviewers",
  noAvailabilitiesFromProvider: "noAvailabilitiesFromProvider",
  noChemicalFilters: "noChemicalFilters",
  noChemicalSearches: "noChemicalSearches",
  noComment: "noComment",
  noCommentLeft: "noCommentLeft",
  noComments: "noComments",
  noCommentsLeft: "noCommentsLeft",
  noCompoundLibraries: "noCompoundLibraries",
  noCompoundsSubmittedYet: "noCompoundsSubmittedYet",
  noCompoundsToSubmit: "noCompoundsToSubmit",
  noCompoundsToSubmitContent: "noCompoundsToSubmitContent",
  noDisplayedColumns: "noDisplayedColumns",
  noDriveFiles: "noDriveFiles",
  noExtraInfoAvailable: "noExtraInfoAvailable",
  noFeatureFlags: "noFeatureFlags",
  noFeaturizeTasks: "noFeaturizeTasks",
  noFilesAdded: "noFilesAdded",
  noFilesOrFolders: "noFilesOrFolders",
  noFiltersCreated: "noFiltersCreated",
  noFiltersSelected: "noFiltersSelected",
  noGenericTemplates: "noGenericTemplates",
  noGroups: "noGroups",
  noHiddenColumns: "noHiddenColumns",
  noInformationProvided: "noInformationProvided",
  noIntegrationsDescription: "noIntegrationsDescription",
  noMembers: "noMembers",
  noMembersInGroup: "noMembersInGroup",
  noMembersInTeamForAddGroup: "noMembersInTeamForAddGroup",
  noMembersInTeamForEditGroup: "noMembersInTeamForEditGroup",
  noModelVersions: "noModelVersions",
  noModels: "noModels",
  noOrganizationMolecules: "noOrganizationMolecules",
  noOrganizations: "noOrganizations",
  noPatents: "noPatents",
  noPeriodicTasks: "noPeriodicTasks",
  noProjects: "noProjects",
  noProjectsInOrg: "noProjectsInOrg",
  noProjectsInOrgError: "noProjectsInOrgError",
  noPropertiesAddedYet: "noPropertiesAddedYet",
  noResults: "noResults",
  noResultsSimilarity: "noResultsSimilarity",
  noResultsSubstruct: "noResultsSubstruct",
  noReviewerCandidates: "noReviewerCandidates",
  noReviewersToSelect: "noReviewersToSelect",
  noRoutesAvailable: "noRoutesAvailable",
  noScoringTasks: "noScoringTasks",
  noServices: "noServices",
  noStrategies: "noStrategies",
  noStructureProvided: "noStructureProvided",
  noStructureToAdd: "noStructureToAdd",
  noTasks: "noTasks",
  noTeams: "noTeams",
  noTrashedFiles: "noTrashedFiles",
  noUsers: "noUsers",
  noVisibleColumns: "noVisibleColumns",
  none: "none",
  noneApplied: "noneApplied",
  noneProvided: "noneProvided",
  notAvailable: "notAvailable",
  notifications: "notifications",
  notifyMeWhenTaskComplete: "notifyMeWhenTaskComplete",
  numberOfAlerts: "numberOfAlerts",
  numberOfAlertsDescription: "numberOfAlertsDescription",
  numberOfChemicalFiltersSelected: "numberOfChemicalFiltersSelected",
  numberOfCompoundsTooLargeContent: "numberOfCompoundsTooLargeContent",
  numberOfCompoundsTooLargeTitle: "numberOfCompoundsTooLargeTitle",
  numberOfFragments: "numberOfFragments",
  numberOfFragmentsDescription: "numberOfFragmentsDescription",
  numberOfItemsSelected: "numberOfItemsSelected",
  numberOfModelsSelected: "numberOfModelsSelected",
  numberReviewComments: "numberReviewComments",
  of: "of",
  ok: "ok",
  oneLowerCaseLetterConstraint: "oneLowerCaseLetterConstraint",
  oneNumberConstraint: "oneNumberConstraint",
  oneSpecialCharacterConstraint: "oneSpecialCharacterConstraint",
  oneUpperCaseLetterConstraint: "oneUpperCaseLetterConstraint",
  onlyOneStructureAllowedDescription: "onlyOneStructureAllowedDescription",
  onlyOneStructureAllowedTitle: "onlyOneStructureAllowedTitle",
  openImageInNewTab: "openImageInNewTab",
  openInWorkspace: "openInWorkspace",
  operatorMissing: "operatorMissing",
  or: "or",
  organization: "organization",
  organizationAddedSuccessfully: "organizationAddedSuccessfully",
  organizationAlreadyExists: "organizationAlreadyExists",
  organizationEditedSuccessfully: "organizationEditedSuccessfully",
  organizationMemberships: "organizationMemberships",
  organizationMoleculesPage: {
    pageDescription: "organizationMoleculesPage.pageDescription",
    pageTitle: "organizationMoleculesPage.pageTitle"
  },
  organizationRemovedSuccessfully: "organizationRemovedSuccessfully",
  organizationSettings: "organizationSettings",
  organizationSettingsDescription: "organizationSettingsDescription",
  organizationSettingsTitle: "organizationSettingsTitle",
  organizations: "organizations",
  organizationsPageDescription: "organizationsPageDescription",
  organizationsPageTitle: "organizationsPageTitle",
  originalCompound: "originalCompound",
  originalName: "originalName",
  otherActions: "otherActions",
  outOf: "outOf",
  outputPath: "outputPath",
  outputPathFolder: "outputPathFolder",
  overview: "overview",
  ownerDescription: "ownerDescription",
  ownerLabel: "ownerLabel",
  page: "page",
  panelN: "panelN",
  parseCDDForm: {
    atpConcentrationLabel: "parseCDDForm.atpConcentrationLabel",
    atpConcentrationPlaceholder: "parseCDDForm.atpConcentrationPlaceholder",
    atpConcentrationTooltip: "parseCDDForm.atpConcentrationTooltip",
    cleanDataLabel: "parseCDDForm.cleanDataLabel",
    cleanDataTooltip: "parseCDDForm.cleanDataTooltip",
    dataColLabel: "parseCDDForm.dataColLabel",
    dataColPlaceholder: "parseCDDForm.dataColPlaceholder",
    dataColTooltip: "parseCDDForm.dataColTooltip",
    dataSheetLabel: "parseCDDForm.dataSheetLabel",
    dataSheetPlaceholder: "parseCDDForm.dataSheetPlaceholder",
    dataSheetTooltip: "parseCDDForm.dataSheetTooltip",
    mergeDataLabel: "parseCDDForm.mergeDataLabel",
    mergeDataTooltip: "parseCDDForm.mergeDataTooltip",
    parsingConfiguration: "parseCDDForm.parsingConfiguration",
    plateIDLabel: "parseCDDForm.plateIDLabel",
    plateIDPlaceholder: "parseCDDForm.plateIDPlaceholder",
    plateIDTooltip: "parseCDDForm.plateIDTooltip",
    plateNameLabel: "parseCDDForm.plateNameLabel",
    plateNamePlaceholder: "parseCDDForm.plateNamePlaceholder",
    plateNameTooltip: "parseCDDForm.plateNameTooltip",
    protocolConfiguration: "parseCDDForm.protocolConfiguration",
    targetColLabel: "parseCDDForm.targetColLabel",
    targetColPlaceholder: "parseCDDForm.targetColPlaceholder",
    targetColTooltip: "parseCDDForm.targetColTooltip",
    targetNameLabel: "parseCDDForm.targetNameLabel",
    targetNamePlaceholder: "parseCDDForm.targetNamePlaceholder",
    targetNameTooltip: "parseCDDForm.targetNameTooltip"
  },
  parseFilesForCDDUpload: "parseFilesForCDDUpload",
  parseForCDD: "parseForCDD",
  parsingFile: "parsingFile",
  password: "password",
  passwordRules: "passwordRules",
  patentor: "patentor",
  patentorPageDescription: "patentorPageDescription",
  patentorPageTitle: "patentorPageTitle",
  patentorPatentListTitle: "patentorPatentListTitle",
  pdf: "pdf",
  pdfReportSupport: "pdfReportSupport",
  performingSearch: "performingSearch",
  periodicTask: "periodicTask",
  periodicTasksDescription: "periodicTasksDescription",
  periodicTasksTitle: "periodicTasksTitle",
  permissionRestrictedPage: "permissionRestrictedPage",
  permissionsMayChangeDescription: "permissionsMayChangeDescription",
  permissionsMayChangeTitle: "permissionsMayChangeTitle",
  platformStatus: "platformStatus",
  platformStatusFunctional: "platformStatusFunctional",
  pleaseAddMembers: "pleaseAddMembers",
  pleaseAddReviewers: "pleaseAddReviewers",
  pleaseAlert: "pleaseAlert",
  pleaseAlphaNumericHyphen: "pleaseAlphaNumericHyphen",
  pleaseAlphaNumericSpaceHyphen: "pleaseAlphaNumericSpaceHyphen",
  pleaseAtLeastOneAssay: "pleaseAtLeastOneAssay",
  pleaseChemicalFilterDescription: "pleaseChemicalFilterDescription",
  pleaseChemicalFilterName: "pleaseChemicalFilterName",
  pleaseCompoundLibraryDescription: "pleaseCompoundLibraryDescription",
  pleaseConfirmPassword: "pleaseConfirmPassword",
  pleaseDescription: "pleaseDescription",
  pleaseDescriptor: "pleaseDescriptor",
  pleaseDescriptors: "pleaseDescriptors",
  pleaseEnterAMessage: "pleaseEnterAMessage",
  pleaseEnterAValidEmail: "pleaseEnterAValidEmail",
  pleaseEnterYourCommentHere: "pleaseEnterYourCommentHere",
  pleaseEnterYourConfirmationCode: "pleaseEnterYourConfirmationCode",
  pleaseFilter: "pleaseFilter",
  pleaseFunctionalGroup: "pleaseFunctionalGroup",
  pleaseGroupDescription: "pleaseGroupDescription",
  pleaseInputValue: "pleaseInputValue",
  pleaseLimitIncrease: "pleaseLimitIncrease",
  pleaseMax255: "pleaseMax255",
  pleaseOneReviewer: "pleaseOneReviewer",
  pleasePasswordRules: "pleasePasswordRules",
  pleaseProjectDescription: "pleaseProjectDescription",
  pleaseProjectTitle: "pleaseProjectTitle",
  pleaseProvideAFileWithCompoundLibrary:
    "pleaseProvideAFileWithCompoundLibrary",
  pleaseProvideAFileWithDatasetTemplate:
    "pleaseProvideAFileWithDatasetTemplate",
  pleaseProvideMessageReason: "pleaseProvideMessageReason",
  pleaseRule: "pleaseRule",
  pleaseSamePassword: "pleaseSamePassword",
  pleaseSelect: "pleaseSelect",
  pleaseSelectALogic: "pleaseSelectALogic",
  pleaseSelectAProject: "pleaseSelectAProject",
  pleaseSelectAStructure: "pleaseSelectAStructure",
  pleaseSelectAnOperator: "pleaseSelectAnOperator",
  pleaseSelectAnOption: "pleaseSelectAnOption",
  pleaseSelectCatalogs: "pleaseSelectCatalogs",
  pleaseSelectOneModel: "pleaseSelectOneModel",
  pleaseSelectRole: "pleaseSelectRole",
  pleaseSelectThreshold: "pleaseSelectThreshold",
  pleaseSelectType: "pleaseSelectType",
  pleaseSomethingWrongApp: "pleaseSomethingWrongApp",
  pleaseSomethingWrongSignup: "pleaseSomethingWrongSignup",
  pleaseStrategyDescription: "pleaseStrategyDescription",
  pleaseStrategyTitle: "pleaseStrategyTitle",
  pleaseUploadCompounds: "pleaseUploadCompounds",
  pleaseUploadFileLess100MB: "pleaseUploadFileLess100MB",
  pleaseUserDontExists: "pleaseUserDontExists",
  pleaseUserExists: "pleaseUserExists",
  pleaseValidCode: "pleaseValidCode",
  pleaseValidEmail: "pleaseValidEmail",
  pleaseValidFamilyName: "pleaseValidFamilyName",
  pleaseValidFolderName: "pleaseValidFolderName",
  pleaseValidFullName: "pleaseValidFullName",
  pleaseValidGivenName: "pleaseValidGivenName",
  pleaseValidMessage: "pleaseValidMessage",
  pleaseValidName: "pleaseValidName",
  pleaseValidNumber: "pleaseValidNumber",
  pleaseValidPassword: "pleaseValidPassword",
  pleaseValidPropertyName: "pleaseValidPropertyName",
  pleaseValidStructure: "pleaseValidStructure",
  pleaseValidTeamName: "pleaseValidTeamName",
  pleaseValidUnits: "pleaseValidUnits",
  pleaseWait: "pleaseWait",
  plusMore: "plusMore",
  popConfirmAddProjectText: "popConfirmAddProjectText",
  popConfirmRemoveProjectText: "popConfirmRemoveProjectText",
  positive: "positive",
  predictCompounds: "predictCompounds",
  predictFrom: "predictFrom",
  predictedProperties: "predictedProperties",
  preparingImage: "preparingImage",
  preview: "preview",
  previewPlatformV2: "previewPlatformV2",
  previewReport: "previewReport",
  previewResults: "previewResults",
  previous: "previous",
  previousMolecule: "previousMolecule",
  previousStep: "previousStep",
  priceAndLeadTimeVary: "priceAndLeadTimeVary",
  priceAndLeadtimeContext: "priceAndLeadtimeContext",
  priceAndLeadtimeContextSimple: "priceAndLeadtimeContextSimple",
  priceDescription: "priceDescription",
  priceMayVary: "priceMayVary",
  priceMayVaryTooltip: "priceMayVaryTooltip",
  pricePerMilligram: "pricePerMilligram",
  priority: "priority",
  priorityHigh: "priorityHigh",
  priorityLow: "priorityLow",
  priorityNeutral: "priorityNeutral",
  priorityUnreviewed: "priorityUnreviewed",
  profileInformation: "profileInformation",
  profileSettings: "profileSettings",
  progressSaved: "progressSaved",
  project: "project",
  projectAddedNotificationDescription: "projectAddedNotificationDescription",
  projectAddedNotificationTitle: "projectAddedNotificationTitle",
  projectAddedSuccessfully: "projectAddedSuccessfully",
  projectAlreadyExists: "projectAlreadyExists",
  projectDeletedSuccessfully: "projectDeletedSuccessfully",
  projectDescription: "projectDescription",
  projectOneMemberInviteMember: "projectOneMemberInviteMember",
  projectSavedSuccessfully: "projectSavedSuccessfully",
  projects: "projects",
  projectsCount: "projectsCount",
  projectsOverview: "projectsOverview",
  projectsPageDescription: "projectsPageDescription",
  projectsPageTitle: "projectsPageTitle",
  projectsRemovedSuccessfully: "projectsRemovedSuccessfully",
  propertyThresoldHI: "propertyThresoldHI",
  propertyThresoldHW: "propertyThresoldHW",
  propertyThresoldJW: "propertyThresoldJW",
  propertyThresoldLO: "propertyThresoldLO",
  propertyThresoldLW: "propertyThresoldLW",
  provideStructuresToSearchWith: "provideStructuresToSearchWith",
  providers: "providers",
  public: "public",
  purchaseAvailability: "purchaseAvailability",
  quickActions: "quickActions",
  quickView: "quickView",
  rangeIsInvalid: "rangeIsInvalid",
  rank: "rank",
  rawQuery: "rawQuery",
  readouts: "readouts",
  reason: "reason",
  recentChemicalSearches: "recentChemicalSearches",
  recentFiles: "recentFiles",
  recentFilesDescription: "recentFilesDescription",
  recentStrategies: "recentStrategies",
  recentStructures: "recentStructures",
  redirectToHomePageOfNextOrg: "redirectToHomePageOfNextOrg",
  refresh: "refresh",
  refreshDashboards: "refreshDashboards",
  refreshError: "refreshError",
  refreshedData: "refreshedData",
  refreshingData: "refreshingData",
  reinitialize: "reinitialize",
  relatedProject: "relatedProject",
  relayout: "relayout",
  remove: "remove",
  removeInvalidStructures: "removeInvalidStructures",
  removeStatement: "removeStatement",
  removeStrategy: "removeStrategy",
  removeSuggestion: "removeSuggestion",
  removingSuggestion: "removingSuggestion",
  rename: "rename",
  renderingError: "renderingError",
  reportUploadSuccessful: "reportUploadSuccessful",
  requestLimitIncrease: "requestLimitIncrease",
  requestedFileDoesNotExist: "requestedFileDoesNotExist",
  research: "research",
  resendCode: "resendCode",
  reset: "reset",
  resetAllFilters: "resetAllFilters",
  resetFilters: "resetFilters",
  resetPassword: "resetPassword",
  resetPasswordButton: "resetPasswordButton",
  resetReview: "resetReview",
  resetUserPassword: "resetUserPassword",
  restErrorAddingCompoundLibraryDescription:
    "restErrorAddingCompoundLibraryDescription",
  restErrorAddingCompoundLibraryTitle: "restErrorAddingCompoundLibraryTitle",
  restErrorAddingMemberDescription: "restErrorAddingMemberDescription",
  restErrorAddingMemberTitle: "restErrorAddingMemberTitle",
  restErrorAddingOrganizationDescription:
    "restErrorAddingOrganizationDescription",
  restErrorAddingOrganizationTitle: "restErrorAddingOrganizationTitle",
  restErrorAddingProjectDescription: "restErrorAddingProjectDescription",
  restErrorAddingProjectTitle: "restErrorAddingProjectTitle",
  restErrorAddingStrategyDescription: "restErrorAddingStrategyDescription",
  restErrorAddingStrategyTitle: "restErrorAddingStrategyTitle",
  restErrorCreateStrategyDescription: "restErrorCreateStrategyDescription",
  restErrorCreateStrategyTitle: "restErrorCreateStrategyTitle",
  restErrorDatasetUploadDescription: "restErrorDatasetUploadDescription",
  restErrorDatasetUploadTitle: "restErrorDatasetUploadTitle",
  restErrorDeletingChemicalFilterDescription:
    "restErrorDeletingChemicalFilterDescription",
  restErrorDeletingChemicalFilterTitle: "restErrorDeletingChemicalFilterTitle",
  restErrorDeletingCompoundLibrariesDescription:
    "restErrorDeletingCompoundLibrariesDescription",
  restErrorDeletingCompoundLibrariesTitle:
    "restErrorDeletingCompoundLibrariesTitle",
  restErrorDeletingMemberDescription: "restErrorDeletingMemberDescription",
  restErrorDeletingMemberTitle: "restErrorDeletingMemberTitle",
  restErrorDeletingOrganizationDescription:
    "restErrorDeletingOrganizationDescription",
  restErrorDeletingOrganizationTitle: "restErrorDeletingOrganizationTitle",
  restErrorDeletingProjectsDescription: "restErrorDeletingProjectsDescription",
  restErrorDeletingProjectsTitle: "restErrorDeletingProjectsTitle",
  restErrorDeletingStrategiesDescription:
    "restErrorDeletingStrategiesDescription",
  restErrorDeletingStrategiesTitle: "restErrorDeletingStrategiesTitle",
  restErrorDeletingUserDescription: "restErrorDeletingUserDescription",
  restErrorDeletingUserTitle: "restErrorDeletingUserTitle",
  restErrorEditingCompoundLibraryDescription:
    "restErrorEditingCompoundLibraryDescription",
  restErrorEditingCompoundLibraryTitle: "restErrorEditingCompoundLibraryTitle",
  restErrorEditingMemberDescription: "restErrorEditingMemberDescription",
  restErrorEditingMemberTitle: "restErrorEditingMemberTitle",
  restErrorEditingOrganizationDescription:
    "restErrorEditingOrganizationDescription",
  restErrorEditingOrganizationTitle: "restErrorEditingOrganizationTitle",
  restErrorFetchingPatents: "restErrorFetchingPatents",
  restErrorPerformingActionDescription: "restErrorPerformingActionDescription",
  restErrorPerformingActionTitle: "restErrorPerformingActionTitle",
  restErrorResultRetry: "restErrorResultRetry",
  restErrorRouteFeedbackDescription: "restErrorRouteFeedbackDescription",
  restErrorRouteFeedbackTitle: "restErrorRouteFeedbackTitle",
  restErrorSavingProgressDescription: "restErrorSavingProgressDescription",
  restErrorSavingProgressTitle: "restErrorSavingProgressTitle",
  restErrorSavingProjectDescription: "restErrorSavingProjectDescription",
  restErrorSavingProjectTitle: "restErrorSavingProjectTitle",
  restErrorScoringTaskExistsDescription:
    "restErrorScoringTaskExistsDescription",
  restErrorScoringTaskSubmissionDescription:
    "restErrorScoringTaskSubmissionDescription",
  restErrorSubmittingReviewDescription: "restErrorSubmittingReviewDescription",
  restErrorSubmittingReviewTitle: "restErrorSubmittingReviewTitle",
  restErrorUploadReportDescription: "restErrorUploadReportDescription",
  restErrorUploadReportTitle: "restErrorUploadReportTitle",
  restErrorUserFeedbackDescription: "restErrorUserFeedbackDescription",
  restErrorUserFeedbackTitle: "restErrorUserFeedbackTitle",
  restore: "restore",
  restrict: "restrict",
  restricted: "restricted",
  restrictedNoMembers: "restrictedNoMembers",
  resubmit: "resubmit",
  resubmitDataset: "resubmitDataset",
  results: "results",
  resultsNotYetAvailable: "resultsNotYetAvailable",
  retrosynthesis: "retrosynthesis",
  retrosynthesisScenarioDescription: "retrosynthesisScenarioDescription",
  retry: "retry",
  returnToMoleculeReview: "returnToMoleculeReview",
  reuse: "reuse",
  review: "review",
  reviewActions: "reviewActions",
  reviewComment: "reviewComment",
  reviewCommentHelp: "reviewCommentHelp",
  reviewComments: "reviewComments",
  reviewCompounds: "reviewCompounds",
  reviewNavigationHelp_groupActionsTitle:
    "reviewNavigationHelp_groupActionsTitle",
  reviewNavigationHelp_groupActions_controlShift:
    "reviewNavigationHelp_groupActions_controlShift",
  reviewNavigationHelp_groupActions_dropdown:
    "reviewNavigationHelp_groupActions_dropdown",
  reviewNavigationHelp_groupActions_selectAll:
    "reviewNavigationHelp_groupActions_selectAll",
  reviewNavigationHelp_groupActions_selectMultiple:
    "reviewNavigationHelp_groupActions_selectMultiple",
  reviewNavigationHelp_groupActions_shortcuts:
    "reviewNavigationHelp_groupActions_shortcuts",
  reviewNavigationHelp_reviewActionsTitle:
    "reviewNavigationHelp_reviewActionsTitle",
  reviewNavigationHelp_reviewActions_addComment:
    "reviewNavigationHelp_reviewActions_addComment",
  reviewNavigationHelp_reviewActions_review:
    "reviewNavigationHelp_reviewActions_review",
  reviewNavigationHelp_reviewActions_saveProgress:
    "reviewNavigationHelp_reviewActions_saveProgress",
  reviewNavigationHelp_reviewActions_submitReview:
    "reviewNavigationHelp_reviewActions_submitReview",
  reviewNavigationHelp_structureActionsTitle:
    "reviewNavigationHelp_structureActionsTitle",
  reviewNavigationHelp_structureActions_rowActions:
    "reviewNavigationHelp_structureActions_rowActions",
  reviewNavigationHelp_structureActions_structure:
    "reviewNavigationHelp_structureActions_structure",
  reviewNavigationHelp_structureActions_zoom:
    "reviewNavigationHelp_structureActions_zoom",
  reviewNavigationHelp_tableActionsTitle:
    "reviewNavigationHelp_tableActionsTitle",
  reviewNavigationHelp_tableActions_filters:
    "reviewNavigationHelp_tableActions_filters",
  reviewNavigationHelp_tableActions_tableTop:
    "reviewNavigationHelp_tableActions_tableTop",
  reviewResults: "reviewResults",
  reviewScore: "reviewScore",
  reviewStatusDetailed_CO: "reviewStatusDetailed_CO",
  reviewStatusDetailed_IP: "reviewStatusDetailed_IP",
  reviewStatusDetailed_NS: "reviewStatusDetailed_NS",
  reviewStatus_CO: "reviewStatus_CO",
  reviewStatus_IP: "reviewStatus_IP",
  reviewStatus_NS: "reviewStatus_NS",
  reviewStrategy: "reviewStrategy",
  reviewSubmission: "reviewSubmission",
  reviewSubmitted: "reviewSubmitted",
  reviewSummary: "reviewSummary",
  reviewType: "reviewType",
  reviewTypeDescription_multiple: "reviewTypeDescription_multiple",
  reviewTypeDescription_team: "reviewTypeDescription_team",
  reviewType_multiple: "reviewType_multiple",
  reviewType_team: "reviewType_team",
  reviewers: "reviewers",
  reviewersCandidatesHelp: "reviewersCandidatesHelp",
  reviewersFilter: "reviewersFilter",
  reviewersPlural: "reviewersPlural",
  reviews: "reviews",
  reviewsWithDeletedUser: "reviewsWithDeletedUser",
  revisionUploadFailed: "revisionUploadFailed",
  revisionUploadFailedDescription: "revisionUploadFailedDescription",
  revisionsHistory: "revisionsHistory",
  revisionsNotice: "revisionsNotice",
  role: "role",
  roleAD: "roleAD",
  roleCO: "roleCO",
  roleDM: "roleDM",
  roleDescriptionAD: "roleDescriptionAD",
  roleDescriptionCO: "roleDescriptionCO",
  roleDescriptionDM: "roleDescriptionDM",
  roleDescriptionNone: "roleDescriptionNone",
  roleDescriptionST: "roleDescriptionST",
  roleRestrictedPage: "roleRestrictedPage",
  roleST: "roleST",
  roleSuper: "roleSuper",
  route: "route",
  routeFeedbackDeletedSuccessfully: "routeFeedbackDeletedSuccessfully",
  routeFeedbackUpdatedSuccessfully: "routeFeedbackUpdatedSuccessfully",
  routeFeedbacksPlural: "routeFeedbacksPlural",
  routeIsSolved: "routeIsSolved",
  routeIsUnsolved: "routeIsUnsolved",
  routes: "routes",
  row: "row",
  save: "save",
  saveLimits: "saveLimits",
  saveName: "saveName",
  saveProgress: "saveProgress",
  saveProgressKeyboard: "saveProgressKeyboard",
  saved: "saved",
  saving: "saving",
  scenarioApplied: "scenarioApplied",
  scenarioLaunched: "scenarioLaunched",
  schedule: "schedule",
  score: "score",
  scoreCompound: "scoreCompound",
  scoreCompounds: "scoreCompounds",
  scoreFrom: "scoreFrom",
  scoreNewCompounds: "scoreNewCompounds",
  scoredMoleculeslimitExceededDescription:
    "scoredMoleculeslimitExceededDescription",
  scoredMoleculeslimitExceededTitle: "scoredMoleculeslimitExceededTitle",
  scoring: "scoring",
  scoringCompounds: "scoringCompounds",
  scoringConfigUpdateSuccess: "scoringConfigUpdateSuccess",
  scoringConfiguration: "scoringConfiguration",
  scoringPageTitle: "scoringPageTitle",
  scoringTaskHistoryDescription: "scoringTaskHistoryDescription",
  scoringTaskHistoryTitle: "scoringTaskHistoryTitle",
  search: "search",
  searchActivities: "searchActivities",
  searchById: "searchById",
  searchByName: "searchByName",
  searchBySmiles: "searchBySmiles",
  searchByType: "searchByType",
  searchByUser: "searchByUser",
  searchChemicalFilters: "searchChemicalFilters",
  searchChemicalSpace: "searchChemicalSpace",
  searchChemicalSpaceModeHelp_editor: "searchChemicalSpaceModeHelp_editor",
  searchChemicalSpaceModeHelp_text: "searchChemicalSpaceModeHelp_text",
  searchChemicalSpaceMode_editor: "searchChemicalSpaceMode_editor",
  searchChemicalSpaceMode_text: "searchChemicalSpaceMode_text",
  searchChemicalTypeHelp_exact: "searchChemicalTypeHelp_exact",
  searchChemicalTypeHelp_similarity: "searchChemicalTypeHelp_similarity",
  searchChemicalTypeHelp_substruct: "searchChemicalTypeHelp_substruct",
  searchChemicalType_exact: "searchChemicalType_exact",
  searchChemicalType_exact_search: "searchChemicalType_exact_search",
  searchChemicalType_similarity: "searchChemicalType_similarity",
  searchChemicalType_similarity_search: "searchChemicalType_similarity_search",
  searchChemicalType_substruct: "searchChemicalType_substruct",
  searchChemicalType_substruct_search: "searchChemicalType_substruct_search",
  searchConfiguration: "searchConfiguration",
  searchError: "searchError",
  searchExactOrSub: "searchExactOrSub",
  searchFilesAndFolders: "searchFilesAndFolders",
  searchFilters: "searchFilters",
  searchFrom: "searchFrom",
  searchHistory: "searchHistory",
  searchInvalid: "searchInvalid",
  searchOrganizationMolecules: "searchOrganizationMolecules",
  searchResults: "searchResults",
  searchResultsAppearHere: "searchResultsAppearHere",
  searchStatus: "searchStatus",
  searchSuccess: "searchSuccess",
  seeAllCatalogs: "seeAllCatalogs",
  seeAllFeedbacks: "seeAllFeedbacks",
  seeCompoundDetails: "seeCompoundDetails",
  seeGroup: "seeGroup",
  seeLogs: "seeLogs",
  seeMyReview: "seeMyReview",
  seeReference: "seeReference",
  seeUnreviewedCompounds: "seeUnreviewedCompounds",
  select: "select",
  selectAType: "selectAType",
  selectAlert: "selectAlert",
  selectAll: "selectAll",
  selectCDDVaultState: "selectCDDVaultState",
  selectDescriptor: "selectDescriptor",
  selectDescriptors: "selectDescriptors",
  selectFile: "selectFile",
  selectFileForScoring: "selectFileForScoring",
  selectFileFromDrive: "selectFileFromDrive",
  selectFilter: "selectFilter",
  selectFolder: "selectFolder",
  selectFolderFromDrive: "selectFolderFromDrive",
  selectFunctionalGroup: "selectFunctionalGroup",
  selectModelsForScoring: "selectModelsForScoring",
  selectOption: "selectOption",
  selectProjectForScoring: "selectProjectForScoring",
  selectRelatedProject: "selectRelatedProject",
  selectRole: "selectRole",
  selectRule: "selectRule",
  selectStrategyType: "selectStrategyType",
  selectStructure: "selectStructure",
  selectTeamMembersOrGroups: "selectTeamMembersOrGroups",
  selectThresold: "selectThresold",
  selectedStructure: "selectedStructure",
  selectionConfig: "selectionConfig",
  sendCode: "sendCode",
  sendReportToPartners: "sendReportToPartners",
  services: "services",
  servicesPageDescription: "servicesPageDescription",
  servicesPageTitle: "servicesPageTitle",
  session: "session",
  setAsCurrent: "setAsCurrent",
  settings: "settings",
  shareFile: "shareFile",
  shareFileURLCopied: "shareFileURLCopied",
  showSuggested: "showSuggested",
  showingCompounds: "showingCompounds",
  shown: "shown",
  signIn: "signIn",
  signInTagLine: "signInTagLine",
  signOut: "signOut",
  signUp: "signUp",
  signUpToCircus: "signUpToCircus",
  signupComplete: "signupComplete",
  signupCompleteWelcome: "signupCompleteWelcome",
  signupSuccessful: "signupSuccessful",
  similarity: "similarity",
  similarityScore: "similarityScore",
  similarityScoreDescription: "similarityScoreDescription",
  similaritySearch: "similaritySearch",
  similaritySearchResultsForAllMolecules:
    "similaritySearchResultsForAllMolecules",
  similaritySearchResultsForCurrentMolecule:
    "similaritySearchResultsForCurrentMolecule",
  similaritySearchThreshold: "similaritySearchThreshold",
  similaritySearchThresholdDescription: "similaritySearchThresholdDescription",
  size: "size",
  small: "small",
  smartsSearch: "smartsSearch",
  smartsSearchScenarioDescription: "smartsSearchScenarioDescription",
  smiles: "smiles",
  smilesColumn: "smilesColumn",
  smilesCopied: "smilesCopied",
  smilesList: "smilesList",
  solved: "solved",
  solvedRouteDescription: "solvedRouteDescription",
  someNegativeScore: "someNegativeScore",
  someNeutralScore: "someNeutralScore",
  somePositiveScore: "somePositiveScore",
  someReviewsAreFiltered: "someReviewsAreFiltered",
  someStructuresInvalid: "someStructuresInvalid",
  someUnreviewedScore: "someUnreviewedScore",
  somethingWentWrong: "somethingWentWrong",
  somethingWentWrongContactUs: "somethingWentWrongContactUs",
  sort: "sort",
  sortAscending: "sortAscending",
  sortBy: "sortBy",
  sortDescending: "sortDescending",
  "sortType_alerts.length": "sortType_alerts.length",
  "sortType_descriptors.crippen_clog_p": "sortType_descriptors.crippen_clog_p",
  "sortType_descriptors.fraction_csp3": "sortType_descriptors.fraction_csp3",
  "sortType_descriptors.molecular_weight":
    "sortType_descriptors.molecular_weight",
  "sortType_descriptors.num_hba": "sortType_descriptors.num_hba",
  "sortType_descriptors.num_hbd": "sortType_descriptors.num_hbd",
  "sortType_descriptors.num_rings": "sortType_descriptors.num_rings",
  "sortType_descriptors.qed": "sortType_descriptors.qed",
  sortType_is_commercially_available: "sortType_is_commercially_available",
  sortType_score: "sortType_score",
  source: "source",
  startDate: "startDate",
  startEnterEmailAddress: "startEnterEmailAddress",
  startReview: "startReview",
  startSearch: "startSearch",
  statementType: "statementType",
  statementType_AL: "statementType_AL",
  statementType_CH: "statementType_CH",
  statementType_DE: "statementType_DE",
  statementType_GR: "statementType_GR",
  statementType_RU: "statementType_RU",
  statementType_ST: "statementType_ST",
  statementType_SU: "statementType_SU",
  status: "status",
  statusPage: "statusPage",
  steps: "steps",
  strategies: "strategies",
  strategiesCount: "strategiesCount",
  strategiesPageDescription: "strategiesPageDescription",
  strategiesPageTitle: "strategiesPageTitle",
  strategiesRemovedSuccessfully: "strategiesRemovedSuccessfully",
  strategiestateAP: "strategiestateAP",
  strategiestateER: "strategiestateER",
  strategiestateRE: "strategiestateRE",
  strategiestateRS: "strategiestateRS",
  strategy: "strategy",
  strategyAddedSuccessfully: "strategyAddedSuccessfully",
  strategyAlreadyExists: "strategyAlreadyExists",
  strategyData: "strategyData",
  strategyDetails: "strategyDetails",
  strategyHasBeenReviewed: "strategyHasBeenReviewed",
  strategyModelVersionsUpdatedSuccessfully:
    "strategyModelVersionsUpdatedSuccessfully",
  strategyOneMemberInOrg: "strategyOneMemberInOrg",
  strategyOneMemberInviteMember: "strategyOneMemberInviteMember",
  strategyReviewDescription: "strategyReviewDescription",
  strategyReviewTitle: "strategyReviewTitle",
  strategyRoles: "strategyRoles",
  strategyType: "strategyType",
  strategyTypeGN: "strategyTypeGN",
  strategyTypeOP: "strategyTypeOP",
  strategyTypeSC: "strategyTypeSC",
  strategyalData: "strategyalData",
  structural: "structural",
  structure: "structure",
  structureAdded: "structureAdded",
  structureAlignment: "structureAlignment",
  structureAlreadyExist: "structureAlreadyExist",
  structureForFilteringWillAppear: "structureForFilteringWillAppear",
  structureInformation: "structureInformation",
  structureMissing: "structureMissing",
  structurePlural: "structurePlural",
  structureRemoved: "structureRemoved",
  structureSimilarity: "structureSimilarity",
  structureSortInfo: "structureSortInfo",
  structures: "structures",
  structuresForSearchWillAppear: "structuresForSearchWillAppear",
  structuresList: "structuresList",
  submit: "submit",
  submitComment: "submitComment",
  submitCompoundForPrediction: "submitCompoundForPrediction",
  submitCompoundForSuggestion: "submitCompoundForSuggestion",
  submitCompoundsForPrediction: "submitCompoundsForPrediction",
  submitCompoundsForSuggestion: "submitCompoundsForSuggestion",
  submitDataset: "submitDataset",
  submitFile: "submitFile",
  submitForPrediction: "submitForPrediction",
  submitForSuggestion: "submitForSuggestion",
  submitForVisualization: "submitForVisualization",
  submitNewCompounds: "submitNewCompounds",
  submitNewFile: "submitNewFile",
  submitNewSetOfCompounds: "submitNewSetOfCompounds",
  submitReview: "submitReview",
  submitReviewAnyway: "submitReviewAnyway",
  submittingCompounds: "submittingCompounds",
  substructureSearch: "substructureSearch",
  substructureSearchResultsForAllMolecules:
    "substructureSearchResultsForAllMolecules",
  substructureSearchResultsForCurrentMolecule:
    "substructureSearchResultsForCurrentMolecule",
  suggest: "suggest",
  suggestCompound: "suggestCompound",
  suggestCompounds: "suggestCompounds",
  suggestFrom: "suggestFrom",
  suggestNewCompounds: "suggestNewCompounds",
  suggested: "suggested",
  suggestedBy: "suggestedBy",
  suggestedMoleculeFileSupport: "suggestedMoleculeFileSupport",
  suggestionCommentHelp: "suggestionCommentHelp",
  suggestionRemoved: "suggestionRemoved",
  suggestionsSaved: "suggestionsSaved",
  superUser: "superUser",
  superUserStaff: "superUserStaff",
  superUserSuper: "superUserSuper",
  supportsSingleFileOrBulk: "supportsSingleFileOrBulk",
  swagger: "swagger",
  switch: "switch",
  switchOrganization: "switchOrganization",
  switchStructure: "switchStructure",
  switchTo: "switchTo",
  switchToAdmin: "switchToAdmin",
  switchToOrganization: "switchToOrganization",
  switchToStaff: "switchToStaff",
  syncCDDVault: "syncCDDVault",
  syncCDDVaultDisabledDescription: "syncCDDVaultDisabledDescription",
  synthesis: "synthesis",
  synthesisRoutes: "synthesisRoutes",
  tableView: "tableView",
  tagName: "tagName",
  tags: "tags",
  tanimotoThreshold: "tanimotoThreshold",
  taskStateCR: "taskStateCR",
  taskStateER: "taskStateER",
  taskStateFA: "taskStateFA",
  taskStateIN: "taskStateIN",
  taskStateMI: "taskStateMI",
  taskStatePD: "taskStatePD",
  taskStateRU: "taskStateRU",
  taskStateSB: "taskStateSB",
  taskStateSU: "taskStateSU",
  taskStateUnknown: "taskStateUnknown",
  taskTypeLabel_dataprocess: "taskTypeLabel_dataprocess",
  taskTypeLabel_descriptors: "taskTypeLabel_descriptors",
  taskTypeLabel_distributedscoring: "taskTypeLabel_distributedscoring",
  taskTypeLabel_featurize: "taskTypeLabel_featurize",
  taskTypeLabel_modelanalysis: "taskTypeLabel_modelanalysis",
  taskTypeLabel_modelselection: "taskTypeLabel_modelselection",
  taskTypeLabel_scoring: "taskTypeLabel_scoring",
  tasks: "tasks",
  teamDrive: "teamDrive",
  teamName: "teamName",
  teamReview: "teamReview",
  templateAlreadyExists: "templateAlreadyExists",
  templateData: "templateData",
  templateRemovedSuccessfully: "templateRemovedSuccessfully",
  templates: "templates",
  termsOfService: "termsOfService",
  text: "text",
  thankYou: "thankYou",
  thankYouForFeedback: "thankYouForFeedback",
  thankYouForYourRouteFeedback: "thankYouForYourRouteFeedback",
  title: "title",
  toggleLegend: "toggleLegend",
  toggleOrganizations: "toggleOrganizations",
  toggleServices: "toggleServices",
  toggleUsers: "toggleUsers",
  tooManyValues: "tooManyValues",
  totalCompounds: "totalCompounds",
  totalCompoundsSubmitted: "totalCompoundsSubmitted",
  totalCompoundsSuggested: "totalCompoundsSuggested",
  totalLibraries: "totalLibraries",
  totalRunCount: "totalRunCount",
  trainingMolecules: "trainingMolecules",
  trainingMoleculesCollapse: "trainingMoleculesCollapse",
  transformerData: "transformerData",
  transformerInitialValue: "transformerInitialValue",
  trash: "trash",
  trashed: "trashed",
  turnOff: "turnOff",
  turnOn: "turnOn",
  type: "type",
  typeSomethingAndPressEnter: "typeSomethingAndPressEnter",
  unableToDownloadSearchResults: "unableToDownloadSearchResults",
  unableToFindStructure: "unableToFindStructure",
  unableToReadFileFormat: "unableToReadFileFormat",
  unauthorizedPageAction: "unauthorizedPageAction",
  unauthorizedPageTitle: "unauthorizedPageTitle",
  unavailable: "unavailable",
  undo: "undo",
  units: "units",
  unrestricted: "unrestricted",
  unsavedChanges: "unsavedChanges",
  unset: "unset",
  unsolved: "unsolved",
  update: "update",
  updateReadouts: "updateReadouts",
  updatedBy: "updatedBy",
  upload: "upload",
  uploadDataToStart: "uploadDataToStart",
  uploadFiles: "uploadFiles",
  uploadInstructions: "uploadInstructions",
  uploadInstructionsSubtitle: "uploadInstructionsSubtitle",
  uploadResults: "uploadResults",
  uploadRevision: "uploadRevision",
  uploadingRevision: "uploadingRevision",
  uploadingSubmission: "uploadingSubmission",
  uploadingSubmissionDescription: "uploadingSubmissionDescription",
  upperLimit: "upperLimit",
  useAsTableFilter: "useAsTableFilter",
  useTemplate: "useTemplate",
  user: "user",
  userAddedSuccessfully: "userAddedSuccessfully",
  userAlreadyInOrganization: "userAlreadyInOrganization",
  userEditedSuccessfully: "userEditedSuccessfully",
  userHasNoOrganization: "userHasNoOrganization",
  userInformation: "userInformation",
  userPwdReset: "userPwdReset",
  userRemovedSuccessfully: "userRemovedSuccessfully",
  userSince: "userSince",
  users: "users",
  usersPageDescription: "usersPageDescription",
  usersPageTitle: "usersPageTitle",
  validPasswordLengthConstraint: "validPasswordLengthConstraint",
  validatingCompounds: "validatingCompounds",
  verified: "verified",
  verifiedTooltip: "verifiedTooltip",
  version: "version",
  versions: "versions",
  view: "view",
  viewAll: "viewAll",
  viewAllChemicalSearches: "viewAllChemicalSearches",
  viewAllLibraries: "viewAllLibraries",
  viewAllMembers: "viewAllMembers",
  viewAllOrganizations: "viewAllOrganizations",
  viewAllProjects: "viewAllProjects",
  viewAllStrategies: "viewAllStrategies",
  viewBlocks: "viewBlocks",
  viewCatalogs: "viewCatalogs",
  viewComment: "viewComment",
  viewDetails: "viewDetails",
  viewExamples: "viewExamples",
  viewInCDD: "viewInCDD",
  viewProject: "viewProject",
  viewProjectPageDescription: "viewProjectPageDescription",
  viewProjectPageTitle: "viewProjectPageTitle",
  viewProjectSubmissions: "viewProjectSubmissions",
  viewReport: "viewReport",
  viewStrategy: "viewStrategy",
  viewStrategyDescription: "viewStrategyDescription",
  viewTableFullScreen: "viewTableFullScreen",
  viewerDescription: "viewerDescription",
  viewerLabel: "viewerLabel",
  visible: "visible",
  visibleColumns: "visibleColumns",
  visibleToAll: "visibleToAll",
  visualization: "visualization",
  visualizeChemicalSpace: "visualizeChemicalSpace",
  visualizeChemicalSpaceNA: "visualizeChemicalSpaceNA",
  visualizedReadout: "visualizedReadout",
  wantMoreResults: "wantMoreResults",
  warning: "warning",
  warningMoleculeScoring: "warningMoleculeScoring",
  warningRemovingReviewers: "warningRemovingReviewers",
  warningResubmit: "warningResubmit",
  warningResubmitGenerated: "warningResubmitGenerated",
  warningUnreviewedCompounds: "warningUnreviewedCompounds",
  warningUpload: "warningUpload",
  weekDays: "weekDays",
  weeks: "weeks",
  welcomeName: "welcomeName",
  welcomeUser: "welcomeUser",
  willGetBackToYouShortly: "willGetBackToYouShortly",
  workflowDesign: "workflowDesign",
  workspace: "workspace",
  workspaceSessionLoading: "workspaceSessionLoading",
  xlsxFileSupport: "xlsxFileSupport",
  yes: "yes",
  youAreAboutToCreateYourStrategy: "youAreAboutToCreateYourStrategy",
  youAreAboutToCreateYourStrategyDescription:
    "youAreAboutToCreateYourStrategyDescription",
  yourOrganizations: "yourOrganizations",
  zero: "zero"
};
