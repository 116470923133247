const UNNAVIGABLE = "UNNAVIGABLE";

const Routes = {
  root: {
    path: "/",
    key: ""
  },
  home: {
    path: "/home",
    key: "home"
  },
  swagger: {
    path: "/swagger",
    key: "swagger"
  },
  activityTracking: {
    path: "/activity-tracking",
    key: "activity"
  },
  profileSettings: {
    path: "/profile-settings",
    key: "profile"
  },
  services: {
    path: "/services",
    key: "service",
    superUser: true
  },
  organizationSettings: {
    path: "/team-settings",
    key: "team-setting",
    superUser: true
  },
  organizations: {
    path: "/organizations",
    key: "organization",
    superUser: true
  },
  addOrganization: {
    path: "/add-organization",
    key: "organization",
    superUser: true
  },
  editOrganization: {
    path: "/edit-organization/:id",
    key: "organization",
    superUser: true
  },
  users: {
    path: "/users",
    key: "user",
    superUser: true
  },
  addSuperUser: {
    path: "/add-super-user",
    key: "user",
    superUser: true
  },
  periodicTasks: {
    path: "/periodic-tasks",
    key: "periodic-task"
  },
  moleculeEditorHelp: {
    path: "/molecule-editor-help",
    key: UNNAVIGABLE
  },
  featureFlags: {
    path: "/feature-flags",
    key: "feature-flag",
    superUser: true
  },
  addFeatureFlag: {
    path: "/add-feature-flag",
    key: "feature-flag",
    superUser: true
  },
  editFeatureFlag: {
    path: "/edit-feature-flag/:id",
    key: "feature-flag",
    superUser: true
  }
};

export default Routes;
