import { ServiceNames } from "@valencediscovery/kernel.services";
export const APP_NAME = (() => {
  if (process.env.REACT_APP_APP_NAME === ServiceNames.outline) {
    return "Outline™ by Valence Labs";
  } else if (process.env.REACT_APP_APP_NAME === ServiceNames.kernel) {
    return "Valence Labs Platform";
  } else if (process.env.REACT_APP_APP_NAME === ServiceNames.api) {
    return "Valence Labs REST API";
  } else if (process.env.REACT_APP_APP_NAME === ServiceNames.onboarding) {
    return "Valence Onboarding Platform";
  } else {
    return "Administration Platform";
  }
})();
