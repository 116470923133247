import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Layout, Typography, message, Modal } from "antd";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import Navigation from "../Navigation/Navigation";
import LocalesKeys from "@valencediscovery/kernel.locales";
import { getMyself, setRDKitState } from "@valencediscovery/kernel.store";
import _ from "lodash";
import { compose } from "lodash/fp";
import {
  withAppState,
  UserProfilePrompt,
  AppLoad,
  HeaderProfile,
  withRouter
} from "@valencediscovery/kernel.react";
import { Auth } from "aws-amplify";
import {
  Environment,
  ENV,
  getAPIRoot
} from "@valencediscovery/kernel.services";
import { setupHotjar } from "@valencediscovery/kernel.utils";
import Axios from "axios";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Routes from "../../Routes";
import "./AppLayout.scss";
import { APP_NAME } from "../../../../config";

class AppLayout extends Component {
  static CollapseState = {
    collapsed: "collapsed",
    expanded: "expanded"
  };
  static getMyself = _.once((callback) => callback());

  state = {
    collapsed: "collapsed"
  };

  toggleCollapsed = () => {
    const nextCollapsedState =
      this.state.collapsed === AppLayout.CollapseState.expanded
        ? AppLayout.CollapseState.collapsed
        : AppLayout.CollapseState.expanded;

    this.setState({
      collapsed: nextCollapsedState
    });
  };

  componentDidMount() {
    this.setupRDKit();

    this.setOrganizationIdQueryParam();

    if (ENV === Environment.prod) {
      setupHotjar();
    }
  }

  setupRDKit() {
    window
      .initRDKitModule()
      .then((instance) => {
        window.RDKit = instance;
        this.props.setRDKitState("LOADED");
      })
      .catch(() => {
        this.props.setRDKitState("ERROR");
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.authState === "signedIn") {
      AppLayout.getMyself(this.props.getMyself.bind(this));
    }

    if (this.props.location !== prevProps.location) {
      Modal.destroyAll();
    }
  }

  render() {
    const applicationLoadedSuccessfully = this.applicationLoadedSuccessfully();

    if (!_.includes(["loading", "signedIn"], this.props.authState)) {
      return this.redirectToSignIn();
    } else if (
      this.props.authState === "loading" ||
      !applicationLoadedSuccessfully
    ) {
      return <AppLoad appName={APP_NAME} />;
    } else if (
      applicationLoadedSuccessfully &&
      this.checkIfUserHasAccessToApp()
    ) {
      return this.getMainApplication();
    }

    Auth.signOut().then(() => {
      message
        .error(
          this.props.intl.formatMessage({
            id: LocalesKeys.applicationLoadFailed
          })
        )
        .then(() => window.location.reload(true));
    });

    return "Logging out...";
  }

  redirectToSignIn() {
    window.history.replaceState({}, "", "/");
    return null;
  }

  getMainApplication() {
    const hasIncompleteProfile = !this.props.user.userprofile.full_name;

    const incompleteProfilePrompt = (
      <div className="authentication-container">
        <div className="authentication-form">
          <h2 className="margin-bottom-2">
            {this.props.intl.formatMessage({
              id: LocalesKeys.completeProfileInformation
            })}
          </h2>
          <UserProfilePrompt
            buttonSize="large"
            extraButtonClasses="authentication-button margin-top-half"
          />
        </div>
      </div>
    );

    const mainApp = (
      <>
        <Layout.Header className="app-header">
          <Button
            className="navigation-toggle"
            type="default"
            size="small"
            onClick={this.toggleCollapsed}
          >
            {this.state.collapsed === AppLayout.CollapseState.collapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined />
            )}
          </Button>
          <Typography.Text className="header-text">{APP_NAME}</Typography.Text>
          <div className="spacer"></div>
          <HeaderProfile
            profilePath={Routes.profileSettings.path}
            email={this.props.user.email}
          />
        </Layout.Header>
        <div className="app-container">
          <Navigation
            isCollapsed={
              this.state.collapsed === AppLayout.CollapseState.collapsed
            }
            user={this.props.user}
            flags={this.props.flags}
          />
          <div
            className="main-wrapper-hide"
            onClick={() => document.querySelector(".navigation-toggle").click()}
          ></div>
          {this.props.children}
        </div>
      </>
    );

    return hasIncompleteProfile ? incompleteProfilePrompt : mainApp;
  }

  applicationLoadedSuccessfully() {
    const applicationLoadedSuccessfully =
      this.props.authState === "signedIn" &&
      !!this.props.user &&
      (!!this.props.selectedOrganization || this.props.user.is_superuser) &&
      this.props.applicationLoaded;

    return applicationLoadedSuccessfully;
  }

  checkIfUserHasAccessToApp() {
    return this.props.user.is_superuser;
  }

  setOrganizationIdQueryParam = () => {
    Axios.interceptors.request.use((config) => {
      const isRequestToInVivoRestAPI = _.includes(config.url, getAPIRoot());
      if (isRequestToInVivoRestAPI) {
        const selectedOrganizationId = _.get(
          this.props,
          "selectedOrganization.organization_id",
          undefined
        );
        config.params = config.params || {};
        config.params["organization"] = selectedOrganizationId;
      }

      return Promise.resolve(config);
    });
  };
}

AppLayout.propTypes = {
  authState: PropTypes.string.isRequired,
  rootPath: PropTypes.string.isRequired,
  homePath: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({});

const onAppLoad = true;
const mapDispatchToProps = (dispatch) => ({
  getMyself: () =>
    dispatch(getMyself(onAppLoad, process.env.REACT_APP_APP_NAME)),
  setRDKitState: (state) => dispatch(setRDKitState(state))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withAppState,
  withRouter
)(AppLayout);
