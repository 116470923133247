import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./Navigation.scss";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "@valencediscovery/kernel.react";
import Routes from "../../Routes";
import { FormattedMessage, injectIntl } from "react-intl";
import LocalesKeys from "@valencediscovery/kernel.locales";
import { titleCase } from "title-case";
import {
  AuditOutlined,
  BankOutlined,
  CopyrightOutlined,
  FlagOutlined,
  RiseOutlined,
  AppstoreOutlined,
  ApiOutlined,
  ClockCircleOutlined
} from "@ant-design/icons";

const NavLink = ({ route, icon, messageId }) => (
  <Link className="white" to={route.path}>
    {icon}
    <span className="margin-left-half">
      <FormattedMessage
        id={messageId}
        children={([title]) => <span>{titleCase(title)}</span>}
      />
    </span>
  </Link>
);

const NavLinkSuperUser = ({ isSuperUser }) =>
  isSuperUser
    ? [
        {
          key: Routes.organizations.key,
          label: (
            <NavLink
              route={Routes.organizations}
              icon={<BankOutlined />}
              messageId={LocalesKeys.organizations}
            />
          )
        },
        {
          key: Routes.users.key,
          label: (
            <NavLink
              route={Routes.users}
              icon={<RiseOutlined />}
              messageId={LocalesKeys.users}
            />
          )
        },
        {
          key: Routes.services.key,
          label: (
            <NavLink
              route={Routes.services}
              icon={<AppstoreOutlined />}
              messageId={LocalesKeys.services}
            />
          )
        },
        {
          key: Routes.activityTracking.key,
          label: (
            <NavLink
              route={Routes.activityTracking}
              icon={<AuditOutlined />}
              messageId={LocalesKeys.activities}
            />
          )
        },
        {
          key: Routes.featureFlags.key,
          label: (
            <NavLink
              route={Routes.featureFlags}
              icon={<FlagOutlined />}
              messageId={LocalesKeys.featureFlags}
            />
          )
        },
        {
          key: Routes.swagger.key,
          label: (
            <NavLink
              route={Routes.swagger}
              icon={<ApiOutlined />}
              messageId={LocalesKeys.swagger}
            />
          )
        },
        {
          key: Routes.periodicTasks.key,
          label: (
            <NavLink
              route={Routes.periodicTasks}
              icon={<ClockCircleOutlined />}
              messageId={LocalesKeys.periodicTasksTitle}
            />
          )
        }
      ]
    : [];

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.helpForm = React.createRef();
  }

  state = {
    visible: false
  };

  render() {
    const { location, isCollapsed, user } = this.props;
    const superUserSections = _.chain(Routes)
      .filter((route) => route.superUser)
      .map((route) => route.path)
      .value();

    const openKeys =
      user.is_superuser &&
      _.includes(superUserSections, location.pathname) &&
      !isCollapsed
        ? ["superuser"]
        : [];

    const path = RegExp(
      location.pathname.split("/").filter(Boolean)[0] || Routes.home.key
    );
    const selectedKey = _.reduce(
      Routes,
      (selectedKey, route) =>
        RegExp(route.key).test(path) ? route.key : selectedKey,
      ""
    );

    return (
      <>
        <Menu
          defaultSelectedKeys={[Routes.home.path]}
          selectedKeys={[selectedKey]}
          defaultOpenKeys={openKeys}
          mode="inline"
          theme="dark"
          className="navigation-menu"
          inlineCollapsed={isCollapsed}
          items={[
            ...NavLinkSuperUser({
              isSuperUser: user.is_superuser
            }),
            {
              label: <div className="spacer" />
            },
            {
              type: "divider",
              className: "navigation-hide-collapsed"
            },
            {
              key: "7",
              className: "navigation-hide-collapsed navigation-copyright",
              disabled: true,
              icon: <CopyrightOutlined className="navigation-copyright-text" />,
              label: (
                <span className="navigation-copyright-text">
                  <FormattedMessage
                    id={LocalesKeys.copyright}
                    values={{ year: new Date().getFullYear() }}
                  />
                </span>
              )
            }
          ]}
        />
      </>
    );
  }
}

Navigation.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  flags: PropTypes.object.isRequired
};

const NavigationWithRouter = injectIntl(withRouter(Navigation));

export default NavigationWithRouter;
