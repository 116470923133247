import * as Sentry from "@sentry/react";
import { AppLoad } from "@valencediscovery/kernel.react";
import {
  ENV,
  Environment,
  getAPIRoot,
  getGAMetaData,
  setupGoogleAnalytics
} from "@valencediscovery/kernel.services";
import { Store } from "@valencediscovery/kernel.store";
import { Auth } from "aws-amplify";
import Axios from "axios";
import { StatusCodes } from "http-status-codes";
import _ from "lodash";
import React, { lazy, Suspense } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { APP_NAME } from "../../config";
import { AppContext } from "../../context";
import AppLayout from "./components/AppLayout/AppLayout";

const Services = lazy(() => import("../../pages/Services"));
const OrganizationSettings = lazy(() =>
  import("../../pages/OrganizationSettings")
);
const Organizations = lazy(() => import("../../pages/Organizations"));
const AddOrganization = lazy(() => import("../../pages/AddOrganization"));
const EditOrganization = lazy(() => import("../../pages/EditOrganization"));
const Users = lazy(() => import("../../pages/Users"));
const MoleculeEditorHelp = lazy(() => import("../../pages/MoleculeEditorHelp"));
const AddSuperUser = lazy(() => import("../../pages/AddSuperUser"));
const ProfileSettings = lazy(() => import("../../pages/ProfileSettings"));
const FeatureFlags = lazy(() => import("../../pages/FeatureFlags"));
const AddFeatureFlag = lazy(() => import("../../pages/AddFeatureFlag"));
const EditFeatureFlag = lazy(() => import("../../pages/EditFeatureFlag"));
const ActivityTracking = lazy(() => import("../../pages/ActivityTracking"));
const Swagger = lazy(() => import("../../pages/Swagger"));
const PeriodicTasks = lazy(() => import("../../pages/PeriodicTasks"));

class App extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.routerRef = React.createRef();
  }

  redirectHomeIfLoggedIn() {
    return (
      this.props.authState === "signedIn" && (
        <Navigate to={this.context.routes.home.path} />
      )
    );
  }

  isUserSignedIn() {
    return this.props.authState === "signedIn";
  }

  redirectIfNotLoggedIn(Component) {
    return this.isUserSignedIn() ? (
      <Component />
    ) : (
      <Navigate exact to={this.context.routes.root.path} />
    );
  }

  setupAPIHeaders() {
    Axios.defaults.headers.common["Content-Type"] = "application/json";
  }

  setupAuthenticationAndTokenRefresh() {
    Axios.interceptors.request.use(
      (config) => {
        // Token Refresh logic taken from https://github.com/aws-amplify/amplify-js/issues/446#issuecomment-389384338
        return Auth.currentSession()
          .then((session) => {
            if (_.includes(config.url, getAPIRoot())) {
              // User is logged in. Set auth header on all requests to our API
              config.headers[
                "Authorization"
              ] = `bearer ${session.idToken.jwtToken}`;
            }

            return Promise.resolve(config);
          })
          .catch(() => {
            if (_.includes(config.url, getAPIRoot())) {
              // Force logout for unauthenticated user trying to query our API.
              Auth.signOut();
              window.location.reload(true);
            } else {
              return Promise.resolve(config);
            }
          });
      },
      function (error) {
        if (ENV !== Environment.local) {
          Sentry.captureException(error);
        }
        return Promise.reject(error);
      }
    );

    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (ENV !== Environment.local) {
          Sentry.captureException(error);
        }

        if (!error || !error.response) {
          return Promise.reject(error);
        }

        const { status, config } = error.response;
        const isForbiddenMyselfCall =
          status === StatusCodes.FORBIDDEN && /api\/v1\/me/.test(config.url);
        const isUnauthorized = _.includes([StatusCodes.UNAUTHORIZED], status);

        if (
          (isForbiddenMyselfCall || isUnauthorized) &&
          _.includes(config.url, getAPIRoot())
        ) {
          // Force logout for unauthenticated and forbidden users trying to query our API.
          Auth.signOut();
          window.location.reload(true);
        }
        return Promise.reject(error);
      }
    );
  }

  componentDidMount() {
    this.setupAPIHeaders();
    this.setupAuthenticationAndTokenRefresh();

    setupGoogleAnalytics(ReactGA, () => this.sendGAEventOnHistoryChange());
    /**
     * SEND FIRST GA EVENT
     * For quick access to GA custom dimensions
     * https://analytics.google.com/analytics/web/#/a159953935w224665183p212981913/admin/custom-dimensions/
     */
    ReactGA.set(
      getGAMetaData(
        this.props.user,
        this.props.selectedOrganization,
        "APPLICATION_PRELOAD"
      )
    );
    ReactGA.pageview(window.location.pathname);
  }

  sendGAEventOnHistoryChange() {
    this.routerRef.current?.navigator?.listen((location, action) => {
      ReactGA.set(
        getGAMetaData(this.props.user, this.props.selectedOrganization, action)
      );
      ReactGA.pageview(location.pathname);
    });
  }

  render() {
    return (
      <Provider store={Store}>
        <Router ref={this.routerRef}>
          <AppLayout
            rootPath={this.context.routes.root.path}
            homePath={this.context.routes.home.path}
            authState={this.props.authState}
          >
            <Suspense fallback={<AppLoad appName={APP_NAME} pageLoad={true} />}>
              <Routes>
                <Route
                  exact
                  path={this.context.routes.root.path}
                  element={this.redirectHomeIfLoggedIn()}
                />
                <Route
                  exact
                  path={this.context.routes.home.path}
                  element={this.redirectIfNotLoggedIn(Organizations)}
                />
                <Route
                  exact
                  path={this.context.routes.profileSettings.path}
                  element={this.redirectIfNotLoggedIn(ProfileSettings)}
                />
                <Route
                  exact
                  path={this.context.routes.services.path}
                  element={this.redirectIfNotLoggedIn(Services)}
                />
                <Route
                  exact
                  path={this.context.routes.organizationSettings.path}
                  element={this.redirectIfNotLoggedIn(OrganizationSettings)}
                />
                <Route
                  exact
                  path={this.context.routes.organizations.path}
                  element={this.redirectIfNotLoggedIn(Organizations)}
                />
                <Route
                  exact
                  path={this.context.routes.addOrganization.path}
                  element={this.redirectIfNotLoggedIn(AddOrganization)}
                />
                <Route
                  exact
                  path={this.context.routes.editOrganization.path}
                  element={this.redirectIfNotLoggedIn(EditOrganization)}
                />
                <Route
                  exact
                  path={this.context.routes.users.path}
                  element={this.redirectIfNotLoggedIn(Users)}
                />
                <Route
                  exact
                  path={this.context.routes.addSuperUser.path}
                  element={this.redirectIfNotLoggedIn(AddSuperUser)}
                />
                <Route
                  exact
                  path={this.context.routes.featureFlags.path}
                  element={this.redirectIfNotLoggedIn(FeatureFlags)}
                />
                <Route
                  exact
                  path={this.context.routes.addFeatureFlag.path}
                  element={this.redirectIfNotLoggedIn(AddFeatureFlag)}
                />
                <Route
                  exact
                  path={this.context.routes.editFeatureFlag.path}
                  element={this.redirectIfNotLoggedIn(EditFeatureFlag)}
                />
                <Route
                  exact
                  path={this.context.routes.moleculeEditorHelp.path}
                  element={this.redirectIfNotLoggedIn(MoleculeEditorHelp)}
                />
                <Route
                  exact
                  path={this.context.routes.activityTracking.path}
                  element={this.redirectIfNotLoggedIn(ActivityTracking)}
                />
                <Route
                  exact
                  path={this.context.routes.swagger.path}
                  element={this.redirectIfNotLoggedIn(() => (
                    <Swagger full={true} />
                  ))}
                />
                <Route
                  exact
                  path={this.context.routes.periodicTasks.path}
                  element={this.redirectIfNotLoggedIn(PeriodicTasks)}
                />
              </Routes>
            </Suspense>
          </AppLayout>
        </Router>
      </Provider>
    );
  }
}

export default App;
