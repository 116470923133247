import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "loading-attribute-polyfill/dist/loading-attribute-polyfill.module";
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import {
  Authenticator,
  SignIn,
  Greetings,
  ForgotPassword,
  RequireNewPassword,
  Loading
} from "aws-amplify-react";
import Amplify from "aws-amplify";
import {
  Environment,
  ENV,
  getUserPool
} from "@valencediscovery/kernel.services";
import {
  CustomSignIn,
  CustomSignInLoading,
  CustomRequireNewPassword,
  CustomForgotPassword
} from "@valencediscovery/kernel.react";
import { IntlProvider } from "react-intl";
import Locales from "@valencediscovery/kernel.locales/Locales";

import "loading-attribute-polyfill/dist/loading-attribute-polyfill.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import "@valencediscovery/kernel.styles/dist/index.min.css";
import "@valencediscovery/kernel.react/dist/main.css";
import { AppContext } from "../../context";
import Routes from "./Routes";
import { APP_NAME } from "../../config";

/**
 * Expose react to window for kernel.react to be able to use it
 * TODO(@michelml): Try fixing with shimming or other webpack strategy
 * It will however require to eject from react-scripts/create-react-app
 * https://webpack.js.org/guides/shimming/
 */
window.React = React;

Amplify.configure({
  Auth: {
    ...getUserPool(),
    mandatorySignIn: true,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  }
});

const mainContent = (
  <AppContext.Provider value={{ routes: Routes }}>
    <IntlProvider locale="en" messages={Locales.en}>
      <Authenticator
        hide={[SignIn, Greetings, ForgotPassword, RequireNewPassword, Loading]}
      >
        <CustomSignIn appName={APP_NAME} tagLine={APP_NAME} />
        <CustomSignInLoading />
        <CustomForgotPassword />
        <CustomRequireNewPassword />
        <App />
      </Authenticator>
    </IntlProvider>
  </AppContext.Provider>
);

const element = document.getElementById("root");
const root = ReactDOM.createRoot(element);
if (ENV === Environment.dev || ENV === Environment.prod) {
  Sentry.init({
    dsn: "https://aa560a9e42054314a2a8442e91081f27@o488680.ingest.sentry.io/5549497",
    environment: `${ENV} - frontend`
  });

  root.render(
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      {mainContent}
    </Sentry.ErrorBoundary>
  );
} else {
  root.render(mainContent);
}
